/* ================================================== */


/* Hostco
/* created by designesia

@charset "utf-8";

/* ================================================== */
/* 
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */

/* ================================================== */


/* import fonts icon */


/* ================================================== */
/* fonts/font-awesome/css/font-awesome.css */
@import url("../fonts/font-awesome/css/font-awesome.css");
@import url("../fonts/elegant_font/HTML_CSS/style.css");
@import url("../fonts/et-line-font/style.css");
@import url("../fonts/icofont/icofont.min.css");

* {
    --title-font: "Plus Jakarta Sans", Helvetica, Arial, sans-serif;
    --body-font: "Plus Jakarta Sans", Helvetica, Arial, sans-serif;
    --border-default:  solid 1px rgba(30, 30, 30, 1);
    --bg-color-even: #E8E8E8;
    --bg-color-odd: #F4F4F4;
    --bg-color-dark: var(--title-font-color);
    --body-font-color: #6D7C90;
    --title-font-color: #161C2D;
    --dark-body-font-color: rgba(255, 255, 255, .5);

}

.g-custom{
    --bs-gutter-x: 30px;
    --bs-gutter-y: 30px;
}

.g-custom-x{
    --bs-gutter-x: 30px;
}

/* ================================================== */


/* body */


/* ================================================== */

html {
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    background: #ffffff;
}

body {    
    font-family: var(--body-font);
    font-size: 16.5px;
    font-weight: 400;
    color: var(--body-font-color);
    padding: 0;
    line-height: 1.8em;
    word-spacing: 0px;
    letter-spacing: -0.2px;
}

.dark-scheme{
    color: var(--dark-body-font-color);
}

body.dark-scheme{
    background: var(--bg-color-even);
}

body.boxed {
    margin: 30px;
    overflow: hidden;
    border: solid 1px #eee;
}

body .owl-stage .owl-item {
    margin-bottom: 15px;
    
}

#wrapper {
    overflow: hidden;
}

.de-navbar-left #wrapper {
    overflow: visible;
}

a,
a:hover {
    text-decoration: none;
}

a.a-underline {
    display: inline-block;
    position: relative;
    overflow: hidden;
}

a.a-underline span {
    display: block;
    position: relative;
    left: 0;
    bottom: 0;
    border-bottom: solid 1px #333;
    width: 0;
}

a.a-underline:hover span {
    width: 100%;
}

/* ================================================== */


/*  header  */


/* ================================================== */

header {
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1001;
    background: #2b313f;
    margin: 0;
    border-bottom: solid 1px rgba(255, 255, 255, 0);
}

header.header-s1 {
    top: 0;
}

header .info {
    display: none;
    color: #fff;
    padding: 10px 0 10px 0;
    width: 100%;
    z-index: 100;
    background: #242424;
}

header .info .col {
    display: inline-block;
    font-size: 12px;
}

header .info .col {
    padding-right: 30px;
}

header .info .col:last-child {
    padding-right: 0px;
}

header .info i {
    font-size: 14px;
    margin-right: 15px;
}

header .info .social.col {
    border: none;
}

header .info .social i {
    color: #fff;
    font-size: 14px;
    display: inline-block;
    float: none;
    padding: 0 10px 0 10px;
    margin: 0;
}

header .info strong {
    font-size: 12px;
}

header.header-bg {
    background: rgba(0, 0, 0, 0.5);
}

header.transparent {
    background: none;
}

body:not(.side-layout) header:not(.smaller):not(.header-mobile).header-light.transparent {
    background: rgba(255, 255, 255, 0.0);
}

header.autoshow {
    top: -120px;
    height: 70px !important;
}

header.autoshow.scrollOn,
header.autoshow.scrollOff {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    outline: none;
}

header.autoshow.scrollOn {
    top: 0;
}

header.autoshow.scrollOff {
    top: -120px;
}

header div#logo {
    font-family: "Oswald", sans-serif;
    color: white;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

header .logo-2 {
    display: none;
}

header .logo-scroll {
    display: none;
}

header .logo,
header .logo-2 {
    font-family: var(--body-font);
    color: #fff;
    font-size: 40px;
    font-weight: 300;
}

header .logo-2 {
    color: #333;
}

header.header-full nav {
    float: left;
}

header nav a {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

header nav a:hover {
    color: #555;
}

header.smaller #mainmenu ul ul {
    top: 0px;
}

header.smaller {
    position: fixed;
    top: 0px;
    background: #ffffff;
    /*
     background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    */
}

.dark-scheme header.smaller{
    background: #101224;
}

header.smaller .btn-rsvp {
    height: 100px;
    padding-top: 55px;
}

.boxed header.smaller {
    margin-top: 0px;
}

header.smaller div#logo,
header.s2 div#logo {}

header.smaller.scroll-light {
    background: #ffffff;
}

header.smaller .logo-init{
    display: none;
}

header.smaller #topbar.topbar-dark{
    background: rgba(34, 34, 34, .05);
}

header.smaller #topbar.topbar-dark a{
    color: #333333;
}

header .logo-mobile{
    display: none;
}

header.smaller.scroll-dark {
    background: var(--bg-color-even);
}

header.header-light .h-phone,
header.smaller.scroll-light .h-phone {
    color: #202020;
}

header:not(.header-light).smaller.scroll-light div#logo .logo {
    display: none;
}

header.smaller.scroll-light div#logo .logo-2 {
    display: inline-block;
}

header.smaller.scroll-light div#logo .logo-scroll {
    display: inline-block;
}

header.smaller.header-light div#logo .logo {
    display: none;
}

header.smaller.scroll-light .social-icons i {
    color: #333;
}

header.smaller.scroll-light #mainmenu li a,
header.smaller.scroll-light #mainmenu li.has-child:after {
    color: #333333;
}

header.smaller.scroll-light #mainmenu li li a:hover {
    color: #ffffff;
}

.logo-smaller div#logo {
    width: 150px;
    height: 30px;
    line-height: 65px;
    font-size: 30px;
}

.logo-smaller div#logo img {
    font-size: 30px;
}

header.smaller div#logo .logo-1 {
    display: none;
}

header.smaller div#logo .logo-2 {
    display: inline-block;
}

header.smaller .hide-on-scroll{
    display: none;
}

header.no-shadow{
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.logo-small {
    margin-bottom: 20px;
}

header.de_header_2 {
    height: 125px;
}

header.de_header_2.smaller {
    height: 70px;
}

header.de_header_2.smaller .info {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

header.header-full {
    padding-left: 40px;
    padding-right: 40px;
}

header .header-row {
    text-align: center;
}

header .header-col {
    position: relative;
    height: 100%;
}

/* left col header */
header .header-col.left {
    float: left;
    padding: 0 30px 0 0;
    /* border-right:solid 1px rgba(255,255,255,.2); */
}

/* middle col header */
header .header-col.mid {
    float: right;
}

header .header-col.mid .social-icons {
    float: right;
    /* border-left:solid 1px rgba(255,255,255,.2); */
    padding: 0 30px 0 30px;
}


.header-col-right {
    text-align: right;
}


.de-navbar-left {
    background: #151618;
}

.de-navbar-left #logo {
    padding: 40px;
    text-align: center;
    width: 100%;
}

.de-navbar-left header {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    background: none;
    padding: 30px;
    padding-right: 20px;
}

.de-navbar-left header #mainmenu {
    float: none;
    margin: 0;
    padding: 0;
}

.de-navbar-left header #mainmenu>li {
    display: block;
    margin: 0;
    float: none;
    text-align: center;
    font-family: var(--body-font);
    letter-spacing: 5px;
    font-size: 14px;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px rgba(255, 255, 255, .1);
}

.de_light.de-navbar-left header #mainmenu>li {
    border-bottom: solid 1px rgba(0, 0, 0, .05);
}

.de-navbar-left header #mainmenu>li>a {
    padding: 0;
    opacity: .5;
}

.de_light.de-navbar-left header #mainmenu>li>a {
    color: #222;
}

.de-navbar-left header #mainmenu>li a:hover {
    opacity: 1;
}

.de-navbar-left header #mainmenu>li>a:after {
    content: none;
}

.de-navbar-left header nav {
    float: none;
}

/* subheader */

#subheader {
    text-align: center;
    overflow: hidden;
    padding: 50px 0 120px 0;
    position: relative;
}

#subheader h1 {
    margin-top: 100px;
    margin-bottom: 10px;
    letter-spacing: -2px;
}

#subheader.dark {
    background-color: #1A1C26;
}

#subheader.dark h1 {
    color: #fff;
}

.de-navbar-left #subheader {
    padding-bottom: 120px;
}

#subheader .crumb {
    font-size: 14px;
    letter-spacing: 3px;
}

#subheader.text-light .crumb {
    color: #fff;
}

#subheader .small-border-deco {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, .1);
}

#subheader .small-border-deco span {
    display: block;
    margin: 0 auto;
    height: 2px;
    width: 100px;
}

#subheader.dark h2,
#subheader.dark h4 {
    color: #fff;
}

#subheader.dark h2 {
    font-size: 48px;
}

#subheader.s2 {
    text-align: left;
    padding: 120px 0 20px;
    border: solid 1px #ddd;
    border-left: none;
    border-right: none;
}

.rtl #subheader.s2{
    text-align: right;
}


#subheader.s2 h1,
#subheader.s2 .crumb {
    margin: 0;
    padding: 0;
}

#subheader.s2 .crumb {
    float: right;
    margin-top: 10px;
}

.rtl #subheader.s2 .crumb {
    float: left;
}

#subheader.sh-plain {
    padding: 100px 0 60px 0;
}

.de-navbar-left.de_light #subheader h1 {
    color: #222;
}

#profile_banner {
    min-height: 360px;
}

/* ================================================== */


/* navigation */


/* ================================================== */

#mainmenu {
    font-family: var(--title-font);
    font-weight: 500;
    font-size: 14px;
    margin: 0 auto;
    float: none;
}

#mainmenu ul {
    margin: 0px 0px;
    padding: 0px;
    height: 30px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    background: #ffffff;
    color: var(--body-font-color);
}

#mainmenu ul:not(.mega) {
    border: solid 1px #dddddd;
}

.dark-scheme #mainmenu ul{
    background: #101224;
    border: solid 1px rgba(255, 255, 255, .1);
}

.dark-scheme #mainmenu ul.mega{
    background: #101224;
}

#mainmenu li {
    margin: 0px 0px;
    padding: 0px 0px;
    float: left;
    display: inline;
    list-style: none;
    position: relative;
}

#mainmenu > li.has-child:after {
    font-family: FontAwesome;
    content: "\f078";
    padding-left: 5px;
    font-size: 8px;
    position: relative;
    top: -2px;
}

.rtl #mainmenu li.has-child:after {
    padding-left: 15px;
}

.header-light #mainmenu li.has-child:after{
    color: rgba(0, 0, 0, .5);
}

#mainmenu li > a {
    padding-right: 6px;
    font-weight: bold;
}

#mainmenu li.has-child>a {
    padding-right: 0px;
}

#mainmenu a {
    position: relative;
    display: inline-block;
    padding: 30px;
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    outline: none;
}

#mainmenu p{
    line-height: 1.8em;
    margin-bottom: 0;
}

header.header-light #mainmenu a{
    color: #35404e;
}

#mainmenu a span:not(.badge) {
    position: relative;
    width: 0%;
    color: #ffffff;
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 14px;
    border-bottom: solid 2px #ffffff;
}

#mainmenu li:hover a span:not(.badge) {
    width: 100%;
}

#mainmenu li li a span:not(.badge) {
    float: right;
    margin-top: 5px;
}

#mainmenu a.active span:not(.badge) {
    width: 100%;
    margin-left: 0%;
}

.header-light {
    border-bottom: solid 1px rgba(255, 255, 255, 0);
}

.header-light #mainmenu>li>a {
    color: #204E96;
    font-size: 16px;
}

#mainmenu li li {
    font-family: var(--body-font);
    font-size: 14px;
}

#mainmenu li li:last-child {
    border-bottom: none;
}

#mainmenu li li a {
    padding: 5px 15px;
    border-top: none;
    color: #606060;
    width: 100%;
    border-left: none;
    text-align: left;
    font-weight: normal;
    border-bottom: solid 1px rgba(255, 255, 255, .1);
}

#mainmenu li:last-child > a{
    border-bottom: none;
}

.dark-scheme #mainmenu li li a{
    color: #ffffff;
}

.rtl #mainmenu li li a {
    text-align: right;
}

#mainmenu li li a:hover {
    color: #111;
}

#mainmenu li li a:after {
    content: none;
}

#mainmenu li li a:hover,
#mainmenu ul li:hover>a {
    color: #fff;
}

#mainmenu li ul {
    width: 190px;
    height: auto;
    position: absolute;
    left: 0px;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    border-radius: 3px;
}

#mainmenu ul li b{
    background: #333;
    color: #ffffff;
    padding: 0 3px;
    padding-bottom: 3px;
    font-size: 10px;
    margin-right: 3px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
}

#mainmenu li li {
    font-size: 14px;
    display: block;
    float: none;
    text-transform: none;
}

#mainmenu li:hover>ul {
    visibility: visible;
    opacity: 1;
    margin-top: 0px;
}

/* before edited 
#mainmenu li ul ul {
    left: 100%;
    top: 0px;
}
*/

#mainmenu li ul ul {
    top: 0px;
    margin-left: 188px;
}

#mainmenu select {
    padding: 10px;
    height: 36px;
    font-size: 14px;
    border: none;
    background: #ff4200;
    color: #eceff3;
}

#mainmenu select option {
    padding: 10px;
}

#mainmenu .btn-type {
    padding: 0;
    margin: 0;
}

#mainmenu .btn-type a {
    background: #eee;
    padding: 0;
    margin: 0;
}

#mainmenu>li ul.mega {
    position: fixed;
    left: 0;
    height: 0%;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    padding:0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: none;
}

#mainmenu>li ul.mega .sb-menu {
    background: #fff;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border: solid 1px #dddddd;
}

.dark-scheme #mainmenu>li ul.mega .sb-menu{
    background: #101224;
    border-color: rgba(255, 255, 255, .1);
}

#mainmenu li:hover ul.mega {
    visibility: visible;
    opacity: 1;
}

#mainmenu li ul.mega>li,
#mainmenu li ul.mega>li a {
    width: 100%;
}

#mainmenu li ul.mega li.title {
    padding: 0px 10px 15px 10px;
    text-align: left;
    color: #fff;
    font-weight: bold;
}

#mainmenu li ul.mega>li ul {
    position: static;
    visibility: visible;
    opacity: 1;
    left: 0;
    float: none;
    width: 100%;
}

#mainmenu ul.mega>li ul li {
    margin: 0;
    padding: 0;
}

#mainmenu ul.mega>li ul li:last-child {
    border-bottom: none;
}

#mainmenu li ul.mega .menu-content {
    background: #171A21;
    padding: 30px;
}

#mainmenu li ul.mega li.title {
    font-family: var(--main-font);
    padding: 0px;
    text-align: left;
    font-weight: bold;
    color: #586383;
}
#mainmenu ul.mega > li ul {
    border: none;
}

#mainmenu li ul.mega ul li a{
    background: none;
    padding: 2px 0;
}
#mainmenu li ul.mega ul li a:hover{
    color: #35404e;
}

header.header-mobile #mainmenu>li ul.mega {
    visibility: visible;
    opacity: 1;
}

header:not(.header-mobile) #mainmenu>li:hover ul.mega {
    height: auto;
}

/* menu toggle for mobile */

#menu-btn {
    display: none;
    float: right;
    width: 32px;
    height: 32px;
    padding: 4px;
    text-align: center;
    cursor: poInter;
    color: #fff;
    margin-top: 5px;
    margin-left: 20px;
}

#menu-btn:before {
    font-family: FontAwesome;
    content: "\f0c9";
    font-size: 20px;
}

#menu-btn:hover {
    background: #fff;
}

#menu-btn:hover:before {
    color: #222;
}

.rtl #menu-btn {
    float: left;
}

header.header-mobile.header-light,
header.header-mobile.header-light #menu-btn,
header.header-mobile.header-light #menu-btn:hover {
    background: none;
}

header.header-mobile.header-light #menu-btn {
    color: #222;
}

/* ================================================== */


/* content */


/* ================================================== */

#content {
    width: 100%;
    background: #ffffff;
    padding: 90px 0 90px 0;
    z-index: 100;
}

.de_light #content {
    background: #ffffff;
}

#content {
    background: #ffffff;
}

.dark-scheme #content {
    background: var(--bg-color-even);
}

/* ================================================== */


/* section */


/* ================================================== */

section {
    padding: 120px 0 50px 0;
    position: relative;
}

#content.zebra section:nth-child(even){ 
    background: #fcfcfc;
}

section:not(.relative) {}

section.no-bg,
#content.no-bg {
    background: none !important;
}

.dark-scheme section{
    background: #101224;
}

.no-bg{
    background: none !important;
}

.bgcolor-variation {
    background: #f6f6f6;
}

.overlay-gradient {
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    padding: 90px 0 90px 0;
}

.de-navbar-left .container-fluid .container {
    width: 100%;
}

.de-navbar-left section {
    padding: 70px;
}

.de_light section {
    background: #ffffff;
}

.section-fixed {
    position: fixed;
    width: 100%;
    min-height: 100%;
    height: auto;
}

.full-height {
    overflow: hidden;
}

#particles-js {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
}

.text_top{
    position: relative;
    z-index: 1000;
}

/* ================================================== */


/* footer */


/* ================================================== */

footer {
    /*background:url('../images/logo-big.png') -50% center no-repeat var(--primary-color) !important;*/
    padding: 80px 0 0 0;
    font-size: 14px;
}

footer{
    background: var(--bg-color-dark);
}

footer.footer-light {
    background: rgba(0, 0, 0, .025);
}

footer.footer-light a {
    color: #5d6371;
}

footer p{
    font-size: 14px;
    line-height: 1.8em;
}

.dark-scheme footer a{
    color: var(--dark-body-font-color);
}

footer.footer-black {
    background: #222222;
}

footer h5,
.de_light footer h5 {
    color: #fff;
    font-size: 18px;
    text-transform: none;
}

footer a {
    color: #ffffff;
    text-decoration: none !important;
}

.de-navbar-left footer {
    padding: 70px 70px 0 70px;
}

.subfooter {
    border-top: solid 1px rgba(255, 255, 255, .1);
    padding: 20px 0 20px 0;
}

.footer-light .subfooter {
    border-top: solid 1px rgba(0, 0, 0, .1);
}

.de-navbar-left .subfooter {
    background: none;
}

header.side-header {
    border-bottom: none;
    margin: 0;
}

header.side-header .social-icons-2 {
    margin: 0 auto;
    margin-top: 50px;
    float: none;
    text-align: center;
}

header.side-header .social-icons-2 a {
    display: inline-block;
    margin: 0 2px 0 2px;
    background: #555;
    width: 36px;
    height: 36px;
    padding-top: 5px;
    display: inline-block;
    text-align: center;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    opacity: .2;
}

.de_light header.side-header .social-icons-2 a {
    background: #fff;
    color: #333;
}

header.side-header .social-icons-2 a:hover {
    opacity: 1;
}

.de-navbar-left.de_light header,
.de-navbar-left.de_light {
    background: rgba(var(--secondary-color-rgb), .1);
}

header.header-light-transparent {
    background: rgba(255, 255, 255, .5);
}

header.header-solid.header-light #mainmenu>li>a {
    color: #555;
}

header.header-solid.header-light .info {
    background: #eee;
    color: #555;
}

header.header-solid.header-light .info .social i {
    color: #222;
}

header.header-solid {
    background: #fff;
}

header.header-light {
    background: #ffffff;
}

header.smaller.header-dark {
    background: #222222;
    border: none;
}

header.smaller.header-dark #mainmenu a {
    color: #fff;
}

header.s2 {
    background: #ffffff !important;
    border-bottom: solid 1px #eee;
    top: 0;
}

header.s2 #mainmenu>li>a {
    padding-top: 18px;
    padding-bottom: 18px;
}

.side-layout header.smaller.header-dark #mainmenu>li {
    border-bottom: solid 1px rgba(255, 255, 255, .1);
}

.side-layout header.smaller.header-dark #mainmenu>li:last-child {
    border-bottom: none;
}

header.header-mobile {
    position: fixed !important;
    background: #fff;
    top: 0;
    padding-bottom: 10px;
}

header.header-mobile.menu-open{
    background: #ddd;
}

header.header-mobile .container{
    max-width: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
}

.dark-scheme header.header-mobile{
     background: #101224;
}

header.header-mobile.has-topbar .header-row,
header.header-mobile.has-topbar .header-col {
    margin-top: 10px;
}

header.header-mobile nav {
    float: none;
}

header.header-mobile .logo {
    display: none;
}

header.header-mobile .btn-rsvp {
    padding-top: 40px;
    height: 90px;
}

header.header-mobile .logo-2 {
    display: inline-block;
}

header.header-mobile.header-light {
    background: #fff;
}

header.header-mobile #mainmenu ul {
    padding: 0;
}

header.header-solid.header-light {
    background: #fff;
}

header.header-light .logo {
    display: none;
}

header.header-light .logo-2 {
    display: inline-block;
}

/* topbar start here */

#topbar {
    z-index: 1000;
    width: 100%;
    overflow: hidden;
    top: 0;
    background: rgba(0, 0, 0, .05);
}

#topbar.topbar-dark{
    background:rgba(34, 34, 34, .2);
}

#topbar a {
    color: var(--title-font-color);
    font-weight: 500;
    text-decoration: none;
}

#topbar.text-light a {
    color: #ffffff;
}

.topbar-left,
.topbar-right {
    display: flex;
}

.topbar-right {
    float: right;
}

.topbar-solid {
    background: #ffffff;
}

.tb-light {
    background: #ffffff;
}

#topbar:not(.topbar-noborder) .topbar-right .topbar-widget {
    border-left: solid 1px rgba(255, 255, 255, .1);
}

#topbar:not(.topbar-noborder) .topbar-right span:last-child {
    border-right: solid 1px rgba(255, 255, 255, .1);
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-right .topbar-widget {
    border-left: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-right span:last-child {
    border-right: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .tb-light .topbar-right .topbar-widget {
    border-left: solid 1px rgba(0, 0, 0, .1);
}

#topbar:not(.topbar-noborder) .tb-light .topbar-right span:last-child {
    border-right: solid 1px rgba(0, 0, 0, .1);
}

/* topbar left content */
.topbar-left {
    float: left;
}

#topbar:not(.topbar-noborder) .topbar-left .topbar-widget {
    border-right: solid 1px rgba(255, 255, 255, .1);
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-left .topbar-widget {
    border-right: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .topbar-left span:first-child {
    border-left: solid 1px rgba(255, 255, 255, .1);
}

#topbar:not(.topbar-noborder) .tb-light .topbar-left .topbar-widget {
    border-right: solid 1px rgba(0, 0, 0, .1);
}

#topbar:not(.topbar-noborder) .tb-light .topbar-left span:first-child {
    border-left: solid 1px rgba(0, 0, 0, .1);
}

.h-phone {
    position: relative;
    font-weight: bold;
    color: #000000;
    font-family: var(--title-font);
    padding-left: 40px;
    font-size: 20px;
    padding-top: 22px;
}

.h-phone span {
    display: inline-block;
    position: absolute;
    left: 40px;
    top: 0;
    font-size: 14px;
    font-weight: 500;
}

.h-phone i {
    display: inline-block;
    position: absolute;
    font-size: 40px;
    left: 0;
    top: 12px
}

/* topbar widget */
.topbar-widget {
    font-size: 14px;
    display: flex;
    padding: 3px 15px;
    font-weight: 400;
    height: 40px;
}

.topbar-widget.widget-text{
    padding-top: 6px;
}

.topbar-widget i {
    font-size: 16px;
    margin-top: 8px;
    margin-right: 10px;
}

.h-sub .topbar-widget {
    padding: 12px;
}

.topbar-widget:before {
    float: left;
    position: relative;
    font-family: "FontAwesome";
    font-size: 16px;
    margin-right: 10px;
}

.topbar-widget.tb-phone:before {
    content: "\f095";
}

.topbar-widget.tb-email:before {
    content: "\f003";
}

.topbar-widget.tb-opening-hours:before {
    content: "\f017";
}

.topbar-widget.tb-social {
    padding: 0;
}

.topbar-widget.tb-social a {
    font-size: 14px;
    display: flex;
    padding: 5px;
    padding-left: 15px;
    display: inline-block;
    text-align: center;
}

.topbar-widget a span{
    font-weight: bold;
    margin-right: 10px;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-widget.tb-social a,
#topbar:not(.topbar-noborder) .topbar-light .topbar-widget.tb-social a:first-child {
    border-left: solid 1px rgba(0, 0, 0, .1);
}

#topbar:not(.topbar-noborder) .topbar-widget.tb-social a {
    border-left: solid 1px rgba(255, 255, 255, .1);
}

.tb-light .topbar-widget.tb-social a {
    border-left: solid 1px rgba(0, 0, 0, .1);
}

.topbar-widget.tb-social a:first-child {
    border: none;
}

/* topbar end here */

#de-sidebar {
    padding: 0px;
}

/* ================================================== */


/* blog */


/* ================================================== */

.blog-list {
    margin: 0;
    padding: 0;
}

/* blog list */

.blog-list>div {
    list-style: none;
    line-height: 1.7em;
    margin-top: 0px;
    border-bottom: solid 1px rgba(255, 255, 255, .1);
    margin-bottom: 20px;
}

.blog-list img {
    margin-bottom: 20px;
}

.de_light .blog-list li {
    border-bottom: solid 1px #ddd;
}

.blog-list h4 {
    margin-top: 20px;
}

.blog-list h4 a {
    color: #fff;
}

.de_light .blog-list h4 a {
    color: #222222;
}

.de_light .text-light .blog-list h4 a {
    color: #fff;
}

.blog-list .btn-more {
    padding: 3px 25px 3px 25px;
    color: #111;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    float: right;
}

.blog-list .btn-more:hover {
    background: #fff;
}

.de_light .blog-list .btn-more:hover {
    background: #222;
    color: #fff;
}

.blog-list .post-text {
    padding-left: 80px;
}

.bloglist .post-text a.btn-main{
    display: inline-block;
    margin-top: 0px;
    padding: 5px 25px;
}

.blog-list .blog-slider {
    margin-bottom: -60px;
}

.blog-list .date-box {
    position: absolute;
    text-align: center;
    text-shadow: none;
}

.blog-list .date-box .day,
.blog-list .date-box .month {
    display: block;
    color: #fff;
    text-align: center;
    width: 40px;
    z-index: 100;
}

.de_light .blog-list .date-box .month {
    color: #222222;
}

.blog-list .date-box {
    text-align: center;
    background: #f6f6f6;
    padding: 10px;
}

.blog-list .date-box .month {
    font-size: 14px;
}

.blog-list .date-box .day {
    font-family: var(--body-font);
    color: #222222;
    font-weight: bold;
    font-size: 28px;
}

.blog-list .date-box .month {
    font-family: var(--body-font);
    color: #fff;
    font-size: 13px;
    letter-spacing: 3px;
}

.de_light .blog-list .date-box .month {
    border-bottom-color: #ddd;
}

.de_light .text-light .blog-list .date-box .month {
    color: #fff;
}

/* de-bloglist-type-1 */
.de-bloglist-type-1 .d-content{
    display: block;
    border-bottom: solid 1px rgba(0, 0, 0, .1);
    padding-bottom: 15px;
    margin-bottom: 10px;
}
.de-bloglist-type-1 .d-image{
    position: absolute;
    overflow: hidden;
    width: 80px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}
.de-bloglist-type-1 .d-image img{
    width: 100%;
}
.de-bloglist-type-1 .d-content{
    padding-left: 100px;
}
.de-bloglist-type-1 .d-content h4{
    font-size: 16px;
    margin-bottom: 2px;
}
.de-bloglist-type-1 .d-date{
    position: relative;
    font-size: 12px;
    font-weight: 500;
    padding-left: 18px;
}
.de-bloglist-type-1 .d-date:before{
    font-family: "FontAwesome";
    font-weight: 300;
    color: var(--primary-color);
    content: "\f073";
    left: 0;
    position: absolute;
}


/* de-post-type-1 */
.de-post-type-1{
    margin-bottom: 40px;
}
.de-post-type-1 .d-image{
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    margin-bottom: 20px;
}
.de-post-type-1 .d-image img{
    width: 100%;
}
.de-post-type-1 .d-meta{
    font-size: 12px;
    margin-bottom: 10px;
}
.de-post-type-1 .d-meta span{
    display: inline-block;
    font-weight: 600;
    margin-right: 30px;
    padding-left: 30px;
    position: relative;
}
.de-post-type-1 h4{
    margin-bottom: 10px;
}
.de-post-type-1 h4 a{
    font-size: 20px;
    color: #333333;
    margin-bottom: 0px;
}
.de-post-type-1 .d-meta span:before{
    font-family: "FontAwesome";
    font-size: 20px;
    font-weight: 300;
    color: var(--primary-color);
    content: "";
    left: 0;
    position: absolute;
}
.de-post-type-1 .d-meta span.d-by:before{
    content: "\f007";
}
.de-post-type-1 .d-meta span.d-date:before{
    content: "\f073";
}
.de-post-type-1 .d-meta span.d-tags:before{
    content: "\f02c";
}
.blog-snippet li {
    border-bottom: none;
    margin-bottom: 0;
}

#blog-carousel .item.item {
    width: 100%;
}

/* blog comment */

#blog-comment {
    margin-top: 40px;
}

#blog-comment h5 {
    margin-bottom: 10px;
}

#blog-comment ul,
#blog-comment li {
    list-style: none;
    margin-left: -40px;
    padding-left: 0;
}

.rtl #blog-comment li {
    margin-right: -30px;
}

.rtl #blog-comment li li{
    margin-right: 40px;
}

#blog-comment li {
    min-height: 70px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #eee;
}

.dark-scheme #blog-comment li{
    border-bottom-color: rgba(255, 255, 255, .1);
}

#blog-comment li .avatar {
    position: absolute;
    margin-top: 5px;
}

#blog-comment .comment {
    margin-left: 85px;
}

.rtl #blog-comment .comment {
    margin-left: 0;
    margin-right: 85px;
}

#blog-comment li {
    min-height: 70px;
    font-size: 14px;
    line-height: 1.6em;
}

#blog-comment li li {
    margin-left: 55px;
    padding-bottom: 0px;
    margin-top: 20px;
    padding-top: 10px;
    border-top: solid 1px #eee;
    border-bottom: none;
}

.dark-scheme #blog-comment li li {
    border-top-color: rgba(255, 255, 255, .1);
}

#blog-comment li li .avatar {
    position: absolute;
}

#blog-comment li li .comment {}

.comment-info {
    margin-left: 85px;
    margin-bottom: 5px;
}

.rtl .comment-info {
    margin-left: 0;
    margin-right: 85px;
}

.comment-info span {}

.comment-info .c_name {
    display: block;
    font-weight: 700;
    color: #555555;
}

.dark-scheme .comment-info .c_name{
    color: #ffffff;
}

.comment-info .c_reply {
    padding-left: 20px;
    margin-left: 20px;
    border-left: solid 1px #ddd;
    font-size: 12px;
}

.dark-scheme .comment-info .c_reply{
    border-left-color: rgba(255, 255, 255, .2);
}

.comment-info .c_date {
    font-size: 12px;
}

.blog-list h4 {
    color: #333;
}

.blog-list .post-wrapper {}

.blog-read h4 {
    color: #333;
    letter-spacing: normal;
    font-size: 24px;
    text-transform: none;
}

.blog-carousel ul,
.blog-carousel li {
    margin: 0;
    list-style: none;
}

.blog-carousel h4 {
    margin-bottom: 0px;
}

.blog-carousel p {
    margin-bottom: 10px;
}

.blog-carousel .post-date {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 11px;
    color: #888;
}

.post-content {
    position: relative;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
}

.post-content p {
    margin-bottom: 20px;
    font-size: 15px;
}

.post-text h4 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.post-text h4 a {
    color: #1A1C26;
    text-decoration: none;
    line-height: 1.5em;
}

.dark-scheme .post-text h4 a{
    color: #ffffff;
}

.post-text .tags {
    font-size: 10px;
    text-transform: uppercase;
}

.p-tagline {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 20px;
}

.p-tagline {
    left: auto;
    right: 20px;
}

.p-tagline,
.p-title {
    font-family: var(--body-font);
    background: rgba(var(--primary-color-rgb), .2);
    border-radius: 3px;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 10px;
    padding: 7px 10px 6px 10px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.p-tagline,
.p-title.invert {
    background: var(--secondary-color);
}

.p-title {
    font-size: 12px;
    margin-bottom: 20px;
}

.post-text .p-date {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 10px;
    font-weight: 500;
    color: #888888;
    padding: 5px 10px;
    background: rgba(0, 0, 0, .05);
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.post-info {
    border-top: solid 1px rgba(0, 0, 0, .1);
    padding-top: 15px;
}

.blog-read img {
    margin-bottom: 20px;
}

.blog-read .post-text {
    padding: 0;
}

.post-date,
.post-comment,
.post-like,
.post-by,
.post-author {
    margin-right: 20px;
    font-size: 13px;
    color: #999;
    font-family: var(--body-font);
}

.dark-scheme .post-date,
.dark-scheme .post-comment,
.dark-scheme .post-like,
.dark-scheme .post-by,
.dark-scheme .post-author {
    color: #ffffff;
}

.post-author {
    color: #777;
}

.post-date:before,
.post-comment:before,
.post-like:before,
.post-by:before {
    font-family: FontAwesome;
    padding-right: 10px;
    font-size: 11px;
}

/*.post-date:before{
    content: "\f133";
}*/
.post-comment:before {
    content: "\f0e5";
}

.post-like:before {
    content: "\f08a";
}

.post-author:before {
    content: "By: ";
}

/* blog comment form */

#commentform label {
    display: block;
}

#commentform input {
    width: 290px;
}

#commentform input:focus {
    border: solid 1px #999;
    background: #fff;
}

#commentform textarea {
    width: 97%;
    padding: 5px;
    height: 150px;
    color: #333;
}

#commentform textarea:focus {
    border: solid 1px #999;
    background: #fff;
}

#commentform input.btn {
    width: auto;
}


.post-meta {
    margin: 0px 0 10px 0px;
    border: solid 1px #eee;
    border-left: none;
    border-right: none;
    border-left: none;
    font-size: 11px;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.post-meta a {
    color: #888;
}

.post-meta span {
    display: table-cell;
    padding: 10px 0 10px 0;
    text-align: center;
}

.post-meta span i {
    float: none;
}

.post-image img {
    width: 100%;
    margin-bottom: 0px;
}

.blog-slide {
    padding: 0;
    margin: 0;
}

.blog-item {
    margin-bottom: 60px;
}

.blog-item img.preview {
    width: 100%;
    margin-bottom: 30px;
}

.post-image {
    position: relative;
}

.post-image .post-info {
    position: absolute;
    bottom: 0;
    padding: 0;
    border: none;
    width: 100%;
}

.post-image .post-info .inner {
    margin: 0 30px 0 30px;
    padding: 3px 20px;
    border-radius: 0px 10px 0 0;
}

.post-image .post-date,
.post-image .post-author {
    color: #ffffff;
}

/* ================================================== */

/* products */


/* ================================================== */

.products {
    padding: 0;
    list-style: none;
    text-align: center;
}

.products li {
    margin-bottom: 40px;
}

.product .price {
    margin-bottom: 10px;
    font-size: 20px;
    color: #222;
}

.de_light .product h4 {
    font-size: 16px;
    margin: 0;
    margin-top: 20px;
}

.product img {
    padding: 2px;
    background: #333;
    border: solid 1px #fff;
}

.de_light .product img {
    padding: 0;
    border: solid 1px #eee;
}

.ratings {
    color: #F86E4E;
}

.de-card .de-rating{
    margin-bottom: 0;
}

.de-card .p-rating i{
    color: rgba(0, 0, 0, .2);
}

.p-rating i{
    color: #cccccc;
}
.p-rating i.checked{
    color: #FFD337;
}

.d-rating i{
    font-size: 12px;
    color: #F86E4E;
}

.product .ratings {
    margin: 5px 0 5px 0;
}

/* ================================================== */


/* contact form */


/* ================================================== */

.error {
    display: none;
    margin-top: 30px;
    color: #e7505a;
    clear: both;
    padding-top: 20px;
    padding: 20px;
    background: rgba(231, 80, 90, .1);
    border: solid 1px #e7505a;
}

.success {
    display: none;
    color: #96c346;
    margin-top: 30px;
    clear: both;
    padding-top: 20px;
    padding: 20px;
    background: rgba(150, 195, 70, .1);
    border: solid 1px #96c346;
}

.error img {
    vertical-align: top;
}

.full {
    width: 98%;
}

#contact_form textarea {
    height: 177px;
}

#contact_form.s2 textarea {
    height: 180px;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
}

.form-underline input[type=text],
.form-underline textarea,
.form-underline input[type=email],
.form-underline select {
    padding: 10px 0 10px 0;
    margin-bottom: 20px;
    border: none;
    border-bottom: solid 2px #bbbbbb;
    background: none;
    border-radius: 0;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.form-underline select {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    -moz-appearance: none;
    appearance: none;
    /* background: url('../../assets/images/ui/arrow-down-form.png') no-repeat 98% 60%; */
}

.form-underline select:focus {
    /* background: url('../images/ui/arrow-down-form-hover.png') no-repeat 98% 60%; */
}

.form-underline input[type=text],
.form-underline textarea,
.form-underline input[type=email],
.form-underline select {
    color: #333;
    border-bottom: solid 1px #bbb;
}

.form-default input[type=text],
.form-default textarea,
.form-default input[type=email],
.form-default select {
    padding: 10px;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, .5);
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    font-weight: 400;
    font-size: 18px;
}

.form-border select{
    background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'><path d='M0 0h24v24H0z' fill='none'></svg>")   right 5px center no-repeat !important;
}

.rtl .form-border select{
    background: none !important;
    padding-left: 20px;
}

#contact_form.form-default textarea {
    padding: 10px;
    height: 170px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

#form_subscribe input[type=text] {
    padding: 7px 12px 7px 12px;
    width: 80%;
    float: left;
    display: table-cell;
    border-radius: 30px 0 0 30px;
    -moz-border-radius: 30px 0 0 30px;
    -webkit-border-radius: 30px 0 0 30px;
    border: none;
    border-right: none;
    background: rgba(255, 255, 255, .7);
    -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
    -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
    box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
}

.d-create-file{
    padding: 50px;
    border-radius: 0px;
    border: dashed 3px #dddddd;
    text-align: center;
}

.dark-scheme .d-create-file{
    border-color: rgba(255, 255, 255, .2);
}

.dark-scheme .d-create-file p{
    color: #ffffff;
}

#form_sb input[type=text]{
    font-size: 20px;
    padding:25px 20px 25px 20px;
    width:85%;
    height: 30px;
    float:left;
    display:table-cell;
    border-radius:30px 0 0 30px;
    -moz-border-radius:30px 0 0 30px;
    -webkit-border-radius:30px 0 0 30px;
    border:none;
    background:#ffffff;
    border: none;
}
.dark-scheme #form_sb input[type=text]{
    color: #ffffff;
    background: rgba(255, 255, 255, .1);
}

#form_sb #btn-submit i{
    text-align:center;
    font-size:30px;
    float:left;
    width:15%;
    background:#171A21;
    color:#ffffff;
    display:table-cell;
    padding:10px 0 10px 0;
    border-radius:0 30px 30px 0 ;
    -moz-border-radius:0 30px 30px 0 ;
    -webkit-border-radius:0 30px 30px 0 ;
}

footer:not(.footer-light) #form_subscribe input[type=text]::-moz-input-placeholder {
    color: #ffffff;
}

footer:not(.footer-light) #form_subscribe input[type=text]::-webkit-input-placeholder {
    color: #ffffff;
}


footer:not(.footer-light) #form_subscribe.form-dark input[type=text] {
    color: #ffffff;
    background: rgba(255, 255, 255, .1);
}

.footer-light #form_subscribe input[type=text] {
    border: none;
    border-right: none;
    padding: 6px 12px;
    background: #eeeeee;
}

#form_subscribe input[type=text]:focus {
    background: rgba(255, 255, 255, .2);
}

#form_subscribe #btn-subscribe i {
    text-align: center;
    font-size: 28px;
    float: left;
    width: 20%;
    background: #171A21;
    color: #ffffff;
    display: table-cell;
    padding: 5px 0 5px 0;
    border-radius: 0 30px 30px 0;
    -moz-border-radius: 0 30px 30px 0;
    -webkit-border-radius: 0 30px 30px 0;
}

#form_quick_search {
    font-family: var(--body-font);
    display: inline-block;
    margin-right: 0px;
    margin-bottom: -16px;
}

#form_quick_search input[type=text] {
    padding: 8px 12px;
    width: 200px;
    float: left;
    display: table-cell;
    border-radius: 0px 0 0 5px;
    -moz-border-radius: 0px 0 0 5px;
    -webkit-border-radius: 0px 0 0 5px;
    border: solid 1px #ddd;
    border-right: none;
    background: rgba(255, 255, 255, .7);
    -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
    -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
    box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
    font-size: 16px;
}

.dark-scheme #form_quick_search input[type=text]{
    color: #ffffff;
    background: none;
    border-color: rgba(255, 255, 255, .2);
}

#form_quick_search #btn-submit i {
    text-align: center;
    font-size: 16px;
    float: left;
    width: 60px;
    background: #171A21;
    color: #ffffff;
    display: table-cell;
    padding: 13px 0;
    border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
}

#quick_form{
    border: solid 1px #d2d2d2;
    padding: 35px;
    padding-bottom: 25px;
}

.text-light #form_quick_search input[type=text] {
    color: #ffffff;
    background: none;
    border: solid 1px rgba(255, 255, 255, .1);
}

.text-light #form_quick_search input[type=text] {
    color: #ffffff;
}

.text-light #form_quick_search input[type=text]::-moz-input-placeholder {
    color: #ffffff;
}

.text-light #form_quick_search input[type=text]::-webkit-input-placeholder {
    color: #ffffff;
}

.form-default input[type=text]:focus,
.form-default textarea:focus,
.form-default input[type=email]:focus,
.form-default select:focus {
    background: #f6f6f6;
}

.error_input {
    border-bottom: solid 1px #ff0000 !important;
}


/* ================================================== */


/* coming soon page */


/* ================================================== */

.coming-soon .logo {
    margin: 0;
    padding: 0;
}

.coming-soon h2 {
    margin-top: 0;
    color: rgba(255, 255, 255, .8);
    font-weight: 400;
}

.coming-soon .social-icons i {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.coming-soon .social-icons i:hover {
    background: none;
    color: rgba(255, 255, 255, .2);
}

.arrow-up,
.arrow-down {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-bottom: 40px solid #fff;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: poInter;
    z-index: 101;
}

.arrow-down {
    border-top: 40px solid #fff;
    border-bottom: none;
    position: fixed;
    z-index: 102;
    top: -40px;
}

.arrow-up:before {
    font-family: "FontAwesome";
    font-size: 20px;
    content: "\f067";
    color: #222;
    position: relative;
    top: 12px;
    margin: 0 0 0 -8px;
}

.arrow-down:before {
    font-family: "FontAwesome";
    font-size: 20px;
    content: "\f068";
    color: #222;
    position: relative;
    top: -35px;
    margin: 0 0 0 -8px;
}

/* ================================================== */


/* elements */


/* ================================================== */


/* address */


/* ------------------------------ */


address span {
    display: block;
    font-size: 18px;
    padding: 5px;
    background: #ffffff;
    border-radius: 5px;
}

.dark-scheme address span{
    background:none;
}

address span i {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 15px;
}

.rtl address span i {
    margin-left: 15px;
    margin-right: 5px;
}

.text-light address span a {
    color: #ffffff;
}

.text-white address span a,
.text-white address span a:hover {
    color: #fff;
}


address.s1 span {
    background: none;
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 1.7em;
    margin-bottom: 5px;
}

address.s1 span i {
    font-size: 15px;
}

address span strong {
    display: inline-block;
}

.de_light #sidebar .widget address span {
    border-bottom: solid 1px #ddd;
}

.de_light #sidebar .widget address a {
    color: #333;
}

/* border */


/* ------------------------------ */

.small-border {
    width: 50px;
    height: 2px;
    background: rgba(0, 0, 0, .5);
    border-left: none;
    border-right: none;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
}


.small-border.sm-left {
    margin-left: 0;
}

.rtl .small-border.sm-left {
    margin-right: 0;
}

.tiny-border {
    height: 1px;
    background: #333;
    width: 40px;
    margin: 20px 0 20px 0;
    display: block;
}

.tiny-border.center {
    margin-left: auto;
    margin-right: auto;
}

.small-border.white {
    border-top: solid 2px #ffffff;
}

/* breadcrumb */


/* ------------------------------ */

.crumb {
    color: #777;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.de-navbar-left.de_light #subheader .crumb {
    color: #333;
}

.crumb a{
    color: #35404e;
}

.crumb li {
    display: inline;
    text-decoration: none;
    letter-spacing: 0;
}

.crumb li:after {
    font-family: "FontAwesome";
    content: "\f054";
    font-size: 10px;
    margin: 0 10px 0 10px;
    opacity: .5;
}

.crumb li:last-child:after {
    display: none;
}

.crumb a {
    text-decoration: none;
}

.crumb li .active {
    font-weight: bold;
}

.de_light .crumb a:hover {
    color: #fff;
}

/* box icon */

.icon_box .icon_wrapper {
    margin-bottom: 20px;
}

.icon_box .icon_wrapper i {
    font-size: 60px;
}

.icon_box.icon_left .icon_wrapper {
    position: absolute;
    width: 80px;
}

.icon_box.icon_left .desc_wrapper {
    margin-left: 80px;
}

.icon_box.icon_right .icon_wrapper {
    position: absolute;
    right: 0;
    width: 80px;
}

.icon_box.icon_right .desc_wrapper {
    margin-right: 80px;
    text-align: right;
}

.icon-big {
    border-radius: 100%;
    text-align: center;
    font-size: 56px;
    display: inline-block;
    padding: 16px 0;
    width: 96px;
    height: 96px;
    -webkit-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), .3);
    -moz-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), .3);
    box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), .3);
}

.icon-box {
    display: block;
    text-align: center;
    border: solid 1px #d2d2d2;
    padding: 20px 0 20px 0;
}

.icon-box.s2 {
    margin: 1%;
}

.icon-box.style-2 {
    border: none;
    background: rgba(var(--secondary-color-rgb), .1);
}

.text-light .icon-box {
    border: solid 2px rgba(255, 255, 255, .1);
}

.icon-box i {
    display: block;
    font-size: 40px;
    margin-bottom: 10px;
}

.icon-box span {
    display: block;
    color: #606060;
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
    line-height: 1.4em;
}

.dark-scheme .icon-box span{
    color: #ffffff;
}

.text-light .icon-box span {
    color: #ffffff;
}

.icon-box:hover {
    color: #ffffff;
    -webkit-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), .3);
    -moz-box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), .3);
    box-shadow: 5px 5px 30px 0px rgba(var(--primary-color-rgb), .3);
}

.icon-box:hover i,
.icon-box:hover span {
    color: #ffffff;
}

/* button and link */


/* ------------------------------ */

a {
    text-decoration: none;
}

.de_light a {
    color: #888;
}

.de_light a:hover {
    color: #555;
}

a.btn-text {
    text-decoration: none;
    display: inline-block;
    color: #111;
    font-weight: 600;
    padding: 0;
}

a.btn-text:after {
    font-family: FontAwesome;
    content: "\f054";
    padding-left: 10px;
}

a.btn-text {
    color: #fff;
}

a.btn-big {
    font-size: 14px;
    color: #eceff3;
    letter-spacing: 1px;
    line-height: normal;
    font-weight: bold;
    text-transform: uppercase;
    border: solid 1px #fff;
    padding: 10px 30px 10px 30px;
}

a.btn-big:after {
    font-family: FontAwesome;
    content: "\f054";
    margin-left: 20px;
}

a.btn,
.btn {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

a.btn:before {
    content: "";
    background: rgba(0, 0, 0, 0);
    width: 0;
    height: 100%;
    position: absolute;
    z-index: -1;
}

a.btn-fx:after {
    font-family: FontAwesome;
    content: "\f178";
    margin-left: 15px;
    position: absolute;
    right: -20px;
    margin-top: 0px;
}

a.btn-fx {
    font-size: 14px;
    color: #eceff3;
    letter-spacing: 1px;
    line-height: normal;
    font-weight: bold;
    text-transform: uppercase;
    border: solid 1px #fff;
    padding: 10px 30px 10px 30px;
}

a.btn-fx:hover {
    padding-left: 20px;
    padding-right: 40px;
}

a.btn-fx:before {
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
}

a.btn-fx:hover:after {
    right: 15px;
}

a.btn-fx:hover:before {
    width: 100%;
    background: rgba(0, 0, 0, 1);
}

a.btn-fx.light:hover:before {
    width: 100%;
    background: rgba(255, 255, 255, 1);
}

.btn-fullwidth {
    display: block;
    width: 100%;
}

a.btn-slider {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;
    border: solid 2px #fff;
    padding: 10px 30px 10px 30px;
    border-radius: 60px;
}

a.btn-slider:hover {
    color: #222;
    background: #fff;
    border-color: #000;
    border: solid 2px #fff;
}

a.btn-main:hover,
.btn-main:hover {
    color: #fff;
    -webkit-box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
    -moz-box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
    box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
}

a.btn-slider:hover:after {
    color: #222;
}

a.btn-id,
a.btn-id:hover {
    border: none;
}

a.btn-light.btn-id {
    color: #222;
}

a.btn-dark.btn-id {
    color: #fff;
}

.btn-main.btn-small {
    padding: 5px 20px 5px 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.btn-fx.btn-main {
    text-transform: normal;
}

a.btn-bg-dark {
    background: #222;
}

a.btn-text-light {
    color: #fff;
}

.btn-icon-left i {
    margin-right: 12px;
    color: #fff;
}

.btn-add_to_cart,
a.btn-add_to_cart {
    border: solid 1px #bbb;
    font-size: 12px;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    padding: 3px;
    padding-left: 40px;
    padding-right: 20px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: #555;
}

.btn-add_to_cart:before,
a.btn-add_to_cart:before {
    font-family: "FontAwesome";
    content: "\f07a";
    position: absolute;
    left: 20px;
}

a.btn-main,
a.btn-main:active,
a.btn-main:focus,
a.btn-main:visited,
.btn-main,
input[type=button].btn-main,
a.btn-line,
#mainmenu li ul.mega a.btn-main{
    display: inline-block;
    font-family: var(--title-font);
    text-align: center;
    color: #fff;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    outline: 0;
    font-weight: 800;
    text-decoration: none;
    padding: 6px 40px 6px 40px;
    font-size: 14px;
    border: none;
}

a.btn-sc{
    display: inline-block;
    font-family: var(--title-font);
    font-weight: bold;
    padding: 6px 0;
    font-size: 14px;
    color: #222;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background: #f2f2f2;
}

a.btn-sc img{
    width: 20px;
    margin-top: -4px;
    margin-right: 8px;
}

a.btn-line,
a.btn-line:hover{
    background: none;
    color: #35404e;
    border: solid 2px rgba(0, 0, 0, .1);
}

a.btn-line:hover{
    color: #ffffff;
    border-color: rgba(0, 0, 0, 0) !important;
}

header:not(.header-light) a.btn-line,
.dark-scheme a.btn-line,
.dark-schem a.btn-line:hover{
    color: #ffffff;
    border-color: rgba(255, 255, 255, .1);
}

a.btn-main.btn-white {
    background: #ffffff;
}

header a.btn-main i{
    display: none;
}

.col-right a.btn-main {
    font-size: 14px;
    text-transform: none;
}

a.btn-border {
    border: solid 2px rgba(255, 255, 255, .3);
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    min-width: 120px;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    padding: 10px 30px;
    min-width: 120px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
}

a.btn-border:hover {
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0);
    margin-top: -2px;
    margin-bottom: 2px;
    box-sizing: border-box
}

a.btn-border.light {
    border: solid 1px #ffffff;
    color: #ffffff;
}

a.btn-border.light:hover {
    background: #ffffff;
}

a.btn-border:hover a {
    color: #ffffff !important;
}

.d-btn-close {
    color: #ffffff;
    cursor: poInter;
    text-align: center;
    display: block;
    text-align: center;
    width: 60px;
    height: 60px;
    background: #333;
    font-size: 32px;
    font-weight: bold;
    text-decoration: none;
    margin: 0 auto;
    padding-top: 12px;
    position: absolute;
    left: 0;
    right: 0;
}

a.btn-link {
    display: block;
    text-decoration: none;
    margin-top: 10px;
}

.cover a.btn-link:hover {
    color: #fff;
}

/*
    a.btn-rsvp:before{
    font-size:32px;
    font-family:"FontAwesome";
    content:"\f073";
    margin-right:15px;
    display:block;
    margin:0 auto;
    margin-bottom:10px;
    }
    */

.play-button {
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    color: #fff;
    text-decoration: none;
    border: solid 5px rgba(255, 255, 255, .3);
    display: inline-block;
    text-align: center;
    width: 80px;
    height: 80px;
    padding-top: 22px;
    padding-left: 5px;
}

.play-button:before {
    font-family: "FontAwesome";
    font-size: 20px;
    content: "\f04b";
    position: relative;
    color: #fff;
}

.play-button:hover {
    border: solid 5px rgba(255, 255, 255, 1);
}

.play-button.dark {
    color: #222;
    border: solid 5px rgba(0, 0, 0, .1);
}

.play-button.dark:before {
    color: #222;
}

.play-button.dark:hover {
    border: solid 5px rgba(0, 0, 0, .5);
}

.text-light .play-button {
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    color: #fff;
    text-decoration: none;
    border: solid 5px rgba(255, 255, 255, .3);
    display: inline-block;
    text-align: center;
    width: 80px;
    height: 80px;
    padding-top: 22px;
    padding-left: 5px;
}

.text-light .play-button:before {
    font-family: "FontAwesome";
    font-size: 20px;
    content: "\f04b";
    position: relative;
    color: #fff;
}

.text-light .play-button.dark {
    color: #fff;
    border: solid 5px rgba(255, 255, 255, .3);
}

.text-light .play-button.dark:before {
    color: #fff;
}

.text-light .play-button.dark:hover {
    border: solid 5px rgba(255, 255, 255, 1);
}

/* columns */


/* ------------------------------ */


/* counter */


/* ------------------------------ */

.de_count {
    padding: 0px 0 10px 0;
    background: none;
}

.de_count h3 {
    font-size: 40px;
    letter-spacing: .1px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    line-height: 1em;
}

.de_count h5 {
    font-size: 14px;
    font-weight: 500;
}

.de_count p.d-small{
    margin-top: 5px;
    opacity: .5;
}

.rtl .de_count h3,
.rtl .de_count h5{
    text-align: right;
}

.de_count h3 span {
    font-size: 36px;
}

.de_count.big h3 {
    font-size: 120px;
}

.de_count span {
    font-size: 12px;
    text-transform: uppercase;
}

.de_count i {
    display: block;
    font-size: 30px;
    margin: 0 auto;
    margin-bottom: 0px;
    width: 100%;
    font-weight: lighter;
}

.de_count.small h4 {
    font-size: 28px;
    margin-bottom: 0;
}

.de_count{
    font-size: 16px;
    background: #ffffff;
    padding: 30px 20px 20px 30px;
    color: #35404e;
    font-weight: 500;
}



/* divider */


/* ------------------------------ */

.spacer-single {
    width: 100%;
    height: 30px;
    display: block;
    clear: both;
}

.spacer-double {
    width: 100%;
    height: 60px;
    display: block;
    clear: both;
}

.spacer-triple {
    width: 100%;
    height: 90px;
    display: block;
    clear: both;
}

.spacer-half {
    width: 100%;
    height: 15px;
    display: block;
    clear: both;
}


.spacer-5 {
    width: 100%;
    height: 5px;
    display: block;
    clear: both;
}

.spacer-10 {
    width: 100%;
    height: 10px;
    display: block;
    clear: both;
}

.spacer-20 {
    width: 100%;
    height: 20px;
    display: block;
    clear: both;
}

.spacer-30 {
    width: 100%;
    height: 30px;
    display: block;
    clear: both;
}

.spacer-40 {
    width: 100%;
    height: 40px;
    display: block;
    clear: both;
}

.spacer-50 {
    width: 100%;
    height: 50px;
    display: block;
    clear: both;
}

.spacer-60 {
    width: 100%;
    height: 60px;
    display: block;
    clear: both;
}

hr {
    display: block;
    clear: both;
    border-top: solid 1px #ddd;
    margin: 40px 0 40px 0;
}

/* dropcap */


/* ------------------------------ */

.dropcap {
    display: inline-block;
    font-size: 48px;
    float: left;
    margin: 10px 15px 15px 0;
    color: #eceff3;
    padding: 20px 10px 20px 10px;
}

/* form */


/* ------------------------------ */

.form-transparent input[type=text],
.form-transparent textarea,
.form-transparent input[type=email] {
    padding: 10px;
    margin-bottom: 20px;
    color: #fff;
    border: solid 1px rgba(0, 0, 0, .3);
    background: rgba(0, 0, 0, .2);
    border-radius: 0;
    height: auto;
}

.form-border input[type=text],
.form-border textarea,
.form-underline input[type=email],
.form-border input[type=password],
.form-border input[type=date],
.form-border select {
    padding: 8px;
    margin-bottom: 10px;
    border: none;
    border: solid 2px #eeeeee;
    background: rgba(0, 0, 0, .025);
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    color: #333;
}

.dark-scheme .form-border input[type=text],
.dark-scheme .form-border textarea,
.dark-scheme .form-underline input[type=email],
.dark-scheme .form-border input[type=password],
.dark-scheme .form-border input[type=date],
.dark-scheme .form-border select {
    color: #ffffff;
    border: solid 1px rgba(255, 255, 255, .2);
}

.dark-scheme ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.dark-scheme .form-border input::-moz-input-placeholder,
.dark-scheme .form-border textarea::-moz-input-placeholder {
    color: rgba(255, 255, 255, .5);
}

.dark-scheme .form-border input::-webkit-input-placeholder,
.dark-scheme .form-border textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .5);
}

.form-border input[type=text]:focus,
.form-border textarea:focus,
.form-underline input[type=email]:focus,
.form-border input[type=date]:focus,
.form-border select:focus {
    background: rgba(var(--secondary-color-rgb), .1);
}

.form-subscribe {
    display: table;
    margin: 0 auto;
}

.form-subscribe input[type=text] {
    display: table-cell;
    border: solid 1px rgba(255, 255, 255, .3);
    border-right: none;
    background: rgba(255, 255, 255, .1);
    padding: 6px 20px 6px 20px;
    border-radius: 60px 0 0 60px;
    height: 40px;
    vertical-align: middle;
}

.form-subscribe .btn-main {
    display: table-cell;
    border-radius: 0 60px 60px 0;
    height: 40px;
    vertical-align: middle;
}

.form-subscribe input[type=text]:hover {
    background: rgba(255, 255, 255, .3);
}

.field-set label {
    color: #606060;
    font-weight: 500;
}

.dark-scheme .field-set label{
    color: #ffffff;
}

#upload_file{
    display: none;
}

#form-create-item textarea {
    height: 45px;
}

.de_form input[type="radio"], 
.de_form input[type="checkbox"] {
/* hide the inputs */
display:none;
}

.de_form input[type="radio"] + label:before{
content:"\f111";
font-family:"FontAwesome";
margin-right:10px;
border: solid 1px #d2d2d2;
}

.de_form input[type="checkbox"] + label:before{
position: absolute;
content: '';
display: inline-block;
font-family:"FontAwesome";
margin-right:10px;
border: solid 2px rgba(0,0,0,.2);
width: 16px;
height:16px;
margin-top: 5px;
left: 0;
font-size: 11px;
padding: 1px 3px 0 3px;
line-height: 15px;
border-radius: 4px;
}

.dark-scheme .de_form input[type="checkbox"] + label:before{
border: solid 2px rgba(255,255,255,.2); 
}

.de_checkbox{
    position: relative;
    display: block;
    padding-left: 25px;
}

.de_form input[type="radio"] + label, 
.de_form input[type="checkbox"] + label {
    cursor: pointer;
    border: none;
    border-radius: 3px;
}

.no-bg input[type="radio"] + label, 
.de_form.no-bg input[type="checkbox"] + label {
    padding: 4px 0px;
    border: none;
    background:none;
}

.de_form input[type="radio"]:checked + label,
.de_form input[type="checkbox"]:checked + label{
    /* style for the checked/selected state */
    border: none;
}

.de_form input[type="checkbox"]:checked + label:before{
    content:"\f00c";
    color: #ffffff;
    border: rgba(0,0,0,0);
}

.item_filter_group{
    border: solid 1px rgba(0,0,0,.15);
    margin-bottom: 25px;
    padding: 30px;
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
}

.dark-scheme .item_filter_group{
    border: solid 1px rgba(255,255,255,.075);
}

.item_filter_group h4{
    font-size: 18px;
}

.de-filter-group{
}

/* heading */


/* ------------------------------ */

h1,
h2,
h4,
h3,
h5,
h6,
.h1_big,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
footer.footer-light h5 {
    margin-top: 0;
    font-family: var(--title-font);
    font-weight: bold;
    color: var(--title-font-color);
}

.dark-scheme h1,
.dark-scheme h2,
.dark-scheme h4,
.dark-scheme h3,
.dark-scheme h5,
.dark-scheme h6,
.dark-scheme .h1_big,
.dark-scheme .h1,
.dark-scheme .h2,
.dark-scheme .h3,
.dark-scheme .h4,
.dark-scheme .h5,
.dark-scheme .h6{
    color: #ffffff;
}

footer h1,
footer h2,
footer h4,
footer h4,
footer h5,
footer h6 {
    color: #fff;
}

h2.text-light,
footer h4,
footer h4,
footer h5,
footer h6 {
    color: #fff;
}

h1,
.h1 {
    font-size: 48px;
    letter-spacing: -1.8px;
    margin-bottom: 20px;
    line-height: 58.08px;
}

h1 .label {
    display: inline-block;
    font-size: 36px;
    padding: 0 6px;
    margin-left: 10px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

h1.s1 {
    letter-spacing: 30px;
    font-size: 26px;
}

h1.s2{
    display: inline-block;
}
h1.s2 > span{
    line-height: 1em;
    letter-spacing: 0;
    display: block;
}
h1.s2 .c1{
    font-size: 240px;
    letter-spacing: -20px;
    line-height: .85em;
    position: relative;
}
h1.s2 .c1 span{
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
    display: block;
    font-size: 40px;
    line-height: 1em;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    padding: 10px 20px 10px 0;
}
h1.s2 .c2{
    color: rgba(255, 255, 255, 0);
    -webkit-text-stroke: 1px rgba(255, 255, 255, .5); /* width and color */
    font-size: 140px;
    padding-bottom: 10px;
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: bold;
}
h1.s2 .c3{
    font-size: 20px;
    font-weight: 500;
}

h1 .small-border {
    margin-top: 30px;
}

h1.big,
.h1_big {
    font-size: 64px;
    margin: 0;
    line-height: 70px;
}

h1.very-big {
    font-size: 88px;
    line-height: 88px;
    letter-spacing: -4px;
}

h1.ultra-big {
    font-size: 140px;
    line-height: 120px;
    letter-spacing: -6px;
    font-weight: 700;
    margin-bottom: 0;
}

h1.ultra-big span {
    display: inline-block;
}

h1.ultra-big span.underline span {
    display: block;
    border-bottom: solid 12px #fff;
    position: relative;
    margin-top: -5px;
}

h1.very-big-2 {
    font-size: 90px;
    letter-spacing: 25px;
    text-transform: uppercase;
    font-weight: bold;
}

.h2_title {
    font-size: 28px;
    display: block;
    margin-top: 0;
    line-height: 1.2em;
}

h2 .small-border {
    margin-left: 0;
    margin-bottom: 15px;
    width: 40px;
}

h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 40px;
    letter-spacing: -1.5px;
    margin-bottom: 20px;
    line-height: 53.08px;
}

h2.style-2 {
    font-size: 30px;
    margin-bottom: 20px;
}

h2.big {
    font-size: 48px;
    line-height: 1.3em;
    margin-bottom: 0;
}

h2 .uptitle {
    font-family: var(--body-font);
    display: block;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
}

h2.s1,
.h2_s1 {
    font-size: 24px;
}

h2.deco-text span {
    font-family: "Parisienne";
    display: block;
    line-height: .85em;
    font-weight: lighter;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

h2.deco-text .md {
    font-size: 80px;
}

h2.deco-text .lg {
    font-size: 120px;
}

h2.deco-text .xl {
    font-size: 150px;
}

h3 {
    font-size: 26px;
    margin-bottom: 20px;
    letter-spacing: -.5px;
}

h4 {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 26px;
}

h4.teaser {
    font-weight: 300;
    font-size: 22px;
}

.subtitle.s2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
}

.subtitle.s2 span {
    margin-right: 20px;
    padding-left: 20px;
}

.subtitle.s2 i {
    margin-right: 10px;
}

.subtitle.s2 span:first-child {
    padding-left: 0;
}

h4.s1 {
    letter-spacing: 10px;
    font-weight: 400;
    font-size: 16px;
}

h4.s2 {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 200;
    line-height: 1.8em;
}

h4.s3 {
    font-family: "Parisienne";
    font-size: 60px;
    font-weight: lighter;
}

h4.s3 {
    font-family: var(--body-font);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 15px;
}

.call-to-action h4 {
    text-transform: none;
    font-size: 20px;
}

h1.slogan_big {
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -2px;
    padding: 0;
    margin: 0px 0 30px 0;
}

h1.title {
    font-size: 64px;
    letter-spacing: 10px;
}

h1.title strong {
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
}

h1.hs1 {
    font-family: "Parisienne";
    font-size: 96px;
    display: inline-block;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

h1.hs2 {
    font-family: "Parisienne";
    font-size: 72px;
    display: inline-block;
    font-weight: lighter;
}

h2.subtitle {
    margin-top: 0;
}

h2.name {
    font-family: var(--body-font);
    color: #fff;
    font-size: 84px;
    line-height: 50px;
}

h2.name span {
    display: block;
    font-size: 32px;
}

h2.name-s1 {
    font-family: var(--body-font);
    color: #fff;
    font-size: 84px;
    font-weight: 700;
    line-height: 50px;
}

h2.name-s1 span {
    display: block;
    font-size: 32px;
}

h2.hw {
    display: block;
    font-family: "Parisienne";
    font-size: 48px;
    text-transform: none;
    font-weight: lighter;
}

h2.deco {
    font-family: var(--body-font);
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}

h2.deco span {
    display: inline-block;
    position: relative;
}

h2.deco span:before,
h2.deco span:after {
    content: "";
    position: absolute;
    border-top: 1px solid #bbb;
    top: 10px;
    width: 100px;
}

h2.deco span:before {
    right: 100%;
    margin-right: 15px;
}

h2.deco span:after {
    left: 100%;
    margin-left: 15px;
}

h2.hs1 {
    font-family: var(--body-font);
    font-size: 40px;
}

h2.hs1 i {
    font-size: 48px;
    position: relative;
    top: 10px;
    color: #ff0042;
    margin: 0 10px 0 10px;
}

h2.hs1 span {
    font-size: 48px;
    position: relative;
    top: 10px;
    font-family: "Miama";
    margin: 0 15px 0 10px;
    font-weight: normal;
}

h2 .de_light .text-light h2 {
    color: #fff;
}

.text-light h2.deco span:before,
.text-light h2.deco span:after {
    border-top: 1px solid rgba(255, 255, 255, .5);
}

h2.s2 {
    font-family: var(--body-font);
    font-weight: 400;
}

h2.s3 {
    font-size: 36px;
    margin-bottom: 20px;
}

h4.title {
    border-bottom: solid 1px #ddd;
    margin-bottom: 20px;
}

h4.style-2 {
    font-size: 18px;
}

h4.title {
    border-bottom: solid 1px #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

h5.s2 {
    display: inline-block;
    letter-spacing: 1px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 6px 12px;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    font-weight: 500;
}

h5.s3{
    display: inline-block;
    padding: 5px 12px;
    color: var(--primary-color);
    border:solid 2px rgba(var(--primary-color-rgb),.5);
    border-radius:30px;
    -moz-border-radius:30px;
    -webkit-border-radius:30px;
}

h5 {
    font-size: 16px;
}


h5 span.label{
    display: inline-block;
    margin-right: 10px;
    background: #222222;
    color: #ffffff;
    padding: 0 5px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

h6{
    font-size: 14px;
}

h4.s1 {
    font-size: 12px;
    letter-spacing: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

span.teaser {
    font-family: "Georgia";
    font-style: italic;
    font-size: 18px;
}

.wm {
    color: #ffffff;
    font-size: 200px;
    opacity: .2;
    position: absolute;
    left: 0;
    z-index: 0;
    width: 100%;
    text-align: center;
    font-family: var(--title-font);
    line-height: 70px;
    letter-spacing: -5px;
}


.text-light {
    color: #ffffff;
}

.text-dark {
    color: #223044;
}

.pricing-s2.bg.text-light .top p {
    color: #fff;
}

.text-white,
.de_light .text-white {
    color: #fff;
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6 {
    color: #fff;
}

.de_light .text-light h1,
.de_light .text-light h2,
.de_light .text-light h4,
.de_light .text-light h4,
.de_light .text-light h5,
.de_light .text-light h6 {
    color: #fff;
}

.text-white h1,
.text-white h2,
.text-white h4,
.text-white h4,
.text-white h5,
.text-white h6 {
    color: #fff;
}

.de_light .text-white h1,
.de_light .text-white h2,
.de_light .text-white h4,
.de_light .text-white h4,
.de_light .text-white h5,
.de_light .text-white h6 {
    color: #fff;
}

.de_light h1,
.de_light h2,
.de_light h4,
.de_light h4,
.de_light h5,
.de_light h6 {
    color: #202020;
}

/* feature box style 1 */


.feature-box .inner {
    position: relative;
    overflow: hidden;
    padding: 40px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.feature-box.s1 .inner {
    background: #ffffff;
}


.feature-box.s1:hover .inner i {
    background: none;
    color: #303030;
}


.text-light .feature-box .inner {
    color: #fff;
}

.feature-box i {
    font-size: 40px;
    margin-bottom: 20px;
}

.feature-box.left i {
    position: absolute;
}

.feature-box.left .text {
    padding-left: 70px;
}

.feature-box.center {
    text-align: center;
}

.feature-box i.circle,
.feature-box i.square {
    display: inline-block;
    padding: 20px;
    font-size: 40px;
    font-weight: 500;
    color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    position: relative;
    z-index: 2;
}

.feature-box i.square {
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.feature-box i.circle {
    display: inline-block;
    padding: 20px;
    font-size: 40px;
    font-weight: 500;
    color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    position: relative;
    z-index: 2;
}

i.font60 {
    font-size: 60px;
}


.feature-box i.wm {
    font-size: 800px;
    position: absolute;
    top: 0%;
    width: 100%;
    left: 50%;
}

.feature-box:hover .inner i.wm {
    transform: scale(1.2);
    color: rgba(0, 0, 0, .05);
    z-index: 0;
}

.feature-box:hover .inner i.circle {
    -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
}

.feature-box.style-3 {
    position: relative;
    overflow: hidden !important;  
}

.feature-box.style-3 i {
    text-align: center;
    font-size: 28px;
    color: #ffffff;
    padding: 14px 0;
    width: 56px;
    height: 56px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;

    /*-webkit-box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);
    box-shadow: 8px 8px 18px 0px rgba(0,0,0,0.2);*/
}

.feature-box.style-3 i.wm {
    position: absolute;
    font-size: 240px;
    background: none;
    width: auto;
    height: auto;
    color: rgba(var(--primary-color-rgb), .5);
    right: 0;
    top: 30%;
}

.feature-box.style-3:hover i.wm {
    -webkit-transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    transform: rotate(-25deg);
}

.feature-box.style-4 i {
    position: relative;
    z-index: 2;
    padding: 10px;
    min-width: 60px;
}

.feature-box.style-4 .wm {
    display: block;
    position: absolute;
    font-size: 160px;
    background: none;
    width: 100%;
    height: auto;
    right: 0;
    top: 120px;
    z-index: 2;
    font-weight: bold;
}

.feature-box.style-4 .text {
    margin-top: -50px;
    position: relative;
    z-index: 1;
    background: #ffffff;
    padding: 30px;
    padding-top: 50px;
}

.feature-box.f-boxed {
    overflow: none;
    padding: 50px;
    background: rgba(var(--primary-color-rgb), .1);
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.text-light .feature-box.f-boxed {
    background: rgba(0, 0, 0, .1);
}

.feature-box.f-boxed:hover {
    color: #ffffff;
    -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.feature-box.f-boxed:hover h4 {
    color: #ffffff;
}

.feature-box i.i-circle {
    border-radius: 60px;
}

.feature-box i.i-boxed {
    border-radius: 0px;
}

.feature-box-small-icon.center {
    text-align: center;
}

.dark .feature-box-small-icon .inner:hover>i {
    color: #fff;
}

.feature-box-small-icon .text {
    padding-left: 80px;
}

.feature-box-small-icon.center .text {
    padding-left: 0;
    display: block;
}

.feature-box-small-icon i {
    text-shadow: none;
    font-size: 40px;
    width: 68px;
    height: 68px;
    text-align: center;
    position: absolute;
    text-align: center;
}

.feature-box-small-icon i.hover {
    color: #333;
}

.feature-box-small-icon .border {
    height: 2px;
    width: 30px;
    background: #ccc;
    display: block;
    margin-top: 20px;
    margin-left: 85px;
}

.feature-box-small-icon .btn {
    margin-top: 10px;
}

.feature-box-small-icon.center i {
    position: inherit;
    float: none;
    display: inline-block;
    margin-bottom: 20px;
    border: none;
    font-size: 60px;
    background: none;
    padding: 0px;
}

.feature-box-small-icon.center .fs1 {
    position: inherit;
    float: none;
    display: inline-block;
    margin-bottom: 20px;
    border: none;
    font-size: 60px;
    background: none;
    padding: 0px;
}

.feature-box-small-icon i.dark {
    background-color: #1A1C26;
}

.feature-box-small-icon h4 {
    line-height: normal;
    margin-bottom: 5px;
}

.feature-box-small-icon.no-bg h2 {
    padding-top: 12px;
}

.feature-box-small-icon .number {
    font-size: 40px;
    text-align: center;
    position: absolute;
    width: 70px;
    margin-top: -5px;
}

.feature-box-small-icon .number.circle {
    border-bottom: solid 2px rgba(255, 255, 255, .3);
    padding: 20px;
    width: 70px;
    height: 70px;
}

.feature-box-small-icon.style-2 .number {
    font-size: 32px;
    color: #fff;
    padding-top: 22px;
    background: #253545;
    width: 70px;
    height: 70px;
}

.feature-box-small-icon.no-bg .inner,
.feature-box-small-icon.no-bg .inner:hover {
    padding: 0;
    background: none;
}

.f-hover {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    outline: none;
}

/* feature-box style 2 */

.feature-box-small-icon-2 {
    margin-bottom: 30px;
}

.feature-box-small-icon-2 .text {
    padding-left: 48px;
}

.feature-box-small-icon-2 i {
    text-shadow: none;
    color: #eceff3;
    font-size: 24px;
    text-align: center;
    position: absolute;
}

.feature-box-small-icon-2 i.dark {
    background-color: #1A1C26;
}

.feature-box-small-icon-2 h4 {
    line-height: normal;
    margin-bottom: 5px;
}

.feature-box-small-icon-2.no-bg h2 {
    padding-top: 12px;
}

.feature-box-small-icon-2.no-bg .inner,
.feature-box-small-icon-2.no-bg .inner:hover {
    padding: 0;
    background: none;
}

/* feature-box image style 3 */

.feature-box-image-2 {
    margin-bottom: 30px;
}

.feature-box-image-2 .text {
    padding-left: 100px;
}

.feature-box-image-2 img {
    position: absolute;
}

.feature-box-image-2 i {
    background-color: #1A1C26;
}

.feature-box-image-2 h4 {
    line-height: normal;
    margin-bottom: 5px;
}

.feature-box-image-2.no-bg h2 {
    padding-top: 12px;
}

.feature-box-image-2.no-bg {}

.feature-box-image-2.no-bg .inner,
.feature-box-image-2.no-bg .inner:hover {
    padding: 0;
    background: none;
}

/* with image */

.feature-box-image img {
    width: 100%;
    margin-bottom: 10px;
    height: auto;
}

.feature-box-image h4 {
    line-height: normal;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
}

/* feature-box image style 4 */

.feature-box-big-icon {
    text-align: center;
}

.feature-box-big-icon .text {}

.feature-box-big-icon i {
    float: none;
    display: inline-block;
    text-shadow: none;
    color: #eceff3;
    font-size: 40px;
    padding: 40px;
    width: 120x;
    height: 120x;
    text-align: center;
    border: none;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -web-kit-border-radius: 0px;
    background: #333;
    margin-bottom: 40px;
    cursor: default;
}

.feature-box-big-icon i:after {
    content: "";
    position: absolute;
    margin: 75px 0 0 -40px;
    border-width: 20px 20px 0 20px;
    /*size of the triangle*/
    border-style: solid;
}

.feature-box-big-icon:hover i,
.feature-box-big-icon:hover i:after {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.feature-box-big-icon:hover i {
    background: #333;
}

.feature-box-big-icon:hover i:after {
    border-color: #333 transparent;
}

.d-card {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border: solid 1px #dddddd;
}

.d-card.s2 {
    border: solid 1px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
}

.d-card i {
    text-align: center;
    position: absolute;
    width: 64px;
    height: 100%;
    font-size: 36px;
    color: #ffffff;
    padding: 12px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.d-card.s2 i,
.d-card.s2:hover i {
    background: rgba(var(--secondary-color-rgb), .1);
    color: #111111;
}

.d-card .text {
    font-size: 15px;
    padding: 25px;
    padding-left: 90px;
}

.d-card h4{
    font-size: 18px;
    margin-bottom: 10px;
}

.feature-box-type-2 {
    padding: 40px;
    padding-bottom: 30px;
    border-radius: 3px;
    background: #ffffff;
    position: relative;
    overflow: hidden;
}

.dark-scheme .feature-box-type-2{
    background: var(--bg-color-dark);
}

.feature-box-type-2 i {
    color: #ffffff;
    font-size: 48px;
    margin-bottom: 20px;
    width: 70px;
    height: 70px;
    padding-top: 10px;
    text-align: center;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

.feature-box-type-2:hover i {
    text-shadow: 0px 0px 0px #ffffff;
}

.feature-box-type-2 .wm {
    color: rgba(255,255,255,.3);
    font-size: 400px;
    bottom: 11.5%;
    left: 12.5%;
    position: absolute;
    text-align: center;
    font-weight: bold;
}


.feature-box-type-2 i {
    /*text-shadow: 5px 5px 5px rgba(var(--primary-color-rgb), 0.3);*/
}

.f-box i {
    color: #ffffff;
    font-size: 36px;
}

.f-box.f-icon-rounded i {
    display: block;
    text-align: center;
    padding: 22px;
    width: 80px;
    height: 80px;
    border-radius: 3px;
}

.f-box.f-border i {
    border: solid 10px rgba(255, 255, 255, .1);
    padding: 12px;
}

.f-box.f-icon-rounded:hover i {
    border-radius: 3px;
    -webkit-box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
    -moz-box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
    box-shadow: 5px 5px 30px 0px rgba(20, 20, 20, 0.2);
}


.f-box.f-icon-shadow i {
    -webkit-box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), .3);
    -moz-box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), .3);
    box-shadow: 5px 5px 10px 0px rgba(var(--primary-color-rgb), .3);
}

.f-box.f-icon-circle i {
    display: block;
    text-align: center;
    padding: 22px 0;
    padding-top: 20px;
    min-width: 80px;
    height: 80px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
}

.f-box.f-icon-left {
    display: flex;
}

.f-box.f-icon-left i {
    margin-right: 30px;
}

.f-box.f-box-s1 {
    padding: 40px;
    padding-left: 0;
    margin-left: 20px;
    background: rgba(var(--primary-color-rgb), .1);
}

.f-box.f-box-s2 {
    background: rgba(255, 255, 255, .1);
}

.f-box.f-box-s1 i {
    margin-left: -20px;
}

.f-box.f-box-s1 p {
    margin: 0;
}

.rtl .f-box.f-icon-left i {
    margin-right: -60px;
    margin-left: 30px;
}

.sm-icon i{
    position: absolute;
    font-size: 21px;
    min-width: 44px;
    min-height: 44px;
    color:#fff;
    text-align: center;
    padding-top:12px;
    border-radius: 3px;
}

.sm-icon h5{
    margin-bottom: 2px;
}

.sm-icon .si-inner{
    padding-left: 60px;
}

.rtl .sm-icon .si-inner{
    padding-left: 0px;
    padding-right: 60px;
}
.d-gallery-item {
    overflow: hidden;
    position: relative;
    color: #ffffff;
}

.d-gallery-item img {
    width: 100%;
    height: 100%;
}

.d-gallery-item .dgi-1 {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.d-gallery-item .dgi-2 {
    display: table;
    width: 100%;
    height: 100%;
    position: absolute;
}

.d-gallery-item .dgi-3 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.d-gallery-item:hover .dgi-1 {
    opacity: .8;
}

.d-gallery-item:hover img {
    transform: scale(1.2);
}


.exp-box {
    border-bottom: solid 1px rgba(255, 255, 255, .1);
    margin-bottom: 30px;
    padding-bottom: 10px;
}

.exp-box h5 {
    border: solid 2px rgba(255, 255, 255, .5);
    display: inline-block;
    padding: 6px 10px 5px 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.box-fx .inner {
    position: relative;
    overflow: hidden;
    border-bottom: solid 2px;
}

.box-fx .inner {
    border-bottom: solid 2px;
}

.box-fx .front {
    padding: 60px 0 60px 0;
}

.box-fx .front span {
    font-size: 14px;
}

.box-fx .info {
    position: absolute;
    padding: 60px 30px 60px 30px;
    background: #fff;
    color: #fff;
}

.box-fx i {
    font-size: 72px;
    margin-bottom: 20px;
}

.box-fx .btn-line,
.box-fx .btn-line a.btn-line {
    border: solid 1px rgba(255, 255, 255, 0.5);
    color: #fff;
}

.box-fx .btn-line:hover,
.box-fx a.btn-line:hover {
    background: #fff;
    color: #111;
    border-color: #fff;
}

.box-fx .btn-line:after,
.box-fx a.btn-line:after {
    color: #fff;
}

.box-fx .btn-line:hover:after,
.box-fx a.btn-line:hover:after,
.box-fx a.btn-line.hover:after {
    color: #111;
}

.box-fx .bg-icon {
    left: 50%;
    bottom: 50px;
    color: #f6f6f6;
    position: absolute;
    font-size: 190px;
    z-index: -1;
}

.box-icon-simple i {
    color: #222;
}

.box-icon-simple.right {
    margin-right: 70px;
    text-align: right;
}

.box-icon-simple.right i {
    font-size: 42px;
    right: 0;
    position: absolute;
}

.box-icon-simple .num {
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: #333333;
    width: 48px;
    height: 48px;
    padding: 12px 12px;
    position: absolute;
    text-align: center;
    font-weight: bold;
    border-radius: 30px;
}

.box-icon-simple.right .num {
    right: 10px;
}

.box-icon-simple.left .text {
    margin-left: 70px;
}

.box-icon-simple.left i {
    font-size: 42px;
    position: absolute;
}

.box-number .number {
    display: block;
    font-size: 48px;
    color: #222;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 25px;
    border-radius: 60px;
    position: absolute;
}

.box-number .text {
    margin-left: 100px;
}

.box-border {
    border: solid 2px #333;
}

/* list */

.activity-list,
.activity-list li{
    list-style: none;
    padding: 0;
    margin: 0;
}

.activity-list li{
    padding: 20px;
    border-radius: 0px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 100px;
}

.activity-list li:after{
    font-family: "FontAwesome";   
    margin-right: 15px;
    float: right;
    position: absolute;
    right: 20px;
    top: 30%;
    font-size: 26px;
    background: #fff;
    height: 48px;
    width: 48px;
    padding: 12px 0;
    text-align: center;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.activity-list li.act_follow:after{
     content: "\f00c";
}

.activity-list li.act_like:after{
     content: "\f004";
}

.activity-list li.act_sale:after{
     content: "\f291";
}

.activity-list li.act_offer:after{
     content: "\f0e3";
}

.activity-filter,.activity-filter li{
    margin: 0;
    padding: 0;
    list-style: none;
}

.activity-filter li{
    display: inline-block;
    padding: 8px 10px;
    border: solid 1px #d2d2d2;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    margin-right: 5px;
    margin-bottom: 10px;
    width: 48%;
    font-weight: bold;
    cursor: pointer;
    font-family: var(--title-font);;
}

.dark-scheme .activity-filter li{
    border-color: rgba(255, 255, 255, .2);
}

.activity-filter li:nth-child(2){
    margin-right: 0;
}

.activity-filter i{
    height: 32px;
    width: 32px;
    padding: 9px 0;
    text-align: center;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    background: #eee;
    margin-right: 10px;
}

.activity-filter li.active{
    color: #fff;
}

.activity-filter li.active i{
    background: #ffffff;
}

.activity-list img{
    width: 80px;
    position: absolute;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
}

.activity-list .act_like img,
.activity-list .act_sale img,
.activity-list .act_offer img{
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.act_list_text{
    padding-left: 100px;
}

.dark-scheme .act_list_text a{
    color: #ffffff;
}

.act_list_text h4{
    margin-bottom: 5px;
    font-size: 16px;
}

.act_list_date{
    display: block;
}

.ul-style-2 {
    padding: 0;
    list-style: none;
}

.ul-style-2 li {
    margin: 5px 0 5px 0;
}

.ul-style-2 li:before {
    font-family: "FontAwesome";
    content: "\f00c";
    margin-right: 15px;
}


.ul-style-4 {
    padding: 0;
    list-style: none;
    font-size: 14px;
}

.ul-style-4 li {
    margin: 2px 0 2px 0;
    display: ;
    font-weight: 500;
}

.ul-style-4 li:before {
    color: #ffffff;
    font-family: "FontAwesome";
    content: "\f00c";
    margin-right: 15px;
    padding: 3px;
    font-size: 12px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.ol-style-1{
    font-size: 16px;
    font-weight: bold;
}

.ol-style-1 h4{
    font-size: 16px;
}

.ol-style-1 p{
    font-weight: normal;
}

.ol-style-1 {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.ol-style-1 li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.ol-style-1 li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;    
}

.ol-style-1 li li {
    margin: 0;
}

.ol-style-1 li li:before {
    content: counters(item, ".") " ";
}


/* pagination */


/* ======================================== */

.pagination {
    margin: 0 auto;
    font-weight: 500;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.pagination li a {
    font-size: 14px;
    color: #888;
    border: solid 1px #d2d2d2;
    border-right: none;
    background: none;
    padding: 15px 20px 15px 20px;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;

    background: #ffffff;
}

.dark-scheme .pagination li:not(.active) a{
    border-color: rgba(255, 255, 255, .1);
    background: none;
    color: #ffffff;
}

.pagination li:last-child a {
    border-right: solid 1px #d2d2d2;
}

.dark-scheme .pagination li:last-child a {
    border-right-color: rgba(255, 255, 255, .1);
}

.pagination>.active>a {
    color: #ffffff;
}

/* pricing table */


/* ================================================== */

.pricing-box {
    color: #888;
}

.pricing-2-col {
    border-right: solid 1px #eee;
}

.pricing-2-col .pricing-box {
    width: 49%;
}

.pricing-3-col {}

.pricing-3-col .pricing-box {
    width: 32.33%;
}

.pricing-4-col {}

.pricing-4-col .pricing-box {
    width: 24%;
}

.pricing-5-col {}

.pricing-5-col .pricing-box {
    width: 19%;
}

.pricing-2-col,
.pricing-3-col,
.pricing-4-col,
.pricing-5-col {}

.pricing-box {
    float: left;
    text-align: center;
    margin: 5px;
    padding: 0;
    opacity: .8;
}

.pricing-box ul {
    margin: 0;
    padding: 0;
}

.pricing-box li {
    list-style: none;
    margin: 0;
    padding: 15px 0 15px 0;
    background: #eee;
}

.pricing-box li h4 {
    font-size: 22px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #888;
}

.pricing-box li h1 {
    font-size: 48px;
    margin: 0;
    margin-bottom: 5px;
    letter-spacing: 0px;
}

.pricing-box li.title-row {
    padding: 15px 0 15px 0;
}

.pricing-box li.title-row h4 {}

.pricing-box li.price-row {
    padding: 15px 0 15px 0;
    background: rgba(var(--secondary-color-rgb), .1);
}

.pricing-box li.deco {
    background: #f6f6f6;
}

.pricing-box a.btn {
    color: #eceff3;
}

.pricing-box li.price-row span {
    display: block;
    font-weight: 600;
}

.pricing-box li.btn-row {
    background: #ddd;
}

.pricing.pricing-box {}

.pricing.pricing-box ul {
    margin: 0;
    padding: 0;
}

.pricing.pricing-box li {
    background: #3b3b3b;
    color: #bbb;
    list-style: none;
    margin: 0;
    padding: 15px 0 15px 0;
}

.pricing.pricing-box li.deco {
    background: #333;
}

.pricing.pricing-box li h4 {
    font-size: 22px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #eceff3;
}

.pricing.pricing-box li h1 {
    font-size: 48px;
    margin: 0;
    margin-bottom: 5px;
    font-weight: 600;
    color: #eceff3;
}

.pricing.pricing-box li.title-row {
    background: #323232;
    padding: 15px 0 15px 0;
}

.pricing.pricing-box li.price-row {
    background: #2b2b2b;
    padding: 15px 0 15px 0;
}

.pricing.pricing-box li.price-row h1 {
    font-weight: 400;
}

.pricing.pricing-box li.price-row h1 span {
    display: inline-block;
    font-weight: 600;
}

.pricing.pricing-box li.price-row span {
    display: block;
    font-weight: 600;
}

.pricing.pricing-box li.btn-row {
    background: #202220;
}

.pricing-featured {
    position: relative;
}

.pricing-featured li {
    padding-top: 17px;
    padding-bottom: 17px;
}

.pricing-table {
    overflow: hidden;
    position: relative;
    background: #ffffff;
}

.pricing-table.table-featured{
    position: relative;
    z-index: 1; 
    /*transform: scale(1.05);*/
}

header.smaller{
    box-shadow: 0px 0px 80px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 80px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 80px 0px rgba(0,0,0,0.15);
}

.dark-scheme .pricing-table{
    background: var(--bg-color-dark);
}

.text-light .pricing-table {
    background: rgba(0, 0, 0, .1);
}

.pricing-table .top {
    padding: 40px;
}

.pricing-table .top h2 {
    font-size: 32px;
    line-height: 1.2em;
    margin-bottom: 0;
}

.dark-scheme .pricing-table .top h2{
    color: #ffffff;
}

.pricing-table .top p.plan-tagline {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.top .num {
    color: #35404e;
    font-family:var(--title-font);
    font-size: 40px;
    font-weight: 500;
}

.pricing-table .top>* {
    margin: 0px;
}

.pricing-table .top .price {
    margin-top: 40px;
    margin-bottom: 30px;
}

.pricing-table .top .price .currency {
    font-size: 24px;
    vertical-align: top;
    display: inline-block;
}

.pricing-table .top .price b {
    color: #fff;
    font-family: var(--body-font);
    font-weight: 500;
}

.pricing-table .mid .price .period {
    color: #fff;
    font-size: 14px;

}

.pricing-table .top .price .txt {
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
    color: rgba(255, 255, 255, .5);
}

.pricing-table .m,
.pricing-table .y {
    font-weight: 500;
    font-family: var(--title-font);
    font-size: 48px;
    letter-spacing: -2px;
}

.pricing-table .top .y {
    display: none;
}

.pricing-table .mid {
    padding: 40px;
    padding-bottom: 1px;
    border-left: none;
    border-right: none;
    background: var(--primary-color);
    color: #ffffff;
}

.pricing-table .bottom {
    border-top: 0px;
    text-align: center;
    /*background: rgba(0,0,0,.02);*/
}

.pricing-table .bottom ul {
    display: block;
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 20px 0 0 0;
    text-align: left;
    overflow: hidden;
    margin: 0 15px;
}

.pricing-table .bottom ul li:nth-child(odd){
    /*background: #f4f4f4;*/
}

.rtl .pricing-table .bottom ul {
    text-align: right;
}

.pricing-table .bottom ul li {
    padding: 6px;
    font-size: 15px;
    color: var(--title-font-color);
    opacity: .75;
    border-bottom: solid 1px rgba(255, 255, 255, .2);
    margin: 0 30px;
}

.dark-scheme .pricing-table .bottom ul li{
    color: #ffffff;
}

.text-light .pricing-table .bottom ul li {
    color: #ffffff;
}

.pricing-table .bottom ul li>span {
    color: #16A085;
    font-size: 20px;
    margin-right: 20px;
}

.pricing-table.zebra .bottom ul li:nth-child(odd) {
    background: #ffffff;
}

.pricing-table.zebra .bottom ul li:nth-child(even) {
    background: rgba(var(--secondary-color-rgb), .1);
}

.pricing-table .bottom>a {
    margin: 40px;
    width: auto;
}

.pricing-table .bottom i {
    margin-right: 15px;
    font-size: 12px;
    padding:5px;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
}

.rtl .pricing-table .bottom i {
    margin-right: 0px;
    margin-left: 10px;
}

.pricing-table .ribbon {
    width: 200px;
    position: absolute;
    top: 25px;
    left: -55px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: bold;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
}

.pricing-table.light {
    color: #222;
}

.pricing-table.light .top {
    background: rgba(var(--secondary-color-rgb), .1);
    color: #222;
}

.pricing-table.light .top h2 {
    color: #222;
}

.pricing-table.light .top .price b,
.pricing-table.light .top .price .txt {
    color: #222;
}

.pricing-table.light .top .price .txt {
    color: #555;
}

.pricing-table.light .top .price .month {
    color: #555;
}

.pricing-table.light .top .price .currency {
    color: #555;
}

.pricing-table.light .bottom {
    background: #eee;
}

.pricing-table.light .bottom ul li>span {
    color: #16A085;
}

.pricing-table.light .bottom ul li:nth-child(odd) {
    background: #ffffff;
}

.pricing-table.light .bottom ul li:nth-child(even) {
    background: rgba(var(--secondary-color-rgb), .1);
}

.pricing-table .ribbon {
    color: #fff;
}

.pricing-table.rec {
    -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.2);
}

.pricing-table .action {
    padding: 40px;
}

.pricing-table .action .btn-main{
    display: block;
    width: 100%;
}

.text-light .pricing-table .action {
    border-top: none;
}

.pricing-s2 {
    color: var(--dark-body-font-color);
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    background: rgba(255, 255, 255, .05);
}

.pricing-s2 .top {
    padding: 30px;
    padding-bottom: 20px;
    text-align: center;
}

.pricing-s2 .top h2 {
    font-size: 22px;
    margin-bottom: 5px;
    letter-spacing: normal;
}

.pricing-s2 .top p {
    margin-bottom: 30px;
}

.pricing-s2 .top>* {
    margin: 0px;
}

.pricing-s2 .top .price {
    margin-bottom: 30px;
}

.pricing-s2.text-light .top .price,
.pricing-s2.text-white .top .price, {
    color: #ffffff;
}

.pricing-s2 .top .price .currency {
    font-size: 24px;
    vertical-align: top;
    display: inline-block;
}

.pricing-s2 .top .price b {
    font-size: 40px;
    font-family: var(--body-font);
    font-weight: 500;
}

.pricing-s2 .top .price .month {
    color: #333;
    font-size: 14px;
}

.pricing-s2 .top .price .txt {
    color: #35404e;
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
}

.pricing-s2 .top a.btn-main {
    display: block;
    width: 100%;
    padding: 6px 10px;
}

.pricing-s2 .bottom {
    border-top: 0px;
    text-align: center;
}


.pricing-s2 a.btn-main {
    border-top: 0px;
    text-align: center !important;
    font-weight: bold;
}


.pricing-s2 .bottom ul {
    display: block;
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0px;
    text-align: left;
    overflow: hidden;
}

.pricing-s2 .bottom ul li {
    padding: 15px 30px;
    color: #505050;
}

.pricing-s2 .bottom ul li>span {
    color: #16A085;
    font-size: 20px;
    margin-right: 20px;
}

.pricing-s2 .bottom ul li:nth-child(odd) {
    background: #ffffff;
}

.pricing-s2 .bottom ul li:nth-child(even) {
    background: rgba(var(--secondary-color-rgb), .1);
}

.pricing-s2 .bottom>a {
    margin: 40px;
    width: auto;
}

.pricing-s2 .bottom i {
    margin-right: 10px;
}

.pricing-s2 .ribbon {
    width: 200px;
    position: absolute;
    top: 25px;
    left: -55px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: bold;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
}

.pricing-s2 .inner {
    padding-bottom: 10px;
}

.pricing-s2.bg .inner {
    background: rgba(var(--primary-color-rgb), .8);
}

.pricing-s2.bg-2 .inner {
    background: rgba(255, 88, 55, .8);
}

.pricing-s2.bg .top p {
    color: #cccccc;
}

.pricing-s2.bg .top h2,
.pricing-s2.bg .top .price,
.pricing-s2.bg .top .price .currency,
.pricing-s2.bg .top .month,

.pricing-s2.bg-2 .top h2,
.pricing-s2.bg-2 .top .price,
.pricing-s2.bg-2 .top .price .currency,
.pricing-s2.bg-2 .top .month {
    color: #ffffff;
}

/* de_pricing-table begin */
.de_pricing-table{
    position: relative;
    height: 100%;
    padding: 40px;
    padding-bottom: 10px;
}
.de_pricing-table .d-head,
.de_pricing-table .d-group,
.de_pricing-table .d-action{
    margin-bottom: 30px;
}
.de_pricing-table .d-head h3,
.de_pricing-table .d-head p{
    margin-bottom: 0px;
}
.de_pricing-table .d-head h3{
    font-size: 20px;
    margin-bottom: 10px;
}
.de_pricing-table .d-head p{
    line-height: 1.4em;
    min-height: 50px;
}
.de_pricing-table .d-price h4{
    font-size: 40px;
    margin-bottom: 5px;
    letter-spacing: -1px;
}
.de_pricing-table .d-price h4 span{
    font-size: 20px;
}
.de_pricing-table .d-group h4{
    font-size: 16px;
    margin-bottom: 5px;
}
.de_pricing-table .d-action p{
    font-size: 12px;
}
.de_pricing-table .d-list{
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    font-size: 15px;
}
.de_pricing-table .d-list li{
    padding-left: 25px;
}
.de_pricing-table .d-list li:before{
    position: absolute;
    left: 0;
    font-family: "FontAwesome";
    content: "\f00c";
    color: #ffffff;
    margin-right: 12px;
    margin-top: 7px;
    width: 16px;
    height: 16px;
    line-height: 1.2em;
    padding: 2px 0;
    font-size: 10px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
}

.de_pricing-table .d-recommend{
    position: absolute;
    top: 0;
    width: 100%;
    background: #333;
    left: 0;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.de_pricing-tables{
    border: solid 1px #dddddd;
    border-bottom: none;
}

.de_pricing-table.type-2{
    background: #ffffff;
    border-right: solid 1px #dddddd;
    border-bottom: solid 1px #dddddd;
}

.dark-scheme .de_pricing-tables{
    border: solid 1px rgba(255, 255, 255, .05);
    border-bottom: none;
}

.dark-scheme .de_pricing-table.type-2{
    background: #16172D;
    border-right: solid 1px rgba(255, 255, 255, .05);
    border-bottom: solid 1px rgba(255, 255, 255, .05);
}

.de_pricing-tables div:last-child .de_pricing-table.type-2{
    border-right: none;
}
/* de_pricing-table end */

.de-price{
    color:var(--title-font-color);
    font-weight: bold;    
    font-size: 40px;
    letter-spacing: -2px;
}
.de-price span{
    font-size: 20px;
    letter-spacing: 0;
}

.spinner {
    display: table-cell;
    vertical-align: middle;
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}

.spinner>div {
    width: 18px;
    height: 18px;
    background-color: #fff;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

/* preloader */


/* ================================================== */

/* ================================================== */

#de-loader {
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #333;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* progress bar */


/* ================================================== */

.de-progress {
    width: 100%;
    height: 8px;
    background: #ddd;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    overflow: hidden;
}

.de-progress .progress-bar {
    background: #333;
    height: 8px;
    box-shadow: none;
}

.de-progress {
    background: #ffffff;
}

.skill-bar {
    margin-bottom: 40px;
}

.skill-bar.style-2 .de-progress {
    background: rgba(0, 0, 0, .1);
}

.skill-bar .value {
    color: #ccc;
    font-size: 12px;
    font-weight: bold;
    float: right;
    margin-top: -40px;
}

.rtl .skill-bar .value {
    float: left;
}

.skill-bar h4 {
    font-size: 16px;
}


/* social-icons */


/* ================================================== */

.social-icons {
    display: inline-block;
}

.social-icons i {
    text-shadow: none;
    color: #fff;
    padding: 12px 10px 8px 10px;
    width: 34px;
    height: 34px;
    text-align: center;
    font-size: 16px;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    margin: 0 3px 0 3px;
    background: #ffffff;
}

.dark-scheme .social-icons i{
    background: rgba(255, 255, 255, .1);
}

.social-icons i.fa-google-plus {
    padding: 12px 14px 10px 6px;
}

.social-icons i:hover {
    background: #fff;
    border-color: #eceff3;
    color: #333;
}

.social-icons.big i {
    font-size: 26px;
    width: auto;
    height: auto;
    margin: 0 15px 0 15px;
}

.social-icons.big i:hover {
    background: none;
}

.de-color-icons i{
    text-shadow: none;
    color: #fff;
    padding: 12px 10px 8px 10px;
    width: 34px;
    height: 34px;
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    margin: 0 1px 10px 1px;
}


.de-color-icons .fa-twitter{background: #3ea1ec;}
.de-color-icons .fa-facebook{background: #344e86;}
.de-color-icons .fa-google-plus{background: #d73925;}
.de-color-icons .fa-reddit{background: #c23321;}
.de-color-icons .fa-linkedin{background: #0976b4;}
.de-color-icons .fa-pinterest{background: #cc2127;}
.de-color-icons .fa-stumbleupon{background: #eb4924;}
.de-color-icons .fa-delicious{background: #198cff;}

.de-color-icons span{
    cursor: poInter;
    display: inline-block;
}

.de-color-icons span:hover{
    transform: scale(1.1);
}

/*
.social-icons .fa-facebook{background:#3e5a9b;}
.social-icons .fa-twitter{background:#55aded;}
.social-icons .fa-google-plus{background:#df4f37;}
.social-icons .fa-linkedin{background:#0075ad;}
.social-icons .fa-rss{background:#fb6506;}
.social-icons .fa-skype{background:#28A8EA;}
*/

.social-icons.big i {
    margin: 0 15px 0 15px;
}

.social-icons.big i:hover {
    background: none;
}

.social-icons.s1 i {
    border: none;
    font-size: 16px;
    margin: 3px;
}

.social-icons.s1:hover i {
    background: none;
}

.social-icons.s1 {
    line-height: 0.5;
    text-align: center;
}

.social-icons.s1 .inner {
    display: inline-block;
    position: relative;
}

.social-icons.s1 .inner:before,
.social-icons.s1 .inner:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    top: 0;
    width: 100%;
}

.social-icons.s1 .inner:before {
    right: 100%;
    margin-right: 15px;
    margin-top: 20px;
}

.social-icons.s1 .inner:after {
    left: 100%;
    margin-left: 15px;
    margin-top: 20px;
}

.col-right {
    display: inline-block;
    float: right;
    margin-top: 22px;
}

/*
    .social-icons .fa-facebook{ background:#305fb3; }
    .social-icons .fa-twitter{  background:#00d7d5; }
    .social-icons .fa-rss{ background:#fca600;  }
    .social-icons .fa-google-plus{ background:#d7482a;  }
    .social-icons .fa-skype{ background:#12c7ef;    }
    .social-icons .fa-dribbble{ background:#f06eaa; }
    */


/* tabs */


/* ================================================== */

/* accordion
------------------------------ */


.accordion-section-title {
    color: #35404e;
    width: 100%;
    padding: 15px 0;
    cursor: poInter;
    font-family: var(--body-font);
    width: 100%;
    display: inline-block;
    font-size: 17px;
    transition: all linear 0.5s;
    text-decoration: none;
    font-weight: 600;
    overflow: none;
    border-bottom: solid 1px #dddddd;
}

.accordion-section-title:before {
    font-family: "FontAwesome";
    content: "\f107";
    float: right;
    color: #fff;
    text-align: center;
    padding: 0 8px 0 8px;
    font-size: 15px;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    width: 30px;
}


.rtl .accordion-section-title:before{
    float: left;
}

.accordion-section-title.active:before {
    content: "\f106";
}

.accordion-section-title.active {
    margin-bottom: 0px;
    text-decoration: none;
}

.accordion-section-title:hover {
    text-decoration: none;
}

.accordion-section-content {
    padding-top: 15px;
    padding-bottom: 5px;
    display: none;
    margin-bottom: 10px;
}


/* bs */

.accordion-item h2{
    line-height: 24px;
    display: block;
}

.expand-custom {
    background: #ffffff;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 3px;
    border: solid 1px #dddddd;
}

.dark-scheme .expand-custom{
    background: var(--bg-color-odd);
    border: var(--border-default);
}

.expand-custom h4 {
    margin: 0;
    margin-bottom: 5px;
}

.expand-custom p {
    margin: 0;
}

.expand-custom .table {
    display: table;
}

.expand-custom .toggle {
    display: block;
    margin-top: 10px;
    float: right;
    cursor: poInter;
}

.rtl .expand-custom .toggle {
    float: left;
}

.expand-custom .toggle:before {
    font-family: "FontAwesome";
    content: "\f107";
    font-size: 32px;
}

.expand-custom .toggle.clicked:before {
    content: "\f106";
}

.expand-custom .c1,
.expand-custom .c2,
.expand-custom .c3 {
    display: table-cell;
    vertical-align: middle;
}

.expand-custom .c1,
.expand-custom .c2 {
    padding-right: 30px;
}

.rtl .expand-custom .c1,
.rtl .expand-custom .c2 {
    padding-right: 0;
    padding-left: 30px;
}

.expand-custom .c1 img {
    width: 80px;
}

.expand-custom .c1 {
    width: 10%;
}

.expand-custom .c2 {
    width: 80%;
}

.expand-custom .c3 {
    width: 10%;
}

.expand-custom .details {
    display: none;
    margin-top: 30px;
}

.expand-custom .box-custom {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.de_tab .de_nav {
    overflow: hidden;
    padding-left: 0;
    margin: 0;
    padding: 0;
    font-weight: bold;
}

.de_tab .de_nav li {
    float: left;
    list-style: none;
    margin-right: 10px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.rtl .de_tab .de_nav li {
    float: right;
    margin-right: 0;
    margin-left: 0;
}

.d_coll .de_tab .de_nav{
    text-align: center;
}

.d_coll .de_tab .de_nav li{
    float: none;
    display: inline-block;
    margin-right: 5px;
}

.de_tab .de_nav li span {
    border: solid 1px rgba(0, 0, 0, .2);
    display: block;
    text-decoration: none;
    color: #646464;
    font-size: 15px;
    cursor: poInter;
}

.dark-scheme .de_tab .de_nav li span{
    color: rgba(255, 255, 255, .3);
    background: #292F45;
}

.de_tab.tab_methods .de_nav li span{
    border: solid 2px rgba(0, 0, 0, .1); 
}

.de_tab.tab_methods .de_nav li {
    background: none;
    display: inline-block;
    float: none;
    margin: 0 10px 0 0;
}

.de_tab.tab_methods .de_nav li span {
    padding: 35px 10px;
    color: rgba(0,0,0,.6);
    font-family: var(--body-font);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    border-radius: 12px;
    min-width: 140px;
}

.dark-scheme .de_tab.tab_methods .de_nav li span{
    color: rgba(255, 255, 255, .6);
}

.dark-scheme .de_tab.tab_methods .de_nav li.active span{
    color: #ffffff;
}


.de_tab.tab_methods .de_nav li span i{
    display: block;
    font-size: 30px;
    margin-bottom: 10px;
}

.de_tab.tab_methods.style-2 .de_nav li span {
    background: none;
    border-radius: 0px;
    border: solid 1px rgba(255, 255, 255, .5);
    font-size: 16px;
    color: #fff;
}

.de_tab.tab_methods.style-2.light .de_nav li span {
    background: #eee;
    color: #707070;
}

.de_tab.tab_methods.style-2 .de_nav li.active span {
    border: solid 2px rgba(255, 255, 255, .8);
    color: #222;
}

.de_tab.tab_methods .de_nav li .v-border {
    height: 60px;
    border-left: solid 1px rgba(255, 255, 255, .3);
    display: inline-block;
    margin-bottom: -5px;
}

.de_tab.tab_methods.dark .de_nav li .v-border {
    border-left: solid 1px rgba(0, 0, 0, .3);
}

.de_tab .de_nav li span {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.de_tab .de_tab_content {
    padding-top: 30px;
}

.de_tab.tab_methods .de_tab_content {
    background: 0;
    padding: 0;
    border: none;
    padding-top: 30px;
}

.de_tab.tab_methods.dark .de_tab_content {
    border-top: solid 1px rgba(0, 0, 0, .4);
}

.de_tab .de_nav li.active span {
    background: #FFF;
    color: #222;
}

.de_tab .de_nav li.active span .v-border {
    display: none;
}

.de_tab .de_nav li.active span {
    background: #27282b;
}

.de_tab.tab_methods .de_nav li.active span {
    background: none;
}

.de_tab.tab_methods.dark .de_nav li.active span {
    background: none;
    color: #333;
    border: solid 1px rgba(0, 0, 0, .8);
}

/* new added */

.de_tab.tab_methods.tab_6 .de_nav li span {
    padding: 20px 0 20px 0;
    font-size: 14px;
    line-height: 70px;
    width: 120px;
    height: 120px;
}

.de_tab.tab_methods.style-2 .de_nav li .arrow {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-top: 0px solid transparent;
    margin: 0 auto;
    margin-bottom: 20px;
}

.de_tab.tab_methods.style-2 .de_nav li.active .arrow {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid;
    margin: 0 auto;
}

.de_tab.tab_methods.style-2 .de_nav li {
    margin: 5px;
    display: inline-block;
}

.de_tab.tab_methods.style-2 .de_nav li span {
    font-family: var(--body-font);
    background: rgba(255, 255, 255, .1);
    border-radius: 0px;
    font-size: 16px;
    font-weight: lighter;
    color: #fff;
    opacity: .8;
    width: 120px;
    height: 100px;
    border: none;
    padding: 20px;
    border-radius: 300px;
    -moz-border-radius: 300px;
    -webkit-border-radius: 300px;
    -webkit-border-radius: 300px;
}

.de_tab.tab_methods.style-2 .de_nav li i {
    display: block;
    font-size: 36px;
    margin-bottom: 10px;
}

.de_tab.tab_methods.style-2.sub-style-2 .de_nav li span {
    background: rgba(0, 0, 0, .1);
    color: #000;
    opacity: .8;
}

.de_tab.tab_methods.style-2 .de_nav li.active span {
    color: #222;
    opacity: 1;
    border: none;
}

.de_tab.tab_methods.style-2 .de_nav li span:hover {
    border: none;
}

.de_tab.tab_methods.style-2 .de_tab_content {
    border: none;
}

.de_tab.tab_simple .de_nav li{
    min-width: 80px;
    text-align: center;
}

.de_tab.tab_simple .de_nav li span{
    font-size: 16px;
    font-weight: 600;
    padding: 2px 0px;
    margin-right: 30px;
    border: none;
    border-radius: 0;
    background: none;
    border-bottom: solid 2px rgba(255, 255, 255, 0);
    color: rgba(0, 0, 0, .5);
}

.dark-scheme .de_tab.tab_simple .de_nav li span{
    color: rgba(255, 255, 255, .5);
}

.rtl .de_tab.tab_simple .de_nav li span{
    margin-right: 0px;
    margin-left: 30px;
}

.de_tab.tab_simple .de_nav li.active span{
    color: rgba(0, 0, 0, 1);
}

.dark-scheme .de_tab.tab_simple .de_nav li.active span{
    color: rgba(255, 255, 255, 1);
}



/* tab de_light */

.de_light .de_tab.tab_style_2 .de_tab_content {
    border: none;
    background: none;
    padding: 40px 0 0 0;
    border-top: solid 1px #ddd;
}

.text-light .de_tab.tab_style_2 .de_tab_content {
    border-top: solid 1px rgba(255, 255, 255, .1);
}

.de_light .de_tab.tab_style_2 .de_nav {}

.de_light .de_tab.tab_style_2 .de_nav li {
    float: none;
    list-style: none;
    background: none;
    margin: 0 5px 0 5px;
    text-align: center;
    display: inline-block;
}

.de_light .de_tab.tab_style_2 .de_nav li img {
    width: auto;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .7;
}

.de_light .de_tab.tab_style_2 .de_nav li.active img {
    width: auto;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
}

.de_light .de_tab.tab_style_2 .de_nav li span {
    background: none;
    display: block;
    text-decoration: none;
    font-size: 14px;
    cursor: poInter;
    border: none;
}

.de_light .de_tab.tab_style_2 .de_nav li.active span {
    border-bottom: solid 6px;
    color: #333;
    background: none;
}

.text-light .de_tab.tab_style_2 .de_nav li.active span {
    color: #fff;
}

/* tab de_light */

.de_light .de_tab.tab_style_3 .de_tab_content {
    border: none;
    background: none;
    padding: 40px 0 0 0;
    border-top: solid 1px #ddd;
}

.text-light .de_tab.tab_style_3 .de_tab_content {
    border-top: solid 1px rgba(255, 255, 255, .1);
}

.de_light .de_tab.tab_style_3 .de_nav {
    text-align: center;
    background: #eee;
    display: inline-block;
    border-radius: 60px;
    margin-bottom: 30px;
    padding: 0;
}

.de_light .de_tab.tab_style_3 .de_nav li {
    float: none;
    list-style: none;
    background: none;
    text-align: center;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.de_light .de_tab.tab_style_3 .de_nav li img {
    width: auto;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .7;
}

.de_light .de_tab.tab_style_3 .de_nav li.active img {
    width: auto;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
}

.de_light .de_tab.tab_style_3 .de_nav li span {
    background: none;
    display: block;
    text-decoration: none;
    font-size: 14px;
    cursor: poInter;
    border: none;
    padding: 5px 30px 5px 30px;
    min-width: 150px;
}

.de_light .de_tab.tab_style_3 .de_nav li:last-child span {
    border-radius: 0 60px 60px 0;
}

.de_light .de_tab.tab_style_3 .de_nav li:first-child span {
    border-radius: 60px 0 0 60px;
}

.de_light .de_tab.tab_style_3 .de_nav li span:hover {
    background: #ddd;
}

.de_light .de_tab.tab_style_3 .de_nav li.active span {
    background: #eee;
    color: #fff;
}

.text-light .de_tab.tab_style_3 .de_nav li.active span {
    color: #fff;
}

/* tab de_light */

.de_tab.tab_style_4 .de_tab_content {
    border: none;
    background: none;
    padding: 0;
    border-top: none;
}

.text-light .de_tab.tab_style_4 .de_tab_content {
    border-top: none;
}

.de_tab.tab_style_4 .de_nav {
    text-align: center;
    background: #eee;
    display: inline-block;
    border-radius: 60px;
    margin-bottom: 30px;
    padding: 0;
    border: solid 2px #333;
}

.de_tab.tab_style_4 .de_nav li img {
    width: auto;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .7;
}

.de_tab.tab_style_4 .de_nav li.active img {
    width: auto;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
}

.de_tab.tab_style_4 .de_nav li {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    cursor: poInter;
    border: none;
    padding: 15px 30px 15px 30px;
    min-width: 150px;
    margin: 0;
    border-right: solid 2px #333;
}

.de_tab.tab_style_4 .de_nav li span {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    width: auto;
    display: inline-block;
}

.de_tab.tab_style_4 .de_nav li:last-child {
    border-radius: 0 60px 60px 0;
    border-right: none;
}

.de_tab.tab_style_4 .de_nav li:first-child {
    border-radius: 60px 0 0 60px;
}

.de_tab.tab_style_4 .de_nav li:hover {
    background: #ddd;
}

.de_tab.tab_style_4 .de_nav li.active,
.de_tab.tab_style_4 .de_nav li.active span {
    background: #333;
    color: #fff;
}

.text-light .de_tab.tab_style_4 .de_nav li.active {
    color: #fff;
}

.de_tab.tab_style_4 .de_nav li.active h4,
.de_tab.tab_style_4 .de_nav li.active h4,
.de_tab.tab_style_4 .de_nav li.active h4 span {
    color: #fff;
}

.tab_style_4 h4,
.de_tab.tab_style_4 .de_nav li span {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
}

.tab_style_4 h4 {
    margin: 0;
    font-size: 12px;
}

.de_tab .de_tab_content.tc_style-1 {
    background: none;
    padding: 0;
    padding-top: 30px;
}

.tab-small-post ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tab-small-post ul li {
    min-height: 60px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: solid 1px rgba(0, 0, 0, .1);
}

.tab-small-post ul li {
    border-bottom: solid 1px #202220;
}

.tab-small-post ul li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.tab-small-post ul li img {
    position: absolute;
}

.tab-small-post ul li span {
    display: block;
    padding-left: 64px;
}

.tab-small-post ul li span.post-date {
    font-size: 11px;
}

.de_tab.timeline.nav_4 li {
    display: inline-block;
    text-align: center;
    background: none;
    width: 24%;
}

.de_tab.timeline.nav_4 li span {
    background: none;
    border: none;
    margin-top: 20px;
    font-family: var(--body-font);
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.de_tab.timeline.nav_4 .dot {
    display: block;
    width: 8px;
    height: 8px;
    background: #fff;
    opacity: 1;
    margin: 0 auto;
    border-radius: 8px;
    margin-top: 1px;
}

.de_tab.timeline.nav_4 .dot:before,
.de_tab.timeline.nav_4 .dot:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    top: 0;
    width: 24%;
}

.de_tab.timeline.nav_4 li:last-child .dot:before,
.de_tab.timeline.nav_4 li:last-child .dot:after {
    border-bottom: none;
}

.de_tab.timeline li .num {
    font-size: 20px;
}

.de_tab.timeline .de_tab_content {
    background: none;
    border: none;
    padding-top: 30px;
}

.de_tab .navigation_arrow {
    text-align: center;
}

.btn-left,
.btn-right {
    background: none;
    border: solid 1px rgba(255, 255, 255, .2);
    padding: 10px 20px 10px 20px;
    margin: 5px;
    font-family: var(--body-font);
    text-transform: uppercase;
    letter-spacing: 5px;
}

.btn-right:after {
    font-family: FontAwesome;
    content: "\f054";
    padding-left: 10px;
}

.btn-left:before {
    font-family: FontAwesome;
    content: "\f053";
    padding-right: 10px;
}

a.btn-left,
a.btn-right {
    text-decoration: none;
}

/* team
    /* ================================================== */

.team {
    margin-bottom: 30px;
    text-align: center;
}

.team h5 {
    margin-bottom: 0;
}

.team_photo {
    margin-bottom: 10px;
}

.team img.team-pic {
    width: 100%;
    height: auto;
}

.pic-team img {
    width: 100%;
    height: auto;
    width: 150px;
    height: 150px;
    position: absolute;
}

.pic-team img.pic-hover {
    z-index: 100;
    display: none;
}

.team .team_desc {
    font-style: italic;
    margin-bottom: 10px;
}

.team .sb-icons {
    padding: 10px 0 0 0;
}

.team .sb-icons img {
    margin-right: 5px;
}

.team .columns {
    margin-bottom: 30px;
}

.team-list {
    min-height: 180px;
    margin-bottom: 40px;
}

.team-list .team-pic {
    width: 150px;
    height: 150px;
    position: absolute;
}

.team-list h5 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
}

.team-list.team_desc {
    font-style: italic;
    margin-bottom: 10px;
}

.team-list .small-border {
    float: none;
    margin: 15px 0 15px 0;
    background: #ddd;
    width: 50px;
}

.team-list .social {
    width: 150px;
    margin-top: 170px;
    text-align: center;
    position: absolute;
}

.team-list .social a {
    display: inline-block;
}

.team-list .info {
    padding-left: 170px;
}

.de-team-list {
    overflow: hidden;
}

.de-team-list .team-desc {
    padding: 20px;
    padding-bottom: 0;
    position: absolute;
    z-index: 100;
    top: 0;
    background: #111;
    color: #ccc;
}

.de-team-list .team-desc h4 {
    color: #fff;
}

.de-team-list .social {}

.de-team-list .social a {
    display: inline-block;
    padding: 0 5px 0 5px;
}

.de-team-list p.lead {
    font-size: 14px;
}

.de-team-list .small-border {
    float: none;
    margin: 15px 0 15px 0;
    background: #ddd;
    width: 50px;
}

.de-team-list .social a:hover {
    color: #fff;
}

/* testimonial
    /* ================================================== */

.de_testi {
    display: block;
    margin-bottom: 10px;
}

.de_testi blockquote {
    margin: 0;
    border: none;
    padding: 20px 30px 30px 80px;
    background: rgba(var(--secondary-color-rgb), .1);
    font-style: normal;
    line-height: 1.6em;
    font-weight: 500;
}

.de_testi blockquote p {
    margin-top: 10px;
}

.de_testi blockquote:before {
    font-family: FontAwesome;
    content: "\f10d";
    padding-bottom: 10px;
    font-size: 20px;
    display: inline-block;
    padding: 10px 20px 10px 20px;
    font-style: normal;
    background: #000;
    position: absolute;
    left: 15px;
    font-weight: normal;
}

.de_testi blockquote {
    color: #eceff3;
}

.de_testi blockquote,
.de_testi blockquote p {
    font-size: 20px;
    line-height: 1.7em;
    font-weight: normal;
}

.de_testi_by {
    font-style: normal;
    font-size: 12px;
    display: flex;
    margin-top: -20px;
}

.de_testi_by span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.de_testi_by img {
    width: 50px !important;
    height: 50px !important;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
}

.de_testi_pic {
    float: left;
    padding-right: 15px;
}

.de_testi_company {
    padding-top: 20px;
}

.de_testi.type-2 blockquote {
    display: block;
    font-family: var(--body-font);
    border: none;
    color: #5d6371;
    font-weight: 300;
    padding: 70px 40px 30px 40px;
    background: rgba(var(--secondary-color-rgb), .1);
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.dark-scheme .de_testi.type-2 blockquote{
    color: rgba(255, 255, 255, .75);
    background: rgba(255, 255, 255, .1);
}

.de_testi.type-2.no-bg blockquote {
    background: none;
}

.de_testi.type-2 blockquote:before {
    font-family: "FontAwesome";
    content: "\f10d";
    display: block;
    color: rgba(0, 0, 0, .1);
    z-index: 100;
    font-size: 20px;
    background: none;
    left: 20px;
}

.de_testi.type-2 i {
    font-size: 36px;
}

.de_testi.type-2.review blockquote {
    padding: 50px;
}

.de_testi.type-2.review blockquote:before {
    display: none;
}

.de_testi.type-2.review .p-rating {
    font-size: 14px;
}

.de_testi.type-2.review p {
    padding-top: 0;
}

.de_testi.type-2.review h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
}

.text-light .de_testi.type-2 blockquote {
    color: #fff;
    background:var(--bg-color-odd);
    border: var(--border-default);
}

.de_testi.type-2 blockquote p {
    font-family: var(--body-font);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7em;
    letter-spacing: -.1px;
}

.de_testi.type-2 .de_testi_by {
    font-size: 14px;
    font-weight: normal;
}

.de_testi.opt-3 blockquote {
    background: none;
    color: #555;
    padding-top: 0;
    font-weight: 400;
}

.de_testi.opt-3 blockquote p {
    font-size: 14px;
}

.de_testi.opt-3 blockquote:before {
    color: #fff;
}

.de_testi.opt-3 .de_testi_by {
    font-size: 14px;
    color: #000;
}

.de_testi.de-border blockquote {
    border: solid 5px rgba(0, 0, 0, .05);
}

#testimonial-masonry .item {
    margin-bottom: 30px;
}

.testimonial-list p {
    font-style: italic;
}

.testimonial-list,
.testimonial-list li {
    list-style: none;
    padding: 0px;
    font-size: 16px;
    line-height: 1.7em;
    padding-left: 30px;
}

.testimonial-list span {
    font-weight: bold;
    display: block;
    margin-top: 20px;
    font-size: 14px;
}

.testimonial-list.big-font li {
    font-size: 28px;
}

.testimonial-list:before {
    font-family: FontAwesome;
    content: "\f10d";
    font-size: 40px;
    color: rgba(0, 0, 0, .1);
    position: absolute;
    margin-left: -30px;
}

.testimonial-list.style-2,
.testimonial-list.style-2 li {
    padding-left: 0px;
}

.testimonial-list.style-2 {
    text-align: center;
}

.testimonial-list.style-2:before {
    margin: 0;
    position: static;
}

.testimonial-list.style-2 span {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
}


/* timeline */

/* timeline */

.timeline {
    position: relative;
    padding: 10px
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 127px;
    height: 100%;
    width: 1px;
    background: #eee;
}

.text-light .timeline:before {
    background: rgba(255, 255, 255, .1);
}

.timeline .tl-block {
    position: relative;
    margin-top: 10px;
}

.timeline .tl-block:after {
    content: ' ';
    clear: both;
    display: block;
}

.timeline .tl-block .tl-time {
    float: left;
    padding: 10px 20px 0px 20px;
    margin-top: -15px;
    border: solid 1px #ddd;
}

.timeline .tl-block .tl-time:after {
    width: 50px;
    height: 1px;
    background: #ddd;
}

.timeline .tl-block .tl-bar {
    float: left;
    width: 5%;
    position: absolute;
    margin-left: 114px;
}

.timeline .tl-block .tl-line {
    background: #333;
    margin-left: 10px;
    border-radius: 0px;
    width: 8px;
    height: 8px;
    font-size: 10px;
    text-align: center;
    line-height: 15px;
}

.timeline .tl-message {
    border-radius: 3px;
    float: left;
    margin-left: 150px;
    top: 0;
    margin-top: -50px;
    margin-bottom: 30px;
}

.timeline .tl-block .tl-message:after {
    display: block;
    content: ' ';
    clear: both;
}

.timeline .tl-block .tl-message .tl-main {
    float: left;
}

.timeline .tl-block .tl-message .tl-main .tl-title {}

.timeline .tl-block .tl-message .tl-main .tl-content {}

.timeline .tl-block .tl-message .tl-main .tl-content label {
    font-weight: bold;
    display: inline-block;
    width: 80px;
}

.timeline .tl-block .tl-message .tl-main .tl-content span {}

.timeline .tl-block .tl-message h4 {
    text-transform: none;
}

.timeline .tl-block .tl-time h4 {
    margin: 0 0 10px 0;
}

.timeline .tl-block .tl-main h4 {
    margin-top: -24px;
}

.timeline.exp:before {
    left: 214px;
}

.timeline.exp .tl-message {
    margin-left: 250px;
}

.timeline.exp .tl-block .tl-bar {
    margin-left: 200px;
}

/* timeline */

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 1px;
    background-color: #ddd;
    left: 50%;
}

.timeline>li {
    position: relative;
    margin-top: -50px;
}

.timeline>li:first-child {
    position: relative;
    margin-top: 0px;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li>.timeline-panel {
    width: 46%;
    float: left;
    /* border: 1px solid #d4d4d4; */
    border-radius: 2px;
    position: relative;
    background: rgba(var(--secondary-color-rgb), .1);
    overflow: hidden;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;

}

.timeline>li>.timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -14px;
    display: inline-block;
    border-top: 15px solid transparent;
    /* border-left: 15px solid #ccc;
  border-right: 0 solid #ccc; */
    border-bottom: 15px solid transparent;
    content: " ";
}

.timeline>li>.timeline-panel:after {
    position: absolute;
    top: 43%;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #ffffff;
    border-right: 0 solid #ffffff;
    border-bottom: 14px solid transparent;
    content: " ";
}

.timeline>li>.timeline-badge {
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 40px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 45%;
    left: 50%;
    margin-left: -7px;
    background-color: #fff;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 15px;
    left: -14px;
    right: auto;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
    margin-bottom: 0;
}

.timeline-body>p+p {
    margin-top: 5px;
}

.timeline-heading img {
    float: right;
    position: absolute;
    top: 30px;
    right: 30px;
}

.timeline .timeline-story {
    position: relative;
    padding: 40px;
}

.timeline-inverted .timeline-story {
    position: relative;
    padding: 40px;
    padding-left: 50px;
}

.timeline .wm {
    font-size: 200px;
    position: absolute;
    bottom: 20px;
    color: rgba(0, 0, 0, .3);
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.timeline h5 {
    background: #bbb;
    color: #ffffff;
    padding: 5px 20px 3px 20px;
    display: inline-block;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    margin-bottom: 15px;
    float: right;
}

.timeline-inverted h5 {
    float: left;
}

/* Timeline */
.container-timeline {
    position: relative;
}

.container-timeline ul {
    margin: 0;
    padding: 0;
    padding-left: 20px;
    display: inline-block;
    counter-reset: wa-process-counter;
}

.container-timeline ul li {
    list-style: none;
    margin: 0;
    border-left: 1px solid green;
    padding: 0 0 30px 40px;
    position: relative;
    counter-increment: wa-process-counter;
}

.container-timeline ul li:last-child {
    border: 0;
}

.container-timeline ul li::before {
    position: absolute;
    left: -21px;
    top: 0;
    content: counter(wa-process-counter);
    border: 1px solid green;
    border-radius: 0px;
    background: white;
    height: 45px;
    width: 45px;
    text-align: center;
    line-height: 41px;
    color: #ffffff;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    display: block;
    font-weight: bold;
}

.container-timeline ul li:hover::before{
    background: var(--primary-color);
    border-color: var(--primary-color);
}

.container-timeline p{
    font-size: 15px;
    line-height: 1.8em;
}

.timelines h2 {
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-bottom: 40px;
    font-size: 32px;
}

.d-flex-2,
.v-center {
    display: flex;
    align-items: center;
}

.timeline-area {
    padding: 80px 0;
}

.all-timelines {
    position: relative;
}

.timelines h2 {
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-bottom: 40px;
}

.all-timelines::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 2px;
    background: #efa22f;
    top: 20px;
}

.single-timeline {
    margin-bottom: 22px;
}

.timeline-blank {
    width: 50%;
}

.timeline-text {
    width: 50%;
    padding-left: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
}

.timeline-text h6 {
    color: #f0f1f2;
    font-weight: 900;
    display: inline-block;
    font-size: 1rem;
}

.timeline-text span {
    color: #f0f1f2;
    display: block;
    width: 100%;
}

.single-timeline:nth-child(even) .timeline-text span {
    text-align: right;
}

.t-circle {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: -6px;
    background: #efa22f;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
}

.single-timeline:nth-child(even) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.single-timeline:nth-child(even) .t-circle {
    right: -6px;
    left: unset;
}

.single-timeline:nth-child(even) .timeline-text {
    padding-left: 0;
    padding-right: 30px;
    text-align: right;
}

@media all and (max-width: 768px) {
    .all-timelines::before {
        right: unset;
        top: 0;
    }

    .single-timeline:nth-child(2n) .timeline-text {
        padding-left: 30px;
        padding-right: 0;
        text-align: left;
    }

    .single-timeline:nth-child(2n) .t-circle {
        left: -6px;
        right: unset;
    }

    .timeline-blank {
        display: none;
    }

    .timeline-text {
        width: 100%;
    }

    .single-timeline:nth-child(even) .timeline-text span {
        text-align: left !important;
    }
}

@media all and (max-width: 360px) {
    .all-timelines::before {
        top: 32px;
    }
}



/* css attributes */


/* margin top */

.mt0 {
    margin-top: 0;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mt40 {
    margin-top: 40px;
}

.mt50 {
    margin-top: 50px;
}

.mt60 {
    margin-top: 60px;
}

.mt70 {
    margin-top: 70px;
}

.mt80 {
    margin-top: 80px;
}

.mt90 {
    margin-top: 90px;
}

.mt100 {
    margin-top: 100px;
}

/* margin bottom */

.mb0 {
    margin-bottom: 0;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb25 {
    margin-bottom: 25px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb70 {
    margin-bottom: 70px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb90 {
    margin-bottom: 90px;
}

.mb100 {
    margin-bottom: 100px;
}

.mb0 {
    margin-bottom: 0;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb70 {
    margin-bottom: 70px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb90 {
    margin-bottom: 90px;
}

.mb100 {
    margin-bottom: 100px;
}

.ml0 {
    margin-left: 0;
}

.ml10 {
    margin-left: 10px;
}

.ml20 {
    margin-left: 20px;
}

.ml30 {
    margin-left: 30px;
}

.ml40 {
    margin-left: 40px;
}

.ml50 {
    margin-left: 50px;
}

.ml60 {
    margin-left: 60px;
}

.ml70 {
    margin-left: 70px;
}

.ml80 {
    margin-left: 80px;
}

.ml90 {
    margin-left: 90px;
}

.ml100 {
    margin-left: 100px;
}

.mr0 {
    margin-right: 0;
}

.mr10 {
    margin-right: 10px;
}

.mr20 {
    margin-right: 20px;
}

.mr30 {
    margin-right: 30px;
}

.mr40 {
    margin-right: 40px;
}

.mr50 {
    margin-right: 50px;
}

.mr60 {
    margin-right: 60px;
}

.mr70 {
    margin-right: 70px;
}

.mr80 {
    margin-right: 80px;
}

.mr90 {
    margin-right: 90px;
}

.mr100 {
    margin-right: 100px;
}

.mb-100 {
    margin-bottom: -100px;
}

.padding10 {
    padding: 10px;
}

.padding20 {
    padding: 20px;
}

.padding30 {
    padding: 30px;
}

.padding40 {
    padding: 40px;
}

.padding50 {
    padding: 50px;
}

.padding60 {
    padding: 60px;
}

.padding70 {
    padding: 70px;
}

.padding80 {
    padding: 80px;
}

.padding100 {
    padding: 100px;
}

.pt0 {
    padding-top: 0;
}

.pt10 {
    padding-top: 10px;
}

.pt20 {
    padding-top: 20px;
}

.pt30 {
    padding-top: 30px;
}

.pt40 {
    padding-top: 40px;
}

.pt50 {
    padding-top: 50px;
}

.pt60 {
    padding-top: 60px;
}

.pt70 {
    padding-top: 70px;
}

.pt80 {
    padding-top: 80px;
}

.pb0 {
    padding-bottom: 0;
}

.pb10 {
    padding-bottom: 10px;
}

.pb20 {
    padding-bottom: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

.pb40 {
    padding-bottom: 40px;
}

.pb50 {
    padding-bottom: 50px;
}

.pb60 {
    padding-bottom: 60px;
}

.pb80 {
    padding-bottom: 80px;
}

.pl130 {
    padding-left: 130px;
}

.pl30{
    padding-left: 30px;
}

.pr20 {
    padding-right: 20px;
}

.pr30 {
    padding-right: 30px;
}

/* margin top */

.mt-10 {
    margin-top: -10px;
}

.mt-20 {
    margin-top: -20px;
}

.mt-30 {
    margin-top: -30px;
}

.mt-40 {
    margin-top: -40px;
}

.mt-50 {
    margin-top: -50px;
}

.mt-60 {
    margin-top: -60px;
}

.mt-70 {
    margin-top: -70px;
}

.mt-80 {
    margin-top: -80px;
}

.mt-90 {
    margin-top: -90px;
}

.mt-100 {
    margin-top: -100px;
}

.absolute {
    position: absolute;
    z-index: 1;
}

.relative {
    position: relative;
    z-index: 1;
}

.z1000 {
    z-index: 1000;
}

.overflow-hidden {
    overflow: hidden;
}

.width100 {
    width: 100%;
}

.w-80px{
    width: 80px;
}
.w-100px{
    width: 100px;
}

.border1 {
    border-width: 1px;
}

.bordertop {
    border-top: solid 1px #eee;
}

.borderbottom {
    border-bottom: solid 1px #eee;
}

.pos-top {
    position: relative;
    z-index: 1000;
}

/* ================================================== */


/* anim */


/* ================================================== */

header,
de_tab .de_nav li span,
.feature-box-small-icon i,
a.btn-line:after,
.de_count,
.social-icons a i,
.de_tab.tab_methods .de_nav li span,
.de_tab.tab_methods .de_nav li span:hover,
.de-gallery .overlay .icon i,
.de-gallery .overlay .icon i:hover,
.product img,
.product img:hover,
.mfp-close,
.mfp-close:hover,
.pic-hover .bg-overlay,
.pic-hover:hover>.bg-overlay,
i.btn-action-hide,
.pic-hover.hover-scale img,
.text-rotate-wrap,
.text-rotate-wrap .text-item,
.overlay-v,
.carousel-item .pf_text .title,
.carousel-item:hover .pf_text .title,
.carousel-item .pf_text .title .type,
.carousel-item:hover .pf_text .title .type,
.owl-slider-nav .next,
.owl-slider-nav .prev,
#back-to-top.show,
#back-to-top.hide,
.social-icons-sm i,
.social-icons-sm i:hover,
.overlay-v i,
.overlay-v i:hover,
.de_tab .de_nav li span,
#popup-box.popup-show,
#popup-box.popup-hide,
.form-underline input[type=text],
.form-underline textarea,
.form-underline input[type=email],
.form-underline select,
.btn-rsvp,
.d-btn-close,
.btn-main,
.overlay-v span,
.de_tab.tab_style_4 .de_nav li,
.form-subscribe input[type=text],
#mainmenu a span,
#mainmenu a.active span,
.feature-box .inner,
.feature-box:hover .inner,
.feature-box .inner i,
.feature-box:hover .inner i,
.error_input,
.de_tab.tab_methods.style-2 .de_nav li.active .arrow,
.owl-item.active.center div blockquote,
.mask *,
.mask .cover,
.mask:hover .cover,
.mask img,
.mask:hover img,
.accordion .content,
a.btn-border,
.f_box span,
.f_box img,
.f_box.s2:hover img,
.mask .cover p,
.d-card:hover i,
.feature-box-type-2 i,
#contact_form input,
#form_subscribe input[type=text],
.table-pricing tbody tr,
#selector .dark-mode,
#selector .dark-mode:hover,
.feature-box.f-boxed,
.feature-box.f-boxed:hover,
.fp-wrap,
.fp-wrap div,
.fp-wrap img,
.feature-box-type-2,
.feature-box.style-3 i,
.feature-box.style-3 h4,
.logo-carousel img,
.f-box,
.f-box *,
.fp-wrap *,
.d-gallery-item *,
.de-box-image *,
.de-image-hover *,
#selector *,
a.a-underline *,
.activity-filter *,
.dropdown *,
.icon-box.style-2 *,
#quick_search,
.form-border *,
.d-carousel *,
.de-card *,
.de-image-text *,
.image-container *,
.de-country,
.de-country *,
#slider-carousel *,
.de-event-item *,
.de-color-icons *,
.container-timeline *,
.d_demo_img *,
#purchase-now *,
.img-hover-1,
.img-hover-1 *{
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    outline: none;
}

a.btn-fx:after,
a.btn-fx:hover:after,
a.btn-fx:before,
a.btn-fx:hover:before,
#mainmenu li,
#mainmenu li ul,
.widget-post li .post-date,
.author_list_pp img,
.items_filter *,
.owl-prev,.owl-next {
    -o-transition: .2s ease;
    -ms-transition: .2s ease;
    -moz-transition: .2s ease;
    -webkit-transition: .2s ease;
    transition: .2s ease;
    outline: none;
}

.feature-box:hover i.wm {
    -o-transition: 2s ease;
    -ms-transition: 2s ease;
    -moz-transition: 2s ease;
    -webkit-transition: 2s ease;
    transition: 2s ease;
    outline: none;
}

header.header-mobile #mainmenu>li>span {
    -o-transition: none;
    -ms-transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    transition: none;
    outline: none;
}

@-webkit-keyframes scaleIn {
    from {
        opacity: 1;
        -webkit-transform: scale3d(1.5, 1.5, 1.5);
        transform: scale3d(1.5, 1.5, 1.5);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(var(--primary-color-rgb));
        transform: scale3d(var(--primary-color-rgb));
    }
}

@keyframes scaleIn {
    from {
        opacity: 1;
        -webkit-transform: scale3d(1.5, 1.5, 1.5);
        transform: scale3d(1.5, 1.5, 1.5);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(var(--primary-color-rgb));
        transform: scale3d(var(--primary-color-rgb));
    }
}

.scaleIn {
    -webkit-animation-name: scaleIn;
    animation-name: scaleIn;
}

@-webkit-keyframes scaleInFade {
    from {
        opacity: 0;
        -webkit-transform: scale3d(1.5, 1.5, 1.5);
        transform: scale3d(1.5, 1.5, 1.5);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(var(--primary-color-rgb));
        transform: scale3d(var(--primary-color-rgb));
    }
}

@keyframes scaleInFade {
    from {
        opacity: 0;
        -webkit-transform: scale3d(1.5, 1.5, 1.5);
        transform: scale3d(1.5, 1.5, 1.5);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(var(--primary-color-rgb));
        transform: scale3d(var(--primary-color-rgb));
    }
}

.scaleInFade {
    -webkit-animation-name: scaleInFade;
    animation-name: scaleInFade;
}

@-webkit-keyframes scaleOutFade {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.75, .75, .75);
        transform: scale3d(.75, .75, .75);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(var(--primary-color-rgb));
        transform: scale3d(var(--primary-color-rgb));
    }
}

@keyframes scaleOutFade {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.75, .75, .75);
        transform: scale3d(.75, .75, .75);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(var(--primary-color-rgb));
        transform: scale3d(var(--primary-color-rgb));
    }
}

.scaleOutFade {
    -webkit-animation-name: scaleOutFade;
    animation-name: scaleOutFade;
}

@-webkit-keyframes widthInFade {
    from {
        opacity: 0;
        width: 1px;
    }

    100% {
        opacity: 1;
        width: 100px;
    }
}

@keyframes widthInFade {
    from {
        opacity: 0;
        width: 1px;
    }

    100% {
        opacity: 1;
        width: 100px;
    }
}

.widthInFade {
    -webkit-animation-name: widthInFade;
    animation-name: widthInFade;
}

/* ================================================== */


/* video */


/* ================================================== */

video,
object {
    display: inline-block;
    vertical-align: baseline;
    min-width: 100%;
    min-height: 100%;
}

.de-video-container {
    top: 0%;
    left: 0%;
    height: 500px;
    width: 100%;
    overflow: hidden;
}

.de-video-content {
    width: 100%;
    position: absolute;
    z-index: 10;
}

.de-video-overlay {
    position: absolute;
    width: 100%;
    min-height: 100%;
    background: rgba(20, 20, 20, .5);
}

.de-video-overlay.dotted {
    /* background: url(../images_02/dotted.png); */
}

.video-fixed {
    width: 100%;
    position: fixed;
    top: 0;
}

/* youtube, vimeo */

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.de-video-container .btn-line:hover:after,
.de-video-container a.btn-line:hover:after,
.de-video-container a.btn-line.hover:after {
    color: #eceff3;
}

/* ================================================== */


/* map */


/* ================================================== */


/* --------------- map --------------- */

#map {
    width: 100%;
    height: 500px;
}

.map iframe {
    width: 100%;
    border: solid 1px #ccc;
    padding: 2px;
    background: #fff;
}

.map-container {
    width: 100%;
    height: 380px;
    overflow: hidden;
}

.map-container iframe {
    width: 100%;
}

.de-map-hotspot{
    position: relative;
}

.de-map-hotspot img{
    width: 100%;
}

.de-spot{
    padding: 0;
    margin: 0;
    position: absolute;
    vertical-align: center;
    text-align: center;
}


.de-spot span{
    position: absolute;
    display:inline-block;
    background:#ffffff;
    padding:0 10px;
    font-size: 12px;
    font-weight: bold;
    left: 30px;
    line-height: 1.8em;
    color: #35404e;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
}

/*
.de-spot span:before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 35%;
    bottom: -15px;
  border: 10px solid transparent;
  border-top: 8px solid #333; /* IE8 Fallback 
  border-top: 8px solid #333;
  z-index: 2;
} */

.de-circle-1 {
    width: 15px;
    height: 15px;
    background-color: #62bd19;
    border-radius: 50%;
    position: absolute;
    margin:5px 0 0 5px;
    left:31%;
}

.de-circle-2 {
    border: 4px solid #62bd19;
    -webkit-border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.0;
    left:31%;
}
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

/* ================================================== */


/* 1A1C26 to action */


/* ================================================== */

.call-to-action-box {
    background: #f6f6f6;
    border: solid 1px #ddd;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(242, 242, 242, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0);
}

.call-to-action-box {
    background: #27282B;
    border: solid 1px #202220;
}

.call-to-action-box .inner {
    padding: 20px 20px 20px 20px;
}

.call-to-action-box .text {
    margin-right: 20px;
    float: left;
}

.call-to-action-box .act {
    float: right;
}

.call-to-action-box h4 {
    margin: 0;
    margin-top: 10px;
}

.call-to-action-box .btn {
    color: #eceff3;
}

.call-to-action-box {
    background: #202220;
    color: #eceff3;
}

.call-to-action-boxh4 {
    color: #eceff3;
}

/* ================================================== */


/* overide bootstrap */


/* ================================================== */

.dark-scheme .modal-header,
.nav-tabs{
    border-bottom-color: rgba(255, 255, 255, .2);
}

.dark-scheme .modal-content{
    background: var(--bg-color-dark);
}

.dark-scheme .modal-footer{
    border-top-color: rgba(255, 255, 255, .2);
}

.dark-scheme .accordion-body{
    background: var(--bg-color-dark);
    border-color: #292F45;
}

.nav-tabs .nav-link {
    font-weight: 600;
}

.tab-default .tab-content {
    margin-top: 20px;
}

.container-fluid {
    padding: 0px;
    margin: 0px;
}

.btn-primary {
    text-shadow: none;
    border: none;
}

.btn.btn-primary:hover {
    background: #555;
}

.img-responsive {
    display: inline-block;
}

.img-shadow {
    -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
}

#mainmenu li li a.img-hover-1{
    margin: 0;
    padding: 0;  
}

#mainmenu li li a.img-hover-1 img{
    border-radius:10px;
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    overflow: hidden;
    box-shadow: 5px 0px 10px 5px rgba(0,0,0,.03);
    -webkit-box-shadow: 5px 0px 10px 5px rgba(0,0,0,.03);
    -moz-box-shadow: 5px 0px 10px 5px rgba(0,0,0,.03);
    border: solid 1px #eeeeee;
}

.dark-scheme #mainmenu li li a.img-hover-1 img{
    border: solid 1px rgba(255, 255, 255, .2);
}

#mainmenu li li a.img-hover-1:hover img{
    transform: scale(1.1);
    box-shadow: 5px 10px 20px 5px rgba(0,0,0,0.1);
    -webkit-box-shadow: 5px 10px 20px 5px rgba(0,0,0,0.1);
    -moz-box-shadow: 5px 10px 20px 5px rgba(0,0,0,0.1);
}


img.img-auto {
    width: auto;
    height: auto;
}

img.img-rounded {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.images-group-s1 img{
    width:31.5%;
    margin-right:2.5%;
    float: left;
    padding: 5px;
}
.images-group-s1 img:nth-child(2){
    padding-top: 25%;
}
.images-group-s1 img:nth-child(3){
    padding-top: 12.5%;
    margin-right: 0;
}
.box-rounded {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.img-profile {
    border: solid 3px #fff;
}

.img-card-sm{
    width: 72px;
    background: #ffffff;
    margin: 3px;
    padding: 5px 0;
}

.label {
    font-family: "Inter", Arial, Helvetica, sans-serif;
}

table thead {
    font-weight: bold;
}

p.lead {
    font-size: 18px;
    line-height: 1.7em;
    margin-top: 0;
    font-weight: 500;
    color: #606060;
    letter-spacing: -0.2px;
}

.dark-scheme p.lead {
    color: rgba(255, 255, 255,.6);
}

p.lead.big {
    font-size: 18px;
}

p.sub {
    font-size: 14px;
}

p.p-info{
    font-size: 14px;
    margin: 0;
    padding: 0 0 10px 0;
}

p.d-small{
    font-size: 14px;
    line-height: 1.5em;
}

p.d-smaller{
    font-size: 12px;
    line-height: 1.5em;
}

.panel-group {
    opacity: .8;
}

.panel-heading,
.panel-body {
    background: #27282B;
    border: solid 1px #202220;
    border-radius: 0;
}

.panel-body {
    border-top: none;
}

.progress {
    background: #27282B;
}

.panel-heading h4 a {
    display: block;
}

.panel-heading h4 a:hover {
    color: #eceff3;
}

.panel-default,
.panel-collapse,
.panel-collapse,
.panel-group .panel {
    border: none;
    border-radius: 0;
}

.panel-heading {
    background: #222;
}

.table-bordered,
.table-bordered td {
    border: solid 1px #202220;
}

blockquote {
    font-family: var(--body-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4em;
    border: none;
    border-left: solid 3px #333333;
    padding-left: 30px;
    color: #606060;
}

.rtl blockquote {
    border-left: none;
    border-right: solid 3px #333333;
    padding-left: 0px;
    padding-right: 30px;
}

.dark-scheme blockquote{
    color: #ffffff;
}

blockquote span {
    font-family: var(--body-font);
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    margin-top: 20px;
    color: var(--title-font-color);
    opacity: .8;
}

.dark-scheme blockquote span{
    color: #ffffff;
}

blockquote.s2 {
    border: none;
    text-align: left;
    background: #f2f2f2;
    padding: 30px 30px 30px 60px;
    margin-top: 20px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

blockquote.s2:before {
    font-family: "FontAwesome";
    content: "\f10e";
    font-style: normal;
    position: absolute;
    margin-left: -30px;
}

blockquote.s1 {
    padding: 0px;
}

blockquote.s1:before {
    font-family: "FontAwesome";
    content: "\f10d";
    display: block;
    color: rgba(0, 0, 0, .1);
    z-index: -1;
    font-size: 40px;
    top: 0;
    left: 0;
}


.collapsible-link::before {
    content: '';
    width: 14px;
    height: 2px;
    background: #333;
    position: absolute;
    top: calc(50% - 1px);
    right: 1rem;
    display: block;
    transition: all 0.3s;
}

/* Vertical line */
.collapsible-link::after {
    content: '';
    width: 2px;
    height: 14px;
    background: #333;
    position: absolute;
    top: calc(50% - 7px);
    right: calc(1rem + 6px);
    display: block;
    transition: all 0.3s;
}

.text-light .collapsible-link:before,
.text-light .collapsible-link:after {
    background: #ffffff;
}



.rtl .collapsible-link::before {
    right: auto;
    left: 0px;
}

.rtl .collapsible-link::after {
    right: auto;
    left: 6px;
}

.rtl .rtl-show {
    display: block
}

.rtl .rtl-hide {
    display: none;
}

.collapsible-link[aria-expanded='true']::after {
    transform: rotate(90deg) translateX(-1px);
}

.collapsible-link[aria-expanded='true']::before {
    transform: rotate(180deg);
}

.collapsible-link.text-white {
    color: #ffffff;
}

.collapsible-link.text-white::after,
.collapsible-link.text-white::before {
    background: #ffffff;
}

.card h6 {
    font-weight: 500;
}

.card-header.text-light h6 {
    color: #ffffff;
}

.de-card{
    display: block;
    position: relative;
    overflow: hidden;
    background: #ffffff;
}
.de-card .de-image{
    position: relative;
    overflow: hidden;
}
.de-card img{
    position: relative;
}
.de-card:hover img{
    transform: scale(1.05);
}
.de-card .text{    
    border: solid 1px #d2d2d2;
    padding: 35px;
    padding-bottom: 25px;
    position: relative;
}
.de-card .de-price span{
    display: inline-block;
    font-weight: bold;
    color: #ffffff;
    padding: 0 10px;
    position: absolute;
    top: 32px;
    right: 35px;
}

.domain-card{
    text-align: center;
    padding: 30px;
    border: solid 1px #eeeeee;
    position: relative;
    height: 100%;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

.domain-card p{
    min-height: 60px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.5em;
}

.domain-card .d-price-before{
    text-decoration-line: line-through;
    margin-bottom: 10px;
}

.domain-card .d-price{
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 30px;
    color: var(--title-font-color);
    letter-spacing: -3px;
}

.domain-card .d-price .d-atr-1,
.domain-card .d-price .d-atr-2{
    font-size: 16px;
    letter-spacing: 0;
}

.domain-card img{
    width: 120px;
}

.box-icon{
    padding: 0;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    line-height: 1.6em;
}

a.box-icon:hover{
    color: var(--body-font-color) !important;
}

.box-icon .d-inner{
    padding-left: 56px;
}

.rtl .box-icon .d-inner{
    padding-left: 0;
    padding-right: 56px;
}


.box-icon h4{
    font-size: 16px;
    margin-bottom: 3px;
}

.box-icon img{
    width: 42px;
    position: absolute;
}

.dark-scheme .box-icon.s2{
    color: var(--dark-body-font-color);
}

.rtl .de-card .de-price span{
    right: auto;
    left: 35px;
}


a.de-card,
a.de-card:hover {
    color: #404040;
}

.de-card.s2 .d-price span{
    font-weight: bold;
    font-size: 20px;
}

.de-card.s2 h4{
    margin-bottom: 20px;
}


.de-image-text{
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative;
    overflow: hidden;
}
.de-image-text img{
    width: 100%;
    position: relative;
}
.de-image-text:hover img{
    transform: scale(1.05);
}
.de-image-text .d-text{
    color: #ffffff;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 40px;
    background: linear-gradient(0deg, rgba(30,30,30,0) 30%, rgba(30,30,30,1) 100%);
}
.de-image-text .d-text h4{
    color: #ffffff;
}


.de-image-text .d-text blockquote{
    border: none;
    font-size: 14px;
    color: rgba(255, 255, 255, .7);
    padding: 0;
    line-height: 1.6em;
}

.de-image-text .d-text blockquote .by{
    color: #ffffff;
    font-weight: 600;
    margin-top: 10px;
    padding-left: 30px;
}

.de-image-text .d-text blockquote .by:before{
    content: "";
    display: block;
    position: absolute;
    left: 40px;
    margin-top: 10px;
    width: 20px;
    height: 1px;
    background: #ffffff;
}


.accordion-style-1 {
    overflow: visible;
}

.accordion-style-1 .card {
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    overflow: hidden;
}

.phone-num-big i {
    font-size: 48px;
    display: block;
}

.pnb-text {
    display: block;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.pnb-num {
    display: block;
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0 20px 0;
}

/* ======================================== */


/* toggle */


/* ======================================== */

.toggle-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.toggle-list li {
    margin-bottom: 10px;
    background: #fff;
    border: solid 1px #ddd;
    border-bottom: solid 3px #ddd;
}

.toggle-list li h2 {
    -moz-user-select: none;
    -html-user-select: none;
    user-select: none;
    color: #555;
    font-size: 16px;
    line-height: 20px;
    font-family: "Inter", Arial, Helvetica, sans-serifr;
    padding: 10px 20px 0px 60px;
    font-weight: 700;
    display: block;
    letter-spacing: normal;
}

.toggle-list li .acc_active:hover {
    color: #777;
    /* background: url(../images_02/icon-slide-plus.png) 20px center no-repeat; */
    padding-left: 80px;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.toggle-list li .acc_noactive:hover {
    color: #777;
    /* background: url(../images_02/icon-slide-min.png) 20px center no-repeat; */
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.toggle-list .ac-content {
    display: none;
    padding: 20px;
    border-top: solid 1px #e5e5e5;
}

.acc_active {
    /* background: url(../images_02/icon-slide-plus.png) 20px center no-repeat; */
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    height: 32px;
}

.acc_noactive {
    /* background: url(../images_02/icon-slide-min.png) 20px center no-repeat; */
}

/* ================================================== */


/* image position */


/* ================================================== */

.img-left {
    float: left;
    margin: 5px 15px 15px 0;
}

.img-right {
    float: right;
    margin: 5px 0 15px 15px;
}

/* ================================================== */


/* flickr */


/* ================================================== */

#flickr-photo-stream img {
    width: 48px;
    height: auto;
    margin: 0 12px 12px 0;
    float: left;
}

#flickr-photo-stream a img {
    border: solid 2px #fff;
}

#flickr-photo-stream a:hover img {
    border: solid 2px #777777;
}

hr {
    border-top: solid 1px #555;
}

hr.blank {
    border: none;
    margin: 20px 0 20px 0;
}

/* ================================================== */


/* font awesome icons */


/* ================================================== */

i {
    display: inline-block;
}

i.large {
    font-size: 72px;
    text-align: center;
    display: inline-block;
    float: none;
}

i.medium {
    font-size: 20px;
    padding: 10px;
    text-align: center;
    display: inline-block;
    float: none;
}

i.small {
    font-size: 12px;
    padding: 4px;
    text-align: center;
    display: inline-block;
    float: none;
}

i.circle-icon {
    border-radius: 75px;
    -moz-border-radius: 75px;
    -webkit-border-radius: 75px;
}

.fa-android {
    color: #469159;
}

.fa-apple {
    color: #0EC3F7;
}

/* extra ^ */

.de_center p {
    width: 100%;
}

.de_center .de_icon {
    float: none;
    margin: 0 auto;
}

/* ================================================== */


/* transition */


/* ================================================== */

a,
a:hover,
.test-column,
.test-column:hover {
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s;
}

.de_contact_info {
    font-size: 40px;
    font-weight: bold;
}

.de_contact_info i {
    float: none;
    margin-right: 20px;
}

.center-div {
    margin: 0 auto;
}

/* ================================================== */


/* gallery */


/* ================================================== */

#gallery-carousel .item {
    text-align: center;
    font-size: 12px;
    width: 100%;
    cursor: e-resize;
}

.de_carousel .item {
    width: 100%;
    display: block;
}

#gallery-carousel .item img {
    width: 100%;
    height: auto;
}

#gallery-carousel .item h4 {
    margin-bottom: 0;
    font-size: 14px;
}

.carousel-item {
    width: 100%;
}

.carousel-item img {
    width: 100%;
    height: auto;
}

.overlay-v {
    text-align: center;
    width: 100px;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.overlay-v i {
    background: #333;
    padding: 10px;
    width: 48px;
    height: 48px;
    font-weight: normal;
    font-size: 32px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    margin-top: 100%;
    color: #222;
    opacity: 0;
}

.overlay-v:hover i {
    margin-top: 25%;
    opacity: 1;
}

.overlay-v span {
    background: #1A1C26;
    padding: 40px;
    width: 100%;
    height: 100%;
    font-weight: normal;
    margin-top: 100%;
    color: #222;
    opacity: 0;
    position: absolute;
    left: 0;
}

.overlay-v:hover span {
    margin-top: 0px;
    opacity: .9;
}

.carousel-item .pf_text .title {
    display: block;
    position: absolute;
    width: 100%;
    top: 80%;
    font-size: 20px;
    font-weight: 700;
    height: 0;
    color: rgba(255, 255, 255, 0);
    letter-spacing: 0;
}

.overlay-v:hover {
    background: rgba(30, 30, 30, .5);
}

.carousel-item:hover .pf_text .title {
    color: rgba(255, 255, 255, 1);
    top: 75%;
}

.carousel-item .pf_text .title .type {
    display: block;
    color: rgba(255, 255, 255, 0);
    font-size: 12px;
    font-weight: 400;
}

.carousel-item:hover .pf_text .title .type {
    color: rgba(255, 255, 255, 1);
}

.pf_text {
    color: #eceff3;
    letter-spacing: 5px;
}

.pf_text h4 {
    color: #eceff3;
    margin-bottom: 0;
    font-size: 14px;
}

.project-info {
    color: #ccc;
    margin-left: 20px;
}

.de_light .project-info {
    color: #606060;
}

.project-info h2 {
    margin-top: 0;
    font-size: 18px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.project-info h4 {
    font-weight: bold;
}

.project-info .details {
    padding: 20px;
    border: solid 1px rgba(255, 255, 255, .2);
    background: rgba(0, 0, 0, .5);
    margin-bottom: 30px;
}

.de_light .project-info .details {
    background: #eee;
    border-color: #ddd;
}

.project-infoproject-info span {
    display: block;
}

.project-info span.title {
    display: inline-block;
    min-width: 80px;
}

.project-info span.val {
    margin-bottom: 15px;
    font-weight: bold;
}

.project-view {
    padding: 120px 0 80px 0;
}

.project-images img {
    width: 100%;
}

.picframe {
    position: relative;
    overflow: hidden;
}

.picframe img {
    width: 100%;
}



#d-btn-close-x {
    width: 100px;
    height: 100px;
    display: block;
    /* background: url(../images_02/close.png) center no-repeat; */
    margin: 0 auto;
    margin-bottom: 80px;
    cursor: poInter;
    padding: 20px;
}

#d-btn-close-x {
    /* background: url(../images_02/close-2.png) center no-repeat; */
}

.project-name {
    font-size: 12px;
    font-weight: bold;
    display: block;
}

.info-details .info-text {
    margin-bottom: 20px;
}

.info-details .title {
    display: block;
    font-weight: bold;
}

span.overlay {
    font-family: var(--body-font);
    text-align: center;
    display: block;
    width: 100px;
    background: rgba(var(--primary-color-rgb), .8);
    position: absolute;
    opacity: .75;
    z-index: 500;
}

span.icon {
    cursor: poInter;
}

.f_box {
    display: block;
    position: relative;
    float: left;
    font-size: 16px;
    overflow: hidden;
    z-index: 1;
}

.f_box:hover .f_bg {
    opacity: .3;
}

.grid-item .f_box img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .8;
}

.f_box:hover img {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
    transform: scale(1.2);
    opacity: 1;
}

.f_box .f_bg {
    background: #111111;
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: .5;
}

.f_box .f_bg.s1 {
    opacity: 0;
}

.f_box:hover .f_bg.s1 {
    opacity: .2;
}

.f_box .f_cap_wrap {
    display: table;
    width: 100%;
    height: 100%;
    position: absolute;
    color: #fff;
    z-index: 2;
    text-align: center;
    font-weight: bold;
}

.f_box .f_cap_wrap .f_cap {
    vertical-align: middle;
    display: table-cell;
}

.f_box:hover .f_cap {
    transform: scale(1.05);
}


.f_box .f_text {
    height: 0;
    overflow: hidden;
    font-family: Helvetica, Arial;
    font-weight: normal;
    display: inline-block;
    width: 70%;
    font-size: 14px;
}

.f_box:hover .f_text {
    height: auto;
}

.f_box img {
    width: 100%;
}

.f_box.s2:hover .f_bg {
    opacity: .4;
}

.f_box.s2 .f_bg {
    opacity: 0;
}

.f_box.s2:hover img {
    transform: scale(1.2);
}

.f_box.s2 {
    position: static;
}

.f_box.s2 .f_cap_wrap {
    opacity: 0;
}

.f_box.s2:hover .f_cap_wrap {
    opacity: 1;
    margin-top: 0;
}

.f_box .f_cap h1,
.f_box .f_cap h4 {
    color: #ffffff;
}

.f_box.s2 .f_cap {
    font-size: 20px;
    transform: scale(1.2);
}

.f_box.s2:hover .f_cap {
    transform: scale(1);
}

.f_box:hover h2 span.underline {
    width: 100%;
}

.overlay .info-area {
    display: block;
    margin-top: 10%;
}

.overlay .sb-icons {
    display: block;
    margin-top: 200px;
}

.de-gallery .overlay {
    text-align: center;
}

.de-gallery .overlay .pf_text {
    display: inline-block;
    opacity: 0;
}

.de-gallery .project-name {
    font-size: 16px;
    letter-spacing: 0;
}

.de-gallery .overlay .icon {
    display: inline-block;
    margin-top: 200px;
    text-align: center;
}

.de-gallery .overlay .icon i {
    text-shadow: none;
    color: #fff;
    border: solid 1px rgba(255, 255, 255, .1);
    font-size: 22px;
    padding: 20px;
    width: 60px;
    height: 60px;
    text-align: center;
    margin-left: 10px;
}

.de-gallery .overlay .icon i.icon-info {
    margin-left: 0px;
}

.de-gallery .overlay .icon i:hover {
    color: #222;
    background: #fff;
}

.grid_gallery .item {
    margin-bottom: 30px;
}

a.img-icon-url,
a.img-icon-zoom {
    display: inline-block;
    width: 36px;
    height: 36px;
    /* background: url(../images_02/icon-url.png) center no-repeat; */
    margin: 0 auto;
    margin: 0 2px 0 2px;
}

a.img-icon-zoom {
    /* background: url(../images_02/icon-zoom.png) center no-repeat; */
}

.pf_full_width {
    width: 100%;
}

.pf_full_width .item {
    float: left;
    width: 24.99%;
}

.pf_4_cols {
    width: 101%;
}

.pf_full_width.pf_3_cols .item {
    width: 33.3%;
}

.pf_full_width.pf_2_cols .item {
    width: 49.9%;
}

.gallery_border {}

.pf_full_width.gallery_border .item,
.pf_full_width.gallery_border.pf_2_cols .item,
.pf_full_width.gallery_border.pf_3_cols .item {
    float: left;
    width: 23.9%;
    margin-right: 1%;
    margin-bottom: 1%;
}

.pf_full_width.gallery_border.pf_3_cols .item {
    width: 32.3%;
}

.pf_full_width.gallery_border.pf_2_cols .item {
    width: 48.9%;
}

.pf_full_width img {
    float: left;
    width: 100%;
}

.pf_full_width.grid {
    padding: .75%;
    padding-bottom: 0;
}

.pf_full_width.grid .item {
    margin: 0 .75% .75% 0;
    width: 32%;
}

.pf_full_width.grid.pf_4_cols .item {
    margin: 0 .75% .75% 0;
    width: 23.9%;
}

.pf_full_width.grid.pf_2_cols .item {
    margin: 0 .75% .75% 0;
    width: 48.5%;
}

.gallery-cols .item {
    margin-bottom: 30px;
}

.pic-grey {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

/* ---- grid ---- */

.grid {
    width: 100%;
}


/* clear fix */
.grid:after {
    content: '';
    display: block;
    clear: both;
}

/* ---- .element-item ---- */

/* 5 columns, percentage width */

.grid-sizer {
    width: 5%;
}

.grid-item {
    position: relative;
    float: left;
    border: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

/* ================================================== */


/* widget */


/* ================================================== */

#sidebar {
    padding-left: 30px;
    position: relative;
}
.rtl #sidebar {
    padding-left: 0px;
    padding-right: 30px;
}

#sidebar.fixed {
    position: fixed;
    top: 0;
}

#sidebar.post-left {
    padding-left: 0px;
    padding-right: 30px;
}

.widget {
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.widget h4 {
    font-size: 20px;
    margin-top: 0;
    letter-spacing: normal;
}

.widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.widget ul li {
    margin: 3px 0;
}

.widget .small-border {
    height: 2px;
    width: 30px;
    background: #fff;
    margin: 0px 0 20px 0;
    display: block;
}

.widget_category li {
    padding: 8px 20px 8px 20px;
    margin-bottom: 8px;
    background: #111;
}

.widget_category li:before {
    font-family: "FontAwesome";
    content: "\f07b";
    margin-right: 10px;
}

.de_light .widget_category li {
    background: none;
    border: solid 1px #eee;
}

.widget_category li a {
    color: #606060;
}

.widget_recent_post li {
    padding-left: 20px;
    /* background: url(../images_02/list-arrow.png) left 2px no-repeat; */
    border-bottom: solid 1px #222;
    padding-bottom: 7px;
    margin-bottom: 5px;
}

.widget_recent_post li a {
    color: #555;
}

.widget-post li {
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.widget-post li a {
    font-weight: 600;
    display: block;
    text-decoration: none;
    color: #606060;
}


.dark-scheme .widget-post li a{
    color: #ffffff;
}

.widget-post .post-date {
    position: absolute;
    color: #222;
    margin-right: 10px;
    text-align: center;
    width: 50px;
    font-size: 10px;
    text-transform: uppercase;
}

.widget-post li:hover>.post-date {
    background: #222;
    color: #fff;
}

.widget-post .date {
    margin-top: 5px;
    margin-right: 10px;
    float: left;
    background: #333;
    color: #fff;
    display: inline-block;
    padding: 2px 3px 2px 3px;
    line-height: 1.4em;
    font-size: 12px;
    width: 60px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
}

.rtl .widget-post .date {
    float: right;
}

.widget .comments li {
    padding-left: 20px;
    /* background: url(../images_02/list-arrow.png) left 2px no-repeat; */
    border-bottom: solid 1px #eee;
    padding-bottom: 7px;
    margin-bottom: 5px;
}

.widget .tiny-border {
    width: 100%;
    background: rgba(255, 255, 255, .1);
}

.footer-light .widget .tiny-border {
    background: rgba(0, 0, 0, .1);
}

.widget_tags li {
    text-shadow: none;
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 16px;
}

.widget_tags li a {
    font-size: 12px;
    text-decoration: none;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    color: #fff !important;
    padding: 5px 10px 7px 10px;
    margin: 0 2px;
}

.de_light .widget_tags li a {
    color: #606060;
    border: solid 1px rgba(0, 0, 0, .2);
}

.widget_tags li a:hover {
    color: #555;
    border-color: #555;
}

.de_light .widget_tags li a:hover {
    color: #111;
    border-color: #111;
}

.widget_top_rated_product ul {
    padding: 0;
}

.widget_top_rated_product li {
    min-height: 80px;
}

.widget_top_rated_product li .text {
    padding-top: 5px;
    padding-left: 75px;
}

.widget_top_rated_product li img {
    position: absolute;
    width: 60px;
    height: 60px;
}

.widget_tags li a {
    border-color: #555;
}

.widget_tags li a:hover {border-color: #eceff3;
}

footer .widget {
    border: none;
    margin-bottom: 0;
}

.widget_tags ul {
    margin-top: 30px;
}

footer .widget.widget_tags li a {
    border-color: #222;
}

footer .widget_recent_post li {
    padding: 0 0 10px 0;
    margin: 0;
    margin-bottom: 10px;
    background: none;
    border-bottom-color: rgba(255, 255, 255, .1);
}

footer .widget_recent_post li a {
    color: #ccc;
}

footer .widget h5 {
    margin-bottom: 20px;
}

footer .widget_list li {
    padding: 0 0 10px 0;
    margin: 0;
    margin-bottom: 10px;
    background: none;
    border-bottom-color: rgba(255, 255, 255, .1);
}

footer .widget_list li a {
    color: #ccc;
}

footer .tiny-border span {
    background: #ffffff;
    display: block;
    height: 1px;
    width: 30px;
}


.side-layout header {
    width: 20%;
    height: 100%;
    background: #222;
    position: fixed;
    padding: 30px;
    top: 0;
}

.side-layout header.header-light {
    background: #fff;
    border-right: solid 1px #eee;
}

.side-layout #content {
    width: 80%;
    float: right;
}

.side-layout footer {
    width: 80%;
    float: right;
}

.side-layout .container {
    width: 90%;
}

.side-layout section {
    width: 100%;
    float: right;
}


.side-layout header .info {
    display: none;
}

.side-layout #mainmenu {
    padding: 0;
    margin-top: 40px;
}

.side-layout #mainmenu>li {
    display: block;
    width: 100%;
    border-bottom: solid 1px rgba(255, 255, 255, .1);
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.side-layout header.smaller #mainmenu>li {
    border-bottom: solid 1px rgba(0, 0, 0, .1);
}

.side-layout header.smaller {
    border-right: solid 1px #f6f6f6;
}

.side-layout header.header-dark.smaller {
    border-right: none;
}

.side-layout #mainmenu>li:last-child {
    border-bottom: none;
}

.side-layout #mainmenu>li>a {
    padding: 0;
    margin: 0;
}

.side-layout #mainmenu>li>a span {
    display: none;
}

.side-layout #mainmenu>li>ul {
    top: 0;
    margin-left: 120%;
}

.side-layout #mainmenu li ul {
    border: solid 1px #888;
}

.side-layout header div#logo {
    display: block;
    float: none;
    text-align: center;
}

.side-layout header div#logo img {
    width: 100%;
}

.side-layout #subheader {
    width: 80%;
    float: right;
    text-align: left;
}

.side-layout #subheader h1 {
    margin-top: 0;
    font-size: 24px;
}

.side-layout #subheader .small-border-deco span {
    margin: 0;
}

.side-layout.side-custom-bg header {
    /* background: url(../images/background/bg-header.jpg) top left; */
    background-size: cover;
}

.bg-color-secondary h1,
.bg-color-secondary h2,
.bg-color-secondary h3,
.bg-color-secondary h4,
.bg-color-secondary h5,
.bg-color-secondary h6,
.p-title.invert,
.p-tagline,
i.bg-color-secondary {
    color: #ffffff;
}


.d_wrap{
    position: relative;
}
.d_wrap img{
    position: relative;
}
.d_wrap_sm-box{
    font-size: 15px;
    font-family: var(--title-font);
    font-weight: bold;
    display: inline-block;
    position: absolute;
    background: #ffffff;
    padding: 10px 15px;
    color: #2f394b;
    box-shadow: 5px 25px 20px 5px rgba(0,0,0,0.1);
    -webkit-box-shadow: 5px 25px 20px 5px rgba(0,0,0,0.1);
    -moz-box-shadow: 5px 25px 20px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

.d_wrap_sm-box i{
    margin-right: 10px;
    background: #ddd;
    padding: 5px;
    width: 25px;
    height: 25px;
    border-radius: 30px;
    text-align: center;
    color: #fff;
}

.d_wrap_sm-box h3{
    font-size: 24px;
    margin-bottom: 5px;
}

.d_wrap_sm-box h6{
    font-weight: 500;
    font-size: 12px;
}

.d_wrap_sm-box.b1{
    left: 0;
    top: 20%;
}

.d_wrap_sm-box.b2{
    right: 0;
    top: 25%;
}

.d_wrap_sm-box.b3{
    right: 50%;
    top: 65%;
}

#cookit {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 1rem 2rem;
  display: flex;
  z-index: 1022;
  background: rgba(20,20,20, 1) !important;
}
.dark-scheme #cookit{
    border-top: solid 1px rgba(255, 255, 255, .15);
}
#cookit.hidden { display: none; }

#cookit #cookit-container {
  margin: auto;
  width: 1300px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#cookit #cookit-container > * { margin: .4rem; }
#cookit #cookit-container p { line-height: 1.4rem; }

#cookit #cookit-container a { text-decoration: none; }
#cookit #cookit-container a:hover { opacity: .9; }

#cookit #cookit-container #cookit-button {
    color: #ffffff !important;
    font-size: 14px;
    font-weight: bold;
    padding: .25rem 2rem;
    margin-left: auto;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
}

/* marquee */


.d-flex {
  display: flex;
  white-space: nowrap;
}

.de-marquee-list {
  display: flex;
  align-items: top;
  -webkit-animation: loop 40s infinite linear;
  animation: loop 40s infinite linear;
}

.de-marquee-list .d-item-txt{
  font-size: 120px;
  line-height: 120px;
  letter-spacing: -5px;
  font-style: normal;
  font-weight: 600;
  font-family: var(--title-font);
  color: #222733;

    background: -moz-linear-gradient(180deg, rgba(var(--primary-color-rgb),.5) 0%, rgba(var(--secondary-color-rgb),.5) 100%);
    background: -webkit-linear-gradient(180deg, rgba(var(--primary-color-rgb),.5) 0%, rgba(var(--secondary-color-rgb),.5) 100%);
    background: linear-gradient(180deg, rgba(var(--primary-color-rgb),.5) 0%, rgba(var(--secondary-color-rgb),.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

.de-marquee-list  .d-item-display{
  display: inline;
}

.de-marquee-list .d-item-dot{
  max-width: 10px;
  max-height: 10px;
  margin: 0 50px;
  padding: 7px;
  border-radius: 50%;
  display: inline-block;
  transform: translateY(-30px);
  background:rgba(0, 0, 0, .3);
}

.text-light .de-marquee-list .d-item-dot{
    background: rgba(255, 255, 255, .3);
}

.de-marquee-list.style-2 .d-item-txt{
    font-size: 160px;
    background: rgba(255, 255, 255, .2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.de-marquee-list.style-2 .d-item-dot{
    transform: translateY(-40px);
    background: rgba(255, 255, 255, .2);
}

@keyframes loop {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* marquee */



/* v-marquee */

.v-marquee-wrapper{
  position: relative;
  text-align:center;
  overflow: hidden;
}
.v-marquee-wrapper .v-marquee-container{
  overflow:hidden;
  margin:0 auto !important;
  text-align:center;
}
.v-marquee-inner span{
  height:50%;
}
.v-marquee-wrapper .v-marquee-block{
  width: 100%;
  height: 750px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  float:left;
}
.v-marquee-inner{
  display: block;
  height: 200%;
  width:100%;
  position: absolute;
}
.v-marquee-item p{
  font-weight: 800;
  font-size: 30px;
  padding:100px;
  font-family: cursive;
}
.v-marquee-inner.to-left{
  animation: v-marqueeTop 60s linear infinite;
}
.v-marquee-inner.to-right{
  animation: v-marqueeBottom 60s linear infinite;
}
.v-marquee-item{
  display: block;
  transition: all .1s ease-out;
  margin:20px 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 14px 0px;
  border-radius:10px;
  -moz-border-radius:10px;
  -webkit-border-radius:10px;
  overflow: hidden;
}
.v-marquee-item img{
    width: 100%;
}

@keyframes v-marqueeTop{
  0% {
    top: 0;
  }
  100% {
    top: -500%;
  }
}
@keyframes v-marqueeBottom{
  0% { 
    top: -500%; 
  }
  100% {
   top: 0; 
  }
}


/* v-marquee end */

.de-review-app{
    position: relative;
    border: solid 2px rgba(0, 0, 0, .1);
    padding: 25px;
    border-radius:20px;
    -moz-border-radius:20px;
    -webkit-border-radius:20px;
}

.de-review-app .d-logo img{
    max-height: 25px;
    margin-bottom: 25px;
}

.de-review-app .d-stars{
    position: absolute;
    right: 25px;
    bottom: 35px;
}
.de-review-app .d-stars img{
    max-height: 20px;
}

.de-review-app .d-testi{
    margin-bottom: 80px;
}

.de-review-app .d-testi-by{
    font-size: 14px;
    position: absolute;
    bottom: 25px;
    color: var(--title-font-color);
    font-weight: bold;
    line-height: 1.6em;
}

.de-review-app .d-testi-by span{
    display: block;
    font-weight: normal;
    font-size: 12px;
    color: var(--body-font-color);
}



/* ================================================== */


/* misc */


/* ================================================== */



body::-webkit-scrollbar,#de_modal::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-track,#de_modal::-webkit-scrollbar-track {
  background:#dddddd;
}
body::-webkit-scrollbar-thumb,#de_modal::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

body.dark-scheme::-webkit-scrollbar-track,#de_modal::-webkit-scrollbar-track {
  background:#101224;
}


.title-line {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
}

.title-line:before,
.title-line:after {
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, .2);
}

.title-line:before{
  margin: 0 20px 0 0;
}

.title-line:after{
  margin: 0 0 0 20px;
}

.dark-scheme .title-line:before,
.dark-scheme .title-line:after {
    background: rgba(255, 255, 255, .2);
}


.de-gradient-edge-top{
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.de-gradient-edge-bottom{
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.dark-scheme .de-gradient-edge-bottom{
    background: linear-gradient(0deg, rgba(23,26,29,1) 0%, rgba(23,26,29,0) 100%);
}


.d_demo_img{
    position: relative;
    overflow: hidden;
    display: block;
    margin: 5px;
    margin-bottom: 20px;
    -webkit-box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, .1);
    -moz-box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, .1);
    box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, .1);
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

.d_demo_img .d-overlay{
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.d_demo_img .d-overlay span{
    color: #ffffff;
    background: var(--primary-color);
    padding: 5px 15px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    font-weight: bold;
    transform: scale(1.5);
    opacity: 0;
}

.d_demo_img:hover img{
    transform: scale(1.1);
}

.d_demo_img:hover .d-overlay{
    opacity: 1;
}

.d_demo_img:hover .d-overlay span{
    transform: scale(1.0);
    opacity: 1;
}

/* box-url */

.box-url{
    position: relative;
    padding: 10px;
    background: #ffffff;
    display: block;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}
.dark-scheme .box-url{
    background: rgba(255, 255, 255, .05);
}
.box-url img{
    margin-bottom: 10px;
    width: 100%;
}
.box-url p{
    font-size: 14px;
    margin-bottom: 0;
    padding: 0;
}
.dark-scheme .box-url p{
    color: var(--dark-body-font-color);
    margin-bottom: 0;
}
.box-url:hover{
    background: rgba(0,0,0,0.05);
}
.box-url-label{
    font-weight: bold;
    position: absolute;
    right: 30px;
    color: #35404e;
    padding: 2px 10px;
    background: rgba(0,0,0,0.1);
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

.menu-simple{
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.menu-simple li{
    display: inline;
    margin: 0 15px;
}

.de-icon{
    width: 80px;
    text-align: center;
    font-size: 40px;
    background: rgba(85, 85, 85, .2);
    padding: 20px 0;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
}

.dark-scheme .box-url-label{
    color: #ffffff;
    background: rgba(255,255,255,0.1);
}
.box-url.style-2{
    text-align: center;
}
.box-url.style-2 h4{
    font-size: 16px;
    margin-bottom: 0;
}
.box-url h3{
    font-size: 26px;
}
.box-url .tld-domain{
    color: var(--title-font-color);
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
}
.dark-scheme .box-url .tld-domain{
    color: #ffffff;
}
.box-url p.tld-subtitle{
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1em;
    font-size: 10px;
}
.box-url p.tld-price{
    font-size: 14px;
    font-weight: bold;
}
.soft-shadow{
    box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), 0 1.5rem 2.2rem rgb(0 0 0 / 10%);
}

.rtl .owl-carousel,
.rtl .bx-wrapper{ direction: ltr; }
.rtl .owl-carousel .owl-item { direction: rtl; }

#item-carousel-big{
    padding: 0 25px;
}

.de-flex {
    display: flex;
    justify-content: space-between;
}

.de-flex>.de-flex-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

#loadmore{
    display: inline-block;
    margin-top:10px;
}

.d-item{
    display: none;
}

.v-center {
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.force-text-center *{
    text-align: center !important;
}

.f-logo {
    margin-right: 30px;
}

.rtl .f-logo {
    margin-right: 0px;
    margin-left: 30px;
}

.text-light .card {
    color: #ffffff !important;
    background: rgba(255, 255, 255, .05);
}


.filter__l,.filter__r{
    font-family: var(--title-font);
}
.filter__l{
    font-weight: bold;
}
.filter__r{
    float: right;
    cursor: pointer;
}

/*Cookie Consent Begin*/
#cookieConsent {
    background-color: rgba(255, 255, 255, 0.8);
    min-height: 26px;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    padding: 10px 30px 10px 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 9999;
}

#cookieConsent a {
    text-decoration: none;
}

#closeCookieConsent {
    float: right;
    display: inline-block;
    cursor: poInter;
    height: 20px;
    width: 20px;
    margin: -15px 0 0 0;
    font-weight: bold;
}

#closeCookieConsent:hover {
    color: #FFF;
}

#cookieConsent a.cookieConsentOK {
    color: #fff;
    display: inline-block;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    padding: 0 20px;
    cursor: poInter;
}

#cookieConsent a.cookieConsentOK:hover {
    opacity: .8;
}

/*Cookie Consent End*/

.vertical-center {
    min-height: 100%;
    /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh;
    /* These two lines are counted as one :-)       */

    display: flex;
    align-items: center;
}

#purchase-now {
    position: fixed;
    bottom: 90px;
    left: 30px;
    z-index: 10000;
}

#purchase-now a {
    color: #ffffff;
    z-index: 1;
    position: absolute;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    background: #78A938;
    display: block;
    font-size: 24px;
    font-weight: bold;
    width: 60px;
    height: 60px;
    padding: 17px;
    padding-left: 19px;    
    line-height: 1em;
    text-decoration: none;
    animation: shadow-pulse 1s infinite;
    /* remove */
    font-size: 24px;
    padding-left:20px;
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(120, 169, 56, 0.5);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(120, 169, 56, 0);
    }
}

#purchase-now a span {
    font-size: 14px;
    font-weight: 500;
    left: 8px;
    position: absolute;
    top: 18px;
    width: 100px;
}

#purchase-now .pn-hover {
    position: absolute;
    font-weight: bold;
    font-size: 16px;
    background: #ffffff;
    color: #78A938;
    width: 120px;
    padding: 10px;
    padding-left: 20px;
    text-align: center;
    top: 8px;
    border-radius: 0px;
    left: 30px;
    opacity: 0;
    -webkit-box-shadow: 0 30px 60px rgb(0 19 87 / 25%);
    -moz-box-shadow: 0 30px 60px rgb(0 19 87 / 25%);
    box-shadow: 0 30px 60px rgb(0 19 87 / 25%);
    border-radius:10px;
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
}

#purchase-now:hover .pn-hover {
    opacity: 1;
    left: 40px;
}

.de-images {
    position: relative;
    width: 100%;
}

.de-images img {
    border-radius: 3px;
    -webkit-box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
    -moz-box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
    box-shadow: 2px 20px 30px 0px rgba(20, 20, 20, 0.3);
}

.di-small {
    position: absolute;
    width: 40%;
    z-index: 1;


}

.di-small-2 {
    position: absolute;
    width: 40%;
    right: -0;
    bottom: -15%;
    z-index: 1;
}

.di-big {
    width: 80%;
    height: 80%;
    margin-top: 15%;
    margin-left: 10%;
}

div.round, .rounded {
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
}

.opt-1 {
    display: inline-block;
}

.opt-2 {
    display: none;
}

.size96 {
    width: 96px;
    height: auto;
}

.domain-ext {
    margin-top: 15px;
}

.ext {
    font-weight: 600;
    display: inline-block;
    font-size: 12px;
    border-radius: 3px;
    margin: 0 5px 10px 5px;
    padding: 10px;
    padding-bottom: 3px;
    color: var(--title-font-color);
}

.dark-scheme .ext{
    color: rgba(255, 255, 255, .75);
}

.domain-ext.pos-left .ext{
    margin: 0 10px 0 0;
}


.ext h4 {
    font-size: 18px;
    line-height: 10px;
    margin-bottom: 0;
}

.border {
    border-color: #EFEFEF !important;
}

.tparrows {
    background: none !important;
}


.fc-header-toolbar {
    font-size: 14px;
}

.fc-content {
    color: #ffffff;
    padding: 3px;
}

.fc-widget-header {}

.fc-view {
    background: #ffffff;
}

.fc-day-number {
    padding: 4px 8px !important;
}

.f-profile {
    margin-bottom: 0px;
}

.f-profile h4 {
    margin-top: 20px;
    margin-bottom: 0px;
}

.fp-wrap {
    position: relative;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    overflow: hidden;
}

.fpw-overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.fpw-overlay-btm {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: .6;
}

.f-invert .fpw-overlay-btm {
    opacity: 0;
}

.fpwo-wrap {
    width: 100%;
    position: absolute;
    bottom: 30px;
    text-align: center;
}

.fpwow-icons {
    display: inline-block;
    background: #ffffff;
    padding: 5px 10px 7px 10px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.fpwow-icons a {
    color: #ffffff;
    padding: 0 5px;
}

.fpwow-icons i {
    font-size: 15px;
}

.fp-icon {
    margin-top: 20%;
    z-index: 2;
    opacity: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    position: absolute;
    display: table;
    text-align: center;
}

.fp-icon i {
    display: none;
    vertical-align: middle;
    display: table-cell;
    color: #fff;
    font-size: 36px;
    color: #ffffff;
    padding: 13px 0;
    background: rgba(var(--primary-color-rgb), .8);
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.gray img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.demo-icon-wrap {
    margin-bottom: 30px;
}

.demo-icon-wrap i {
    text-align: center;
    font-size: 20px;
    margin-right: 20px;
    position: relative;
    background: rgba(var(--secondary-color-rgb), .1);
    width: 48px;
    height: 48px;
    border-radius: 0px;
    padding: 15px 0;
}

.rtl .demo-icon-wrap i {
    margin-right: 0;
    margin-left: 20px;
}

.demo-icon-wrap-s2 {
    margin-bottom: 30px;
}

.demo-icon-wrap-s2 span {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    margin-right: 20px;
    position: relative;
    background: rgba(var(--secondary-color-rgb), .1);
    width: 48px;
    height: 48px;
    border-radius: 0px;
    padding: 15px 0;
}

.rtl .demo-icon-wrap-s2 span {
    margin-right: 0px;
    margin-left: 20px;
}

.demo-icon-wrap .text-grey {
    display: none;
}

.fp-wrap:hover {
    -webkit-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
    -moz-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
    box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.1);
}

.fp-wrap:hover img {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);

    transform: scale(1.05);
}

.fp-wrap:hover .fpw-overlay-btm {
    opacity: 0;
}

.fp-wrap:hover .fpwow-icons a i {
    color: #fff;
}

.fp-wrap:hover .fp-icon {
    opacity: 1;
    margin-top: 0;
    margin-right: 0;
}

.fp-wrap:hover .fp-icon i {
    transform: scale(1.5);
}

i.big {
    display: inline-block;
    font-size: 48px;
}

.text-light .border {
    border-color: rgba(255, 255, 255, .1) !important;
}

.container-full {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
}

.one-fourth {
    width: 24.9%;
    display: table-cell;
    vertical-align: middle;
}

.one-third {
    width: 33.3%;
    float: left;
}

.three-fourth {
    position: relative;
    width: 74.9%;
    height: 100%;
    display: table-cell;
}

.owl-carousel {
    position: relative;
    margin-top: -5px;
    bottom: -5px;
    margin-bottom: -15px;
}

.owl-stage-outer .feature-box-type-2 {
    overflow: none;
    margin: 30px 0 30px 0;
}


.owl-item.active.center .feature-box-type-2,
.owl-item.active.center .feature-box-type-2 h4,
.owl-item.active.center .feature-box-type-2 i {
    color: #ffffff;
}

.owl-thumbs img{
    padding:3px;
}

#slider-carousel .owl-thumbs{
    padding:3px;
    margin-bottom:30px;
    width: auto;
}

#slider-carousel .owl-thumbs  button{
    opacity:1;
    border: none !important;
    padding: 0;
    margin-right: 15px;
    margin-bottom: 20px;
    opacity: 1;
    overflow: hidden;
    opacity: .5;
}
#slider-carousel .owl-thumb-item.active{
    opacity:1;
}
#slider-carousel button.owl-thumb-item.active{
    -webkit-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.5);
    box-shadow: 5px 5px 20px 0px rgba(0,0,0,0.5);
}
#slider-carousel .owl-thumb-item img{   
    background:#fff;
    padding:0;
    width:120px;
}

.schedule-item {
    padding: 40px 0 30px 0;
    border-bottom: solid 1px #ddd;
}

.schedule-listing:nth-child(even) {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(229, 229, 229, 1) 50%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(229, 229, 229, 1) 50%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(229, 229, 229, 1) 50%, rgba(255, 255, 255, 0) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
}

.schedule-item>div {
    float: left;
}

.schedule-item .sc-time {
    width: 160px;
    padding-top: 25px;
    color: #333;
}

.schedule-item .sc-pic {
    width: 120px;
}

.schedule-item .sc-pic img {
    width: 80px;
    height: auto;
}

.schedule-item .sc-name {
    font-weight: normal;
    font-size: 14px;
    width: 160px;
    padding-top: 15px;
}

.schedule-item .sc-name h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    line-height: 1.6em;
}

.schedule-item .sc-name span {
    font-weight: normal;
    font-size: 12px;
}

.schedule-item .sc-info {
    float: none;
    display: inline-block;
    width: 60%;
}

.schedule-item .sc-info h4 {
    margin-bottom: 10px;
}

.de_tab.tab_style_4 .de_nav {
    -webkit-touch-1A1C26out: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

strong {
    font-weight: bold;
}

.text-light strong {
    color: #fff;
}

.bg-white {
    background: #ffffff;
}

.bg-white-10 {
    background: rgba(255, 255, 255, .1);
}

.wh80 {
    width: 80%;
    height: 80%;
}

.border-top {
    border-top: solid 1px #e5e5e5;
}

.border-bottom {
    border-bottom: solid 1px #e5e5e5;
}

.dark-scheme header.border-bottom{
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
}

.color-overlay {
    background: rgba(157, 130, 235, .3);
}

section .color-overlay {
    padding: 60px 0 60px 0;
}

.color-overlay-2 {
    background: rgba(26, 37, 48, .85);
}

.fontsize24 {
    font-size: 24px;
}

.fontsize32 {
    font-size: 32px;
}

.fontsize36 {
    font-size: 36px;
}

.fontsize40 {
    font-size: 40px;
}

.fontsize48 {
    font-size: 48px;
}

.overlay50 {
    background: rgba(0, 0, 0, .5)
}

.overlay60 {
    background: rgba(0, 0, 0, .6)
}

.overlay70 {
    background: rgba(0, 0, 0, .7)
}

.overlay80 {
    background: rgba(0, 0, 0, .8)
}

.hidden-xy {
    overflow: hidden;
}

.border-box {
    border: solid 1px #d2d2d2;
}

.display-table {
    display: table;
}

.display-table [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
    margin: 0;
    padding: 0;
}

#popup-box {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: -100%;
    overflow-y: auto;
}

#popup-box.popup-show {
    top: 0;
}

#popup-box.popup-hide {
    top: -100%;
}

.overlay-s1 {
    background: rgba(217, 42, 224, .5);
}

.overlay-s2 {
    background: rgba(255, 135, 140, .5);
}

.overlay-s3 {
    background: rgba(91, 35, 108, .5);
}

.circle-x {
    background: #333;
    display: inline-block;
    padding: 20px;
    font-size: 40px;
    color: #fff;
    margin-top: 140px;
    width: 70px;
    height: 70px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.6);
}

#services-list {
    padding: 0;
    margin: 0;
}

#services-list li a {
    color: #fff;
}

#services-list li.active a:after {
    font-family: "FontAwesome";
    content: "\f054";
    float: right;
}

.de_light #services-list li a {
    color: #999;
}

#services-list li {
    font-weight: bold;
    list-style: none;
    margin: 0;
    background: rgba(0, 0, 0, .3);
    margin-bottom: 2px;
}

.de_light #services-list li {
    background: #eee;
}

#services-list li a {
    display: block;
    padding: 15px 20px 15px 20px;
    text-decoration: none;
}

#services-list li a:hover {
    color: #111;
}

.pic-services img {
    margin-bottom: 30px;
}

#services-list li.active a,
#services-list li.active a:hover {
    color: #fff;
    background: #333;
}

.project-images img {
    margin-bottom: 60px;
}

.teaser-text {
    font-family: var(--body-font);
    font-size: 18px;
    color: rgba(255, 255, 255, .5);
    line-height: normal;
    font-weight: 400;
}

.teaser-text h2 {}

#navigation {
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    right: 20px;
    text-align: center;
    margin-bottom: 60px;
    z-index: 1000;
}

.nav-prev:before,
.nav-next:before,
.nav-exit:before {
    font-family: "FontAwesome";
    content: '\f177';
    color: #fff;
    padding: 20px;
    font-size: 24px;
    cursor: poInter;
}

.nav-exit:before {
    width: 100%;
    display: block;
}

.nav-next:before {
    content: '\f178';
    width: 100%;
    background: #555;
}

.nav-exit:before {
    content: '\f00d';
}

.de_light .nav-exit:before {
    color: #222;
}

.container-4 .de-team-list {
    width: 24.9%;
    float: left;
    position: relative;
}

.container-3 .de-team-list {
    width: 33.3%;
    float: left;
    position: relative;
}

.no-bottom {
    margin-bottom: 0;
    padding-bottom: 0;
}

p {
    margin-bottom: 20px;
}

p.intro {
    color: #555;
    font-size: 16px;
    line-height: 1.5em;
}

.bg-grey {
    background: #f6f6f6;
}

.no-bottom .col-md-12 {}

.no-bottom {
    padding-bottom: 0 !important;
}

.no-top {
    padding-top: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.nopadding {
    padding: 0;
    margin: 0;
}

#filters i {
    margin-right: 10px;
}

.bg-side {
    position: relative;
    overflow: hidden;
}

.image-container {
    position: absolute;
    padding: 0;
    margin: 0;
    background-size: cover;
}

.image-container.pos-right {
    right: 0;
}

.image-container:hover .background-image{
    transform: scale(1.05);
}

.rtl .image-container.pos-right {
    right: auto;
    left: 0;
}

.image-slider {
    cursor: move;
}

.inner-padding {
    padding: 90px;
}

.inner-padding.pos-left {
    padding: 90px;
    padding-left: 0;
}

.rtl .inner-padding.pos-left {
    padding-left: 90px;
    padding-right: 0;
}

.no-padding,
section.no-padding {
    padding: 0;
}

.float-left {
    float: left;
}

#loader-area {
    display: none;
    background: #fff;
}

#loader-area {
    display: none;
    background: #111;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
}

.page-overlay {
    display: none;
    width: 120px;
    height: 120px;
    border-radius: 0px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    margin: auto;
}

.teaser {
    font-size: 16px;
    line-height: 1.8em;
    font-weight: 400;
}

.teaser.style-2 {
    letter-spacing: 20px;
    text-transform: uppercase;
}

.teaser.style-3 {
    font-size: 14px;
}

.teaser.style-4 {
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 14px;
}

.form-control:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.d-carousel{
    position: relative;
    display: flex;
    align-items: center;
}

.d-custom-nav{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100px;
    z-index: 100;
}

.d-arrow-left, .d-arrow-right{
    display: none;
    cursor: pointer;
    position: absolute;
    margin-top: -10px;
    z-index: 100;
    width: 30px;
    height: 60px;
    background-color: rgba(255, 255, 255, 1.0);
}

.d-arrow-left{
    left: 25px;  
    border-top-right-radius: 110px;
    border-bottom-right-radius: 110px;
}

.d-arrow-right{
    right: 25px;
    border-top-left-radius: 110px;
    border-bottom-left-radius: 110px;
}

.d-arrow-left i, .d-arrow-right i{
    position: absolute;
    top: 20px;
    color: #111111;
    font-size: 20px;
    font-weight: bold;
}
.d-arrow-left i{
    left: 8px;
}

.d-arrow-right i{
    right: 8px;
}

#d-coll-carousel{
    position: relative;
    display: flex;
    align-items: center;
}

.owl-nav{
    display: flex;
}

.owl-prev,.owl-next,
.d-nav-left, .d-nav-right{
    cursor: pointer;
    position: absolute;
    top: 27.5%;
    z-index: 100;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1.0);
    border: solid 1px #ccc;
}

.dark-scheme .owl-prev, .dark-scheme .owl-next{
background: var(--bg-color-even);
color: #ffffff;
border-color: rgba(255, 255, 255, .1);
}

.owl-prev:hover,.owl-next:hover{
    transform: scale(1.1);
    -webkit-box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.2);
}

.owl-prev,
.d-nav-left{
    left: -12px;
    border-radius: 60px;
    padding-left: 4px;
}

.owl-next,
.d-nav-right{
    right: -12px;
    border-radius: 60px;
    padding-right: 4px;
}

.d-nav-left i, .d-nav-right i{
    position: absolute;
    top: 20px;
    color: #111111;
    font-size: 20px;
    font-weight: bold;
}
.owl-next i,
.d-nav-left i{
    top: 12px;
    left: 16px;
}

.d-nav-right i{
    top: 12px;
    right: 16px;
}

.owl-arrow {
    z-index: 100;
    background: #fff;
    margin-top: 48%;
    display: none;
    cursor: poInter;
}

.owl-arrow span {
    display: block;
    background: #555;
    position: absolute;
}

.owl-arrow .prev {
    left: 0;
}

.owl-arrow .next {
    right: 0;
}

.owl-arrow .prev:before,
.owl-arrow .next:before {
    font-family: "FontAwesome";
    content: '\f177';
    color: #222222;
    padding: 20px;
    font-size: 32px;
    display: block;
}

.owl-arrow .next:before {
    content: '\f178';
}

.owl-pagination {
    margin-top: 0px;
    height: 40px;
}

.owl-theme .owl-controls .owl-page span {
    display: block;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    background-color: #555;
    opacity: 1;
}

.owl-theme .owl-controls .owl-page.active span {
    background: none;
}

.owl-theme .owl-controls .owl-page.active span:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    position: relative;
    border: solid 2px #555;
    top: -2px;
    left: -2px;
}

/* new owl */

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 0px;
}

.owl-dots button {
    border: none;
    outline: none;
    padding: 0;
    width: 8px;
    height: 8px;
    margin: 0 5px;
    border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot span {
    display: none;
}

/* new owl close */

.text-light .owl-theme .owl-controls .owl-page span {
    background-color: #fff;
}

.text-light .owl-theme .owl-controls .owl-page.active span {
    background: none;
}

.text-light .owl-theme .owl-controls .owl-page.active span:before {
    border: solid 2px #fff;
}

.text-slider {
    color: #fff;
    display: inline-block;
}

.text-slider.med-text {
    font-size: 72px;
    line-height: 84px;
    font-weight: bold;
}

.text-slider.big-text {
    font-size: 120px;
    line-height: 120px;
    font-weight: bold;
}

.border-deco .text-item {
    display: inline-block;
}

.text-slider.dark {
    color: #222;
}

.text-item i {
    font-size: 60px;
    margin-top: 5px;
}

.text-slider.big-text i {
    font-size: 90px;
    margin-top: 15px;
}

.text-slider h1 {
    font-size: 60px;
}

.text-rotate-wrap {
    display: inline-block;
}

.text-rotate-wrap .text-item {
    display: none;
}

.red {
    color: #ff0000;
}

.teal {
    color: #009999;
}

.purple {
    color: #663366;
}

.green {
    color: #009900;
}

.bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.center-y {
    position: relative;
}

.overlay-bg {
    padding: 120px 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(var(--primary-color-rgb), .9);
}

.overlay-bg.p60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.overlay-gradient {
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
}

.overlay-solid {
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
}

.overlay-bg.t0 {
    background: rgba(var(--primary-color-rgb), 0);
}

.overlay-bg.t20 {
    background: rgba(var(--primary-color-rgb), .2);
}

.overlay-bg.t30 {
    background: rgba(var(--primary-color-rgb), .3);
}

.overlay-bg.t40 {
    background: rgba(var(--primary-color-rgb), .4);
}

.overlay-bg.t50 {
    background: rgba(var(--primary-color-rgb), .5);
}

.overlay-bg.t60 {
    background: rgba(var(--primary-color-rgb), .6);
}

.overlay-bg.t70 {
    background: rgba(var(--primary-color-rgb), .7);
}

.overlay-bg.t80 {
    background: rgba(var(--primary-color-rgb), .8);
}

.overlay-bg.t90 {
    background: rgba(var(--primary-color-rgb), .9);
}


.overlay-bg.light-5{background: rgba(255,255,255,.5);}
.overlay-bg.light-6{background: rgba(255,255,255,.6);}
.overlay-bg.light-7{background: rgba(255,255,255,.7);}
.overlay-bg.light-8{background: rgba(255,255,255,.8);}
.overlay-bg.light-4{background: rgba(254,254,254,.9);}

.overlay-bg.c1 {
    background: rgba(var(--primary-color-rgb), .8);
}

.overlay-bg.dark {
    background: rgba(20, 20, 20, .8);
}

.overlay-bg.gradient-blue-aqua {
    background: -moz-linear-gradient(45deg, rgba(2, 68, 129, 0.9) 0%, rgba(52, 176, 188, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(2, 68, 129, 0.9) 0%, rgba(52, 176, 188, 1) 100%);
    background: linear-gradient(45deg, rgba(2, 68, 129, 0.9) 0%, rgba(52, 176, 188, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc024481', endColorstr='#cc34b0bc', GradientType=1);
}

.full-height .overlay-bg {
    display: table;
}

.full-height .overlay-gradient {
    display: table;
}

.full-height .center-y {
    display: table-cell;
    vertical-align: middle;
}

.pic-hover {
    width: 100%;
    position: relative;
    overflow: hidden;
}

i.btn-action-hide {
    opacity: 0;
}

.pic-hover:hover .bg-overlay {
    background: rgba(0, 0, 0, .5);
}

.pic-hover:hover i.btn-action-hide {
    opacity: 1;
}

.pic-hover.hover-scale:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.center-xy {
    position: absolute;
    z-index: 5;
}

.center-xy i.btn-action {
    font-size: 24px;
    border: solid 2px rgba(255, 255, 255, .3);
    padding: 16px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    text-align: center;
    color: #fff;
}

.center-xy i.btn-action:hover {
    border-color: rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, .3);
}

.center-xy i.btn-action.btn-play {
    padding-left: 20px;
}

.bg-grey,
section.bg-grey {
    background: rgba(var(--secondary-color-rgb), .1);
}

.wow {
    visibility: hidden;
}

#back-to-top {
    background: #ffffff;
    position: fixed;
    bottom: -40px;
    right: 10px;
    z-index: 1020;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 30px;
    cursor: poInter;
    padding-top: 3px;
    border: 0;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    outline: none;
    opacity: 0;
}

#back-to-top:hover {
    transform: scale(1.1);
}

#back-to-top.show {
    bottom: 20px;
    opacity: 1;
}

#back-to-top.hide {
    bottom: -40px;
}

#back-to-top:before {
    font-family: "FontAwesome";
    font-size: 18px;
    content: "\f106";
    color: #fff !important;
    position: relative;
}

/* mouse scroll icon begin */

.mouse {
    position: absolute;
    width: 22px;
    height: 42px;
    bottom: 40px;
    left: 50%;
    margin-left: -12px;
    border-radius: 15px;
    border: 2px solid #fff;
    -webkit-animation: intro 1s;
    animation: intro 1s;
}

.scroll {
    display: block;
    width: 3px;
    height: 3px;
    margin: 6px auto;
    border-radius: 4px;
    background: #fff;
    -webkit-animation: finger 1s infinite;
    animation: finger 1s infinite;
}

@-webkit-keyframes intro {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes intro {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes finger {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}

@keyframes finger {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}

/* mouse scroll icon close */

@-moz-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -moz-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-webkit-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

.arrow-anim {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    background-size: contain;
}

.arrow-anim:before {
    content: "\f107";
    font-family: "FontAwesome";
    font-size: 32px;
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

a.read_more {
    display: inline-block;
    font-weight: bold;
    color: #222222;
    text-decoration: none;
}

a.read_more:hover {
    color: #707070;
}

a.read_more i {
    margin-left: 8px;
}

.text-light a.read_more {
    color: #fff;
}

.text-black {
    color: #222;
}


#de-arrow-nav-set {
    text-align: center;
}

#de-arrow-nav-set i {
    color: #222;
    font-size: 32px;
}

#de-arrow-nav-set i.icon_menu {
    display: inline-block;
}

.profile_pic h4 {
    margin-bottom: 0px;
}

.profile_pic .subtitle {
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
    color: #999;
}

.profile_pic .tiny-border {
    margin-bottom: 20px;
}

.profile_pic span {
    color: #fff;
}

.owl-custom-nav {
    position: absolute;
    z-index: 100;
    right: 4px;
    margin-top: 80px;
    width: 100%;
}

.owl-custom-nav .btn-next,
.owl-custom-nav .btn-prev {
    opacity: 0;
}

.owl-custom-nav .btn-next {
    right: -50px;
    position: absolute;
}

.owl-custom-nav .btn-prev {
    left: -50px;
    position: absolute;
}

.pf-hover:hover .btn-next {
    right: 30px;
    opacity: 1;
}

.pf-hover:hover .btn-prev {
    left: 30px;
    opacity: 1;
}

.owl-custom-nav .btn-next:before,
.owl-custom-nav .btn-prev:before {
    font-size: 18px;
}

.owl-custom-nav .btn-next:before {
    font-family: "FontAwesome";
    content: "\f178";
    color: #fff;
    padding: 12px;
}

.owl-custom-nav .btn-prev:before {
    font-family: "FontAwesome";
    content: "\f177";
    color: #fff;
    padding: 12px;
}

.owl-custom-nav a {
    text-decoration: none;
}

.owl-custom-nav .btn-prev:hover:before,
.owl-custom-nav .btn-next:hover:before {
    cursor: poInter;
}

#custom-owl-slider {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    background: #fff;
}

#custom-owl-slider .items {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 20;
    visibility: inherit;
    opacity: 1;
}

#custom-owl-slider .item img {
    display: block;
    width: 100%;
    height: 100%;
}

.owl-slider-nav {
    position: absolute;
    width: 100%;
}

.owl-slider-nav,
.owl-slider-nav div {
    position: absolute;
    z-index: 10;
    cursor: poInter;
}

.owl-slider-nav .next {
    right: -50px;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-right: 50px solid transparent;
}

.owl-slider-nav .prev {
    left: -50px;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 50px solid transparent;
}

.owl-slider-nav .next:before {
    font-family: "FontAwesome";
    font-size: 24px;
    content: "\f0da";
    color: #fff;
    position: absolute;
    right: -40px;
    top: -13px;
}

.owl-slider-nav .prev:before {
    font-family: "FontAwesome";
    font-size: 24px;
    content: "\f0d9";
    color: #fff;
    position: absolute;
    left: -40px;
    top: -13px;
}

.owl-dots {
    text-align: center;
}

.owl-dot {
    background: #ccc;
    border: none;
    margin: 5px;
    width: 6px;
    height: 6px;
    padding: 0;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
}

.dark-scheme .owl-dot{
    background: rgba(255, 255, 255, .3);
}

.owl-item.active div blockquote {
    background: #F0F9FE;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
}

.owl-item.active div blockquote,
.owl-item.active div blockquote .de_testi_by {
    opacity: 1;
}

.owl-item.active div blockquote:before {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    border-radius: 0 0 15px 0;
}

.owl-stage {
    overflow: none;
}

/* close new table */

.table {
    display: table;
    margin-bottom: 0;
}

.table [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
    padding: 0;
    height: 100%;
}

.table-custom-1 {
    position: absolute;
    height: 100%;
}

.table .table {
    background: none;
}

.table-pricing {
    font-size: 16px;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-right: none;
    overflow: hidden;
    padding: 5px;
    background: #ddd;
}

.table-pricing thead {
    color: #ffffff;
    font-size: 18px;
}

.table-pricing thead tr th {
    font-weight: bold;
    padding: 20px 0 20px 0;
}

.table-pricing tbody tr {
    width: 100%;
    background: #ffffff;
}

.table-pricing tbody tr:nth-child(even) {
    width: 100%;
    background: #f5f5f5;
}

.table-pricing.dark tbody tr{
    background: #222222;
}

.table-pricing.dark tbody tr:nth-child(even) {
    width: 100%;
    background: #111111;
}

.table-pricing tbody tr th{
    font-weight: bold;
}

.table-pricing tbody tr td,
.table-pricing tbody tr th {
    border: none;
    padding: 20px 0 20px 0;
}

.table-pricing tbody tr th .lbl,
.table-pricing tbody tr td .lbl {
    display: none;
}

.table-pricing .num{
    font-weight: bold;
}

.table-pricing .btn-main,
.table-pricing .btn-line{
    display: inline-block;
    line-height: 1.1em;
}

.table-pricing>:not(caption)>*>*{
    border: none;
}

.typed-strings p {
    position: absolute;
}

.typed-cursor {
    opacity: 1;
    -webkit-animation: blink 0.5s infinite;
    -moz-animation: blink 0.5s infinite;
    animation: blink 0.5s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.mask {
    width: 100%;
    position: relative;
    overflow: hidden !important;
    display: inline-block;
}

.mask img {
    width: 100%;
    height: 100%;
}

.mask .cover {
    display: table;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 70px;
    padding: 25px;
    background: #35404e;
    bottom: 0px;
    top: 100%;
    margin-top: -70px;
}

.mask .cover .c-inner {
    display: table-cell;
    vertical-align: middle;
}

.mask.light .cover {
    background: #ffffff;
}

.mask .cover h3 {
    font-size: 18px;
    color: #fff;
    line-height: 1em;
    padding-left: 30px;
}

.mask.light .cover h3 {
    color: #35404e;
}

.mask .cover p {
    color: #ffffff;
    padding-top: 15px;
    padding-right: 25px;
}

.rtl .mask .cover p {
    padding-right: 0;
}

.mask.light .cover p {
    color: #777777;
}

.mask:hover .cover {
    height: 100%;
    top: 0;
    margin-top: -0px;
    background: rgba(35, 37, 42, .8);
}

.mask:hover .cover p {
    opacity: 1;
}

.mask:hover .cover p {
    padding-top: 0px;
}

.mask .cover h3 span {
    margin-left: 20px;
}

.rtl .mask .cover h3 span {
    margin-right: 50px;
}

.mask .cover h3 i {
    font-size: 32px;
    position: absolute;
    left: 30px;
    margin-top: -8px;
}

.rtl .mask .cover h3 i {
    left: auto;
    right: 30px;
}

.mask.s1 .cover {
    top: 0;
    padding: 30px;
    text-align: center;
    background: rgba(0, 0, 0, .0);
}

.mask.s1 .cover .desc {
    opacity: 0;
}

.mask.s1:hover .cover {
    background: rgba(0, 0, 0, .8);
}

.mask.s1:hover .cover .desc {
    opacity: 1;
}

.mask.s1 h3 {
    font-size: 24px;
}

.mask .cover a.btn-main {
    display: block;
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}

.mask .s-gradient img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.mask .s-gradient {
    position: relative;
}

.mask .s-gradient .gradient-fx {
    position: absolute;
    background: #333;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: -moz-linear-gradient(left, rgba(var(--primary-color-rgb), .75) 0%, rgba(var(--primary-color-rgb), .2) 100%);
    background: -webkit-linear-gradient(left, rgba(var(--primary-color-rgb), .75) 0%, rgba(var(--primary-color-rgb), .2) 100%);
    background: linear-gradient(to right, rgba(var(--primary-color-rgb), .75) 0%, rgba(var(--primary-color-rgb), .2) 100%);
}

.style-2 .date-box {
    text-align: center;
    position: absolute;
    right: 0;
    padding: 15px 5px 5px 5px;
    color: #fff;
}

.style-2 .date-box .d {
    font-weight: 600;
    font-size: 38px;
    margin-bottom: 5px;
}

.style-2 .date-box .m {
    font-family: var(--body-font);
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 8px;
    margin-left: 8px;
}

.sitemap {
    margin: 0;
    padding: 0;
}

.sitemap.s1 li {
    list-style: none;
}

.sitemap.s1 li:before {
    font-family: FontAwesome;
    content: "\f016";
    margin-right: 10px;
}

.list {
    margin: 0;
    padding: 0;
}

.list li {
    list-style: none;
}

.rtl .list.s1 li:after,
.list.s1 li:before {
    font-family: FontAwesome;
    content: "\f00c";
    margin-right: 10px;
}

.rtl .list.s1 li:after {
    margin-right: 0;
    margin-left: 10px;
}

.rtl .list.s1 li:before {
    display: none;
}

.list.s2 li:before {
    font-family: FontAwesome;
    content: "\f055";
    margin-right: 10px;
}

.list.s3 {
    display: inline;
    margin: 0;
    padding: 0;
}

.list.s3 li {
    display: inline;
    margin-right: 20px;
}

.list.s3 a {
    font-weight: bold;
}

#owl-logo img:hover {
    opacity: 1;
}

.switch-set {
    font-weight: 500;
    direction: ltr !important;
}

.dark-scheme .switch{
    background: rgba(255,255,255,.1);
}

.switch-set div {
    display: inline-block;
    padding: 0 5px;
    direction: ltr !important;
}

.switch {
    margin-bottom: -5px;
    -webkit-appearance: none;
    height: 22px;
    width: 42px;
    background-color: #F4F4F4;
    border-radius: 60px;
    position: relative;
    cursor: poInter;
    border: none;
}

.switch::after {
    content: "";
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    position: absolute;
    border-radius: 100%;
    transition: .5s;
    margin: 2px 0 0 2px;
}

.de-switch {
  display: block;
}
.de-switch h3 {
  font-weight: 400;
  padding-bottom: 6px;
}
.de-switch input[type=checkbox] {
  display: none;
}
.de-switch input[type=checkbox]:checked + label {
  background-color: #2f7df9;
}
.de-switch input[type=checkbox]:checked + label:after {
  left: 23px;
}
.de-switch label {
  transition: all 200ms ease-in-out;
  display: inline-block;
  position: relative;
  height: 20px;
  width: 40px;
  border-radius: 40px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .3);
  color: transparent;
}
.dark-scheme .de-switch label{
  background-color: rgba(255, 255, 255, .1);
}
.de-switch label:after {
  transition: all 200ms ease-in-out;
  content: " ";
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: white;
  top: 3px;
  left: 3px;
  right: auto;
}
.de-switch.colored input[type=checkbox]:checked + label {
  background-color: #55c946;
}
.de-switch.colored label {
  background-color: #ff4949;
}

.switch-with-title h5{
    float: left;
}

.switch-with-title .de-switch{
    float: right;
}

/*
 .switch:checked {
     background-color: blue;
}
*/
.switch:checked::after {
    transform: translateX(20px);
}

.switch:focus {
    outline-color: transparent;
}

.box-highlight,
.box-highlight .content {
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    padding: 1px;
}

.box-highlight .heading {
    padding:15px 20px;
}

.box-highlight h4{
    margin-bottom: 0;
}

.box-highlight .content {
    margin: 5px;
    padding: 20px;
    background: #ffffff;
}

.box-highlight .content,
.box-highlight .content strong {
    color: #111111;
}

.box-highlight .content.v1 p {
    font-size: 20px;
}

.box-cc {
    color: #1a1b1e;
    border: solid 1px rgba(0, 0, 0, .15);
    padding: 20px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    font-weight: 500;
    position: relative;
    background: #ffffff;
    -webkit-box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
    -moz-box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
    box-shadow: 0 30px 30px 0 rgba(10, 10, 10, 0.1);
}

.box-cc .bc-price span {
    display: inline-block;
    margin-left: 10px;
}

.box-cc img {
    margin-bottom: 10px;
    width: auto !important;
    height: auto !important;
}

.bc-btn {
    display: block;
    position: absolute;
    right: 20px;
    background: rgba(0, 0, 0, .05);
    padding: 0 8px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

#selector{
    width:500px;
    position:fixed;
    right:0;
    top:45%;
    padding:5px;
    z-index:1000;
}

#selector .opt{
    margin-top: 10px;
    margin-right: 5px;
    width:16px;
    height:16px;
    display:block;
    float: left;
    background:#ffffff;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    cursor:pointer;
    border:solid 1px rgba(255,255,255,.5);
}

#selector .opt:hover{
    border-color:#ffffff;
}

#selector .opt.tc1{background:#00927c;}
#selector .opt.tc2{background:#436fb6;}
#selector .opt.tc3{background:#71c055;}
#selector .opt.tc4{background:#fba51a;}
#selector .opt.tc5{background:#d1499b;}
#selector .opt.tc6{background:#8750a1;}
#selector .opt.tc7{background:#40b8ea;}


#selector #dark-mode,
#selector #related-items{
    font-weight:bold;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top:50px;
    right:15px;
    width:40px;
    height:39px;
    position:absolute;  
    background:#222222; 
    padding:8px;
    padding-top:0px;
    cursor:pointer;
    overflow:hidden;
    border-radius:60px;
    -moz-border-radius:60px;
    -webkit-border-radius:60px;
    color:#FDD32A;
    font-size:16px;
}
#selector #related-items{
    margin-top:100px;
    background:#82b440;
    color:#ffffff;
}
#selector #dark-mode:hover,
#selector #related-items:hover{
    width:190px;
}
#selector #dark-mode:before,
#selector #related-items:before{
    font-weight:normal;
    display:inline-block;
    content:'\f186';
    font-family:"FontAwesome";
    font-size:20px;
    padding:3px;
    padding-top:6px;
    margin-right:10px;
}
#selector #related-items:before{
    content:'\f05a';
}
#selector #dark-mode.active{
    background:#FDD32A;
    color:#222222;
}

.disable-dark #selector .dark-mode{
    display:none;
}

#selector .sc-opt {
    cursor: pointer;
    background:#ffffff;
    color:#35404e;
    font-size: 14px;
    height: 38px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    max-width:45px;
    padding-right: 20px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    right:0px;
    border: solid 1px rgba(0, 0, 0, .2);
    border-right: none;
    font-weight: 500;
}

#selector .sc-opt:hover{
    max-width: 100%;
}

#selector .sc-opt .sc-icon{
    width:45px;
    height:38px;
    color:#000000;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    padding:12px 10px;
    position: relative;
    float:left;
}

#selector .sc-mt{
    margin-top: 45px;
}

#selector .sc-val{
    display: inline-block;
    margin-top: 5px;
}

.de-box-image {
    padding: 10px;
    text-align: center;
}

.de-box-image img {
    margin-bottom: 20px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border: solid 1px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
    -moz-box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
    box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
}

.de-box-image:hover img {
    margin-top: -20px;
    -webkit-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
    -moz-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
    box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
}

.de-box-image:hover h4 {
    margin-top: 20px;
}


.de-image-hover {
    position: relative;
    overflow: hidden;
}

.dih-overlay {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #eee;
    opacity: 0;
}

.dih-title-wrap {
    z-index: 2;
    display: table;
    position: absolute;
    width: 100%;
    height: 100%;
}

.dih-title {
    opacity: 0;
    font-size: 16px;
    z-index: 2;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    color: #fff;
}

.de-image-hover:hover .dih-overlay {
    opacity: .8;
}

.de-image-hover:hover .dih-title {
    opacity: 1;
    transform: scale(1.2);
}

.de-image-hover:hover img {
    transform: scale(1.1);
}

.de-image-hover:hover {
    -webkit-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
}

.img-fullwidth {
    width: 100%;
    height: auto;
}

.author_list {
    -moz-column-count: 4;
    -moz-column-gap: 20px;
    -webkit-column-count: 4;
    -webkit-column-gap: 20px;
    column-count: 4;
    column-gap: 20px;
}

.author_list li {
    position: relative;
    margin-bottom: 30px;
}

.author_list_pp {
    margin-top: 2px;
    position: absolute;
    display: inline-block;
    width: 50px;
    height: auto;
    margin-left: 10px;
    z-index: 10;
}

.author_list_pp img {
    width: 100%;
    border-radius: 100% !important;
    -moz-border-radius: 100% !important;
    -webkit-border-radius: 100% !important;
    position: relative;
    z-index: 1;
    
}

.author_list_pp:hover img{
    padding: 3px;
}

.author_list_pp i {
    color: #ffffff;
    font-size: 10px;
    padding: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    z-index: 2;
}

.author_list_info {
    font-weight: bold;
    padding-left: 70px;
}

.author_list_info span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2em;
}

.author_list_info a {
    color: #1a1b1e;
}

.coll_list_pp {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: auto;
    margin-left: 10px;
    margin-top: -12px;
    z-index: 10;
    left: 0;
}

.coll_list_pp img {
    width: 100%;
    border-radius: 100% !important;
    -moz-border-radius: 100% !important;
    -webkit-border-radius: 100% !important;
    position: relative;
    z-index: 1;
    
}

.coll_list_pp i {
    color: #ffffff;
    font-size: 10px;
    padding: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    z-index: 2;
}

.de-table.table-rank th,
.de-table.table-rank tr{
    border-bottom: solid 1px rgba(0,0,0,.05) !important;
}
.dark-scheme .de-table.table-rank th,
.dark-scheme .de-table.table-rank tr{
    border-bottom: solid 1px rgba(255,255,255,.1) !important;
}
.de-table.table-rank th,
.de-table.table-rank td{
    padding: 30px 0;
}
.de-table.table-rank .d-plus{
    color: #34c77b;
}
.de-table.table-rank .d-min{
    color: #eb5757;
}
.de-table.table-rank tbody th{
    position: relative;
    padding-left: 75px;
}

.dark-scheme .de-table.table-rank{
    color: #ffffff;
}

.dark-scheme .author_list_info a{
    color: #ffffff;
}

.text-light .author_list_info a {
    color: #ffffff;
}

.p_list {
    margin-bottom: 30px;
}

.p_list_pp {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: auto;
    margin-left: 0px;
    margin-top: -3px;
    z-index: 10;
}

.p_list_pp img {
    width: 100%;
    border-radius: 100% !important;
    -moz-border-radius: 100% !important;
    -webkit-border-radius: 100% !important;
}

.p_list_pp i {
    color: #ffffff;
    font-size: 10px;
    padding: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
}

.p_list_info {
    font-weight: 400;
    padding-left: 70px;
}

.dark-scheme .p_list_info b{
    color: #ffffff;
}

.p_list_info span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2em;
}

.p_list_info a {
    color: #1a1b1e;
}

.text-light .p_list_info a {
    color: #ffffff;
}

.menu_side_area {
    margin-left: 20px;
}

.rtl .menu_side_area {
    margin-left: 0;
}

.de_countdown {
    text-align: right;
    background: #ffffff;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    z-index: 100;
    padding: 0 0 10px 0;
}

.dark-scheme .de_countdown{
    color: #fff;
    background:none;
}

.countdown-section {
    font-size: 28px;
    margin-left: 8px;
    font-weight: bold;
}

.d_profile {
    margin-bottom: 40px;
}

.profile_avatar {
    display: flex;
}

.d_coll .profile_avatar{
    display: block;
    text-align: center;   
}

.profile_avatar img,
.profile_name {
    display: flex;
    align-items: center;
}

.d_coll .profile_avatar img,
.d_coll .profile_name{
    display: block;
    margin: 0 auto;
    text-align: center;
}

.profile_avatar img {
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    width: 150px;
    height: auto;
}

.d_coll .profile_avatar img{
    display: inline-block;
    margin-top: -100px;
    margin-bottom: 30px;
}

.profile_avatar i {
    color: #ffffff;
    margin: 110px 0 0 110px;
    display: block;
    position: absolute;
    padding: 10px;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
}

.profile_name {
    margin-left: 20px;
}

.profile_name h4 {
    font-size: 24px;
    line-height: 1.3em;
}

.profile_follower {
    margin-right: 20px;
    font-weight: 600;
}

.dark-scheme .profile_follower{
    color: #ffffff;
}

.profile_username,
.profile_wallet {
    font-size: 16px;
    font-weight: 500;
}

.profile_username {

    display: block;
}

.profile_wallet {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
    float: left;
    color: #404040;
}

.dark-scheme .profile_wallet{
    color: var(--dark-body-font-color);
}

.d_coll .profile_wallet{
    display: inline-block;
    float: none;
}

#btn_copy {
    position: absolute;
    font-size: 12px;
    padding: 4px 10px;
    line-height: 1em;
    border: solid 1px #d2d2d2;
    display: inline-block;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    outline: none;
}

.dark-scheme #btn_copy{
    color: #ffffff;
    background: rgba(255, 255, 255, .3);
    border: none;
    padding: 6px 12px;
}

#btn_copy.clicked {
    color: #ffffff;
}

.d_coll .d_profile_img{
    position: relative;
    margin: 0 auto;
}

.d_coll .d_profile_img i{
   color: #ffffff;
    margin:10px 0 0 -40px;
    float: none;
    display: inline-block;
   
}

.tabs_wrapper {
    margin-top: 40px;
}

.hide-content{
    display: none;
}

.display-none{
    display: none;
}

.ul-style-2 {
    padding: 0;
    list-style: none;
}

.ul-style-2 li {
    position: relative;
    display: block;
    margin: 10px 0;
    margin-top: 0;
    padding: 0px;
    padding-left: 40px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 1.6em;
}
.ul-style-2 li h4{
    font-size: 18px;
    font-weight: 500;
}

.ul-style-2.s2 li h4{
    font-weight: 600;
}

.ul-style-2.s2 li{
    margin-bottom: 30px;
}

.ul-style-2.s3 li{
    margin-bottom: 5px
}

.ul-style-2.s4 li{
    color:var(--title-font-color);
    font-weight: 600;
    margin-bottom: 5px
}

.ul-style-2 .d-label{
    background: #fdda76;
    font-weight: bold;
    padding: 0 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

/*
.ul-style-2 li:before {
    font-family: "FontAwesome";
    content: "\f00c";
    margin-right: 12px;
    width: 20px;
    line-height: 1;
    padding: 4px 0;
    font-size: 12px;
    display: inline-block;
    text-align: center;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    background: #35404e;
    color: #ffffff;
}*/


.ul-style-2 li:before {
    position: absolute;
    left: 0;
    font-family: "FontAwesome";
    content: "\f00c";
    margin-right: 12px;
    width: 20px;
    line-height: 1;
    padding: 4px 0;
    font-size: 15px;
    display: inline-block;
    text-align: center;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.rtl .ul-style-2 li:before{
    margin-right: 0;
    margin-left: 12px;
}

.ul-style-3 {
    margin-top: 15px;
    padding: 0;
    list-style: none;
}

.ul-style-3 li {
    display: inline-block;
    margin: 0;
    padding: 0px;
}

.ul-style-3 li:after {
    content: "•";
    margin: 0 5px 0 10px;
}

.ul-style-3 li:last-child:after {
    content: "";
}

.de-review-list{
    list-style: none;
    margin:  0;
    padding: 0;
}

.de-review-list li{
    padding: 30px;
    margin-bottom: 20px;
    border: solid 1px #ddd;
    position: relative;
    overflow: hidden;
}

.de-review-list p{
    margin-bottom: 0px;
}

.de-review-list .d-user{
    font-weight: 500;
}

.de-review-list h5:after{
    font-family: FontAwesome;
    content: "\f10e";
    font-size: 24px;
    position: absolute;
    top: 30px;
    right: 30px;
    color: rgba(0, 0, 0, .5);
    line-height: 1;
}

.rtl .de-review-list h5:after{
    right: auto;
    left: 30px;
}

#carouselExampleIndicators .carousel-indicators{
    margin: 0;
}

.de-country{
    border: solid 1px #dddddd;
    margin-bottom: 25px;
    overflow: hidden;
}

.dark-scheme .de-country{
    border: var(--border-default);
}

.de-country .d-title{
    padding: 20px;
    padding-bottom: 15px;
    cursor: pointer;
}

.de-country .d-title:after{
    font-family: FontAwesome;
    content: "\f078";
    margin-top: 3px;
    float: right;
}

.rtl .de-country .d-title:after{
    float: left;
}

.de-country.expand .d-title:after{
    content: "\f077";
}

.de-country .d-title h4{
    display: inline-block;
    margin-top: 5px;
    margin-left: 10px;
    font-size: 18px;
}

.de-country img{
    width: 32px;
    height:  auto;
    float: left;
}

.rtl .de-country img{
    float: right;
    margin-left: 10px;
}

.de-country .d-content{
    padding: 0px 20px;
    padding-top: 0;
    max-height: 0;
}

.de-country .d-content ul{
    list-style: none;
    padding: 0;
}

.de-country .d-content a{
    color:#202020;
}

.dark-scheme .de-country .d-content a{
    color:#ffffff;
}

.de-country.expand .d-content{
    padding: 20px;
    padding-top: 0;
    max-height: 1000px;
}

.de-country .d-content h5{
    font-size: 16px;
}

.de-country:hover{
    position: relative;
    margin-top: -5px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 4px 6px 0 rgba(10,10,10, .2);
    -moz-box-shadow: 0 4px 6px 0 rgba(10,10,10, .2);
    box-shadow: 0 4px 6px 0 rgba(10,10,10, .2);
}

/* shadow */

.shadow-soft,
header.smaller,
.de-country,
.de-card,
.de-image-text,
#quick_form,
.de-event-item,
.de-event-item .d-date,
.de-map-wrapper iframe,
.de-box,
.de-review-list li,
.expand-custom,
.sc-opt,
.feature-box-type-2,
.pricing-table:not(.pricing-s1),
.de_count,
#form_sb input,
.container-timeline ul li::before,
.box-url,
.table-pricing,
.pricing-s1,
.pricing-s2
{
    -webkit-box-shadow: 0 30px 60px rgb(0 19 87 / 6%);
    -moz-box-shadow: 0 30px 60px rgb(0 19 87 / 6%);
    box-shadow: 0 30px 60px rgb(0 19 87 / 6%);
}

.no-shadow{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.rounded-5{
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    position: relative;
    overflow: hidden;
}

/* border radius */
.pricing-table,
.de_count{
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
}

.de-country:hover,
.de-card:hover,
.de-image-text:hover,
.dropdown ul{
    -webkit-box-shadow: 0 4px 6px 0 rgba(10,10,10, .2);
    -moz-box-shadow: 0 4px 6px 0 rgba(10,10,10, .2);
    box-shadow: 0 4px 6px 0 rgba(10,10,10, .2);
}

.rtl .carousel-control-next{
    right: 0;
}

.rtl .carousel-control-prev{
    left: 0;
    right: auto;
}

.carousel-control-next-icon:after, 
.carousel-control-prev-icon:after{
    font-family: FontAwesome;
    content: "\f104";
    font-size: 32px;
}

.carousel-control-next-icon:after{
    content: "\f105";
}

.de-event-item{
    position: relative;
    margin-bottom: 20px;
}
.de-event-item .d-image{
    position: relative;
}
.de-event-item .d-image-wrap{
    position: relative;
    overflow: hidden;
    display: block;
    z-index: 2;
}
.de-event-item .d-image img{
    width: 100%;
    position: relative;
}
.de-event-item .d-date{
    position: absolute;
    z-index: 10;
    top: -10px;
    left: 20px;
    color: #ffffff;
    font-weight: bold;
    padding: 15px;
}

.rtl .de-event-item .d-date{
    left: auto;
    right: 20px;
}

.de-event-item .d-date span{
    display: block;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
}
.de-event-item .d-date .d-mm{
    margin-bottom: 2px;
}
.de-event-item .d-date .d-dd{
    font-size: 40px;
    margin-bottom: 4px;
}
.de-event-item .d-shadow{
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 0 20px;
    border-color: transparent transparent transparent #007bff;
    left: 98px;
    top: -10px;
}

.rtl .de-event-item .d-shadow{
    left: auto;
    right: 2px;
}

.de-event-item .d-text{
    padding: 30px;
    border: solid 1px #d2d2d2;
}

.de-event-item:hover .d-date{
    top: -20px;
    left:  10px;
}
.de-event-item:hover .d-shadow{
     top: -20px;
     left: 88px;
}

.rtl .de-event-item:hover .d-date{
    top: -20px;
    left:  auto;
    right:  20px;
}
.rtl .de-event-item:hover .d-shadow{
     top: -20px;
     left: auto;
     right: 0;
}
.de-event-item:hover img{
    transform: scale(1.1);
}
.de-event-item .d-location{
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 3;
    background: #ffffff;
    padding: 0 10px;
    font-weight: bold;
    border-radius: 30px;
}

.rtl .de-event-item .d-location{
    left: auto;
    right: 20px;
}

.de-map-wrapper iframe{
    width: 100%;
    height: 450px;
    border: solid 1px #cccccc;
}

.de-location-address{
    font-weight: 400;
}

.de-box{
    padding: 30px;
    border: solid 1px #d2d2d2;
}


/* image effect */


img.anim-up-down {
    -webkit-animation: move-up-down 1s infinite  alternate;
    animation: move-up-down 1s infinite  alternate;
}
img.anim-up-down {
    -webkit-animation: move-up-down 1s infinite  alternate;
    animation: move-up-down 1s infinite  alternate;
}
@-webkit-keyframes move-up-down {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes move-up-down {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

.slider-text-wrap{
    background:rgba(255, 255, 255, .75);
    padding: 30px;
    padding-top: 20px;
    overflow: hidden;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

/* ================================================== */


/* quick-search */


/* ================================================== */

#quick_search {
    margin-left: 30px;
    padding: 4px 11px;
    border: none;
    border-radius: 0px;
    font-size: 15px;
    background: rgba(255, 255, 255, .1);
    width: 120%;
    font-family: var(--title-font);
    outline: none;
}

#quick_search.style-2{
    background: rgba(0, 0, 0, .05) !important;
}

.dark-scheme #quick_search.style-2{
    background: rgba(255, 255, 255, .05) !important;
}

header:not(.header-light) #quick_search {
    color: #ffffff;
}

header:not(.header-light) #quick_search::-moz-input-placeholder {
    color: #ffffff;
}

header:not(.header-light) #quick_search::-webkit-input-placeholder {
    color: #ffffff;
}

.header-light #quick_search {
    background: rgba(var(--secondary-color-rgb), .1);
}

.quick-search {
    display: inline-block;
    float: right;
    margin-left: 10px;
    padding: 10px 0 0 0;
}

.quick-search input {
    width: 120px;
    border-radius: 40px;
}

.side-bg {
    position: relative;
}

.image-container {
    height: 100%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;

}

.side-bg .background-image {
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.side-bg .background-image i{
    font-size: 84px;
    background: rgba(0, 0, 0, .3);
    width: 135px;
    padding: 25px 0;
    text-align: center;
    border-radius: 100%;
}

.side-bg.right .image-container {
    right: 0;
}

#search {
    float: left;
    width: 70%;
    margin-bottom: 0;
}

#btn-search {
    border: none;
    padding: 7px;
    background: #222;
    float: left;
    width: 30%;
    height: 42px;
}

#btn-search:before {
    font-family: FontAwesome;
    content: "\f002";
    font-size: 24px;
    color: #111;
}

#btn-search:hover {
    background: #fff;
}

/* dropdown */
/* ------------------------------ */
.dropdown {
    position: relative;
    z-index: 500;
    font-weight: 600;
    display: inline-block;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    text-align: left;
}

.dropdown a,
.dropdown a:hover {
    text-decoration: none;
    outline: none;
    display: inline-block;
}

.dropdown a,
.header-light .dropdown a:hover {
    color: #333;
}

.dark-scheme .dropdown a,
.dark-scheme .header-light .dropdown a:hover {
    color: #ffffff;
}

.dropdown>a {
    display: inline-block;
    padding: 7px 12px 7px 12px;
    min-width: 140px;
    border: solid 1px rgba(0, 0, 0, .2);
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    font-weight: bold;
}

.dark-scheme .dropdown>a{
    border: solid 1px rgba(255, 255, 255, .2);
}

.dropdown>a:after {
    font-family: "FontAwesome";
    font-size: 16px;
    content: "\f107";
    position: relative;
    float: right;
    margin-left: 10px;
}

.rtl .dropdown>a:after{
    margin-left: 0;
    float: left;
}

.dropdown ul,
.dropdown li {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
}

.dropdown ul {
    background: #ffffff;
    padding: 10px;
    position: absolute;
    min-width: 140px;
    display: none;
    cursor: pointer;
    border: solid 1px rgba(0, 0, 0, .1);
}

.dropdown li span {
    background: #ffffff;
    display: block;
    padding: 5px 10px 5px 10px;
    border-top: none;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
}

.dark-scheme .dropdown li span{
    background:var(--bg-color-dark);
    border: solid 1px rgba(255, 255, 255, .1);
}

.dark-scheme .dropdown li span:hover{
    background: var(--bg-color-even);
    color: #fff;
}

.dropdown li span:hover {
    color: #ffffff;
    background: #eeeeee;
}

.dropdown li.active {
    display: none;
}

.dropdown ul.d-col-3{
    width: 600px !important;
}

.dropdown ul.d-col-3 li{
    width: 33.3%;
    float: left;
}

.dropdown ul.d-col-3 li span{
}

.text-light .dropdown {
    background: none;
    border: solid 1px rgba(255, 255, 255, .1);
}

.text-light .dropdown a {
    color: #ffffff;
}

.items_filter {
    font-size: 14px;
    margin-bottom: 30px;
}

.items_filter .dropdown {
    margin-right: 10px;
}

.dropdown.fullwidth,
.dropdown.fullwidth a,
.dropdown.fullwidth ul,
.dropdown.fullwidth li {
    width: 100%;
}

.dropdown.style-2,
.dropdown.style-2 a,
.dropdown.style-2 ul,
.dropdown.style-2 li {
    width: 100%;
}

.rtl .dropdown.style-2{
    text-align: right;
}

.dropdown.style-2 h4{
    font-size: 15px;
    font-weight: 500;
}
.dropdown.style-2 a{
    font-size: 20px;
    color: #ffffff;
    padding: 5px 0;
    border:  none;
    border-top: solid 1px rgba(255, 255, 255, .3);
}

a.btn-search-big{
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 20px;
    font-weight: bold;
    color: #ffffff;
    font-size: 20px;
     border: solid 1px #ffffff;
}

a.btn-search-big:hover{
    background: #ffffff;
    color: #35404e;
}

/* ================================================== */


/* review */


/* ================================================== */

.de_review {
    text-align: center;
    min-height: 260px;
}

.de_review .de_nav {
    overflow: hidden;
    padding-left: 0;
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-align: center;
}

.de_review .de_nav li {
    list-style: none;
    background: #EEE;
    margin: 0 10px 0 10px;
    margin-bottom: 20px;
}

.de_review .de_nav li {
    display: inline-block;
}

.de_review .de_nav li img {
    width: 100px;
    height: auto;
}

.de_review .de_nav li span {
    padding: 8px 16px 8px 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    display: block;
    background: #f6f6f6;
    text-decoration: none;
    color: #646464;
    font-size: 13px;
    cursor: poInter;
}

.de_review blockquote {
    border: none;
    font-family: "Inter", Georgia, "Times New Roman", Times, serif;
}

.de_review .de_nav li span {
    border: 1px solid #202220;
    background: #222;
}

.de_review .de_nav li:first-child span {
    border-left: 1px solid #ddd;
}

.de_review .de_nav li:first-child span {
    border-left: 1px solid #202220;
}

.de_review .de_review_content {
    border: 1px solid #ddd;
    padding: 15px 20px 20px 20px;
    margin-top: -1px;
    background: #fff;
}

.de_review .de_review_content {
    border: 1px solid #202220;
    background: #27282b;
}

.de_review .de_nav li.active span {
    background: #FFF;
    border-bottom: 1px solid #fff;
    color: #222;
    margin-bottom: -3px;
}

.separator {
    line-height: 0.5;
    text-align: center;
    margin: 30px 0 30px 0;
}

.separator span {
    display: inline-block;
    position: relative;
}

.separator span:before,
.separator span:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    top: 5px;
    width: 50px;
}

.separator span:before {
    right: 100%;
    margin-right: 15px;
}

.separator span:after {
    left: 100%;
    margin-left: 15px;
}

.separator span i {
    font-size: 12px;
    padding: 0;
    margin: 0;
    float: none;
}

.de_light .separator span:before,
.de_light .separator span:after {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.text-light .separator span:before,
.text-light .separator span:after {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

/* --------------------------------------------------
     * plugin
     * --------------------------------------------------*/


/* --------------------------------------------------
* revolution slider setting
* --------------------------------------------------*/

#revolution-slider {
    background: #222;
}

.tp-caption {
    color: #fff;
    font-weight: 400;
}

.tp-caption.teaser {
    letter-spacing: 5px;
}

.tp-caption br {
    margin-bottom: 5px;
}

.tparrows {
    top: 50%;
}

.med {
    font-size: 24px;
    letter-spacing: -.5px;
}

.med-white {
    color: #fff;
    font-size: 24px;
    letter-spacing: -.5px;
}

.med-green {
    color: #1a8b49;
    font-size: 24px;
    letter-spacing: -.5px;
}

.small-white {
    color: #fff;
    line-height: 1.7em;
}

.big-black {
    font-size: 52px;
    letter-spacing: -3px;
    color: #222;
}

.big-white {
    font-size: 16px;
    color: #fff;
    line-height: normal;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.very-big-white {
    font-family: var(--body-font);
    font-size: 64px;
    color: #fff;
    margin: 0;
    font-weight: 500;
}

.ultra-big-white {
    font-size: 120px;
    font-weight: bold;
    color: #fff;
    margin: 0;
}

.ultra-big-black {
    font-size: 96px;
    font-weight: 400;
    color: #222;
    margin: 0;
    line-height: 72px;
}

a.btn-slider:hover {
    color: #222;
}

.tp-caption.separator {
    width: 100px;
    border-bottom: solid 1px rgba(255, 255, 255, .3);
}

/* ================================================== */


/* isotope */


/* ================================================== */

.isotope,
.isotope .isotope-item {
    /* change duration value to whatever you like */
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -ms-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-duration: 1s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -ms-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}

#filters {
    font-family: var(--body-font);
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 400;
}

#filters li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;
    font-size: 11px;
    text-transform: uppercase;
}

#filters a {
    background: none;
    outline: none;
    color: #888;
    text-decoration: none;
    color: #888;
    padding: 5px 20px 5px 20px;
    border-radius: 3px;
    -moz-border-radius: 300px;
    -webkit-border-radius: 300px;
}

.text-light #filters a {
    color: rgba(255, 255, 255, .5);
}

#filters a:hover {
    color: #111;
}

#filters a.selected {
    color: #fff;
}

.text-light #filters a:hover {
    color: #fff;
}

.text-light #filters a.selected {
    color: #fff;
}

.text-light #filters a.selected {
    color: #fff;
}

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    poInter-events: none;
    z-index: 1;
}

/* -------------------------------------------------- */


/* flex slider */


/* -------------------------------------------------- */

.flexslider {
    width: 100%;
    background: none;
    border: none;
    box-shadow: none;
    margin: 0px;
}

.control-slider {
    float: right;
    display: inline-block;
    margin-right: -10px;
}

.prev-slider,
.next-slider {
    float: left;
    display: block;
    text-align: center;
    margin-top: 10px;
    cursor: poInter;
}

.prev-slider i,
.next-slider i {
    border: solid 1px #ccc;
    padding: 5px 10px 5px 10px;
}

.prev-slider i:hover,
.next-slider i:hover {
    color: #fff;
    border: solid 1px #333;
    background: #333;
}

.prev-slider {
    margin-right: -5px;
}

.project-carousel ul.slides,
.project-carousel-3-col ul.slides {
    margin-top: 10px;
    margin-bottom: 20px;
}

.logo-carousel .flex-direction-nav,
.project-carousel .flex-control-nav,
.project-carousel .flex-direction-nav,
.project-carousel-3-col .flex-control-nav,
.project-carousel-3-col .flex-direction-nav {
    display: none;
}

.logo-carousel.owl-carousel .owl-item img {
    width: auto;
    opacity: .5;
}

.logo-carousel.no-control .flex-control-nav {
    display: none;
}

.logo-carousel.no-alpha.owl-carousel .owl-item img{
    opacity: 1;
}



/* mdb carousel */

/* Carousel styling */
      #de-carousel,
      #de-carousel .carousel-inner,
      #de-carousel .carousel-item,
      #de-carousel .carousel-item.active {
        height: 100vh;
      }

      #de-carousel .carousel-item:nth-child(1) {
        background-image: url('https://mdbootstrap.com/img/Photos/Others/images/76.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      #de-carousel .carousel-item:nth-child(2) {
        background-image: url('https://mdbootstrap.com/img/Photos/Others/images/77.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      #de-carousel .carousel-item:nth-child(3) {
        background-image: url('https://mdbootstrap.com/img/Photos/Others/images/78.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      #de-carousel .carousel-control-prev-icon:after{
        content: '\f104';
        font-family: "FontAwesome";
      }
      #de-carousel .carousel-control-next-icon:after{
        content: '\f105';
        font-family: "FontAwesome";
      }

      #de-carousel .carousel-indicators [data-mdb-target]{
        width: 8px;
        height: 8px;
        margin: 0 6px;
      }

      #de-carousel .mask{
        background: rgba(30, 30, 30, 0);
      }

      #de-carousel .carousel-control-next, #de-carousel .carousel-control-prev{
        width: 100px;
      }

/* mdb carousel */


/* flex slider - testi slider */

#testimonial-full {
    text-align: center;
    padding: 10px 0 10px 0;
    text-shadow: none;
}

#testimonial-full blockquote {
    color: #fff;
    border: none;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-transform: uppercase;
    letter-spacing: 3px;
}

#testimonial-full span {
    letter-spacing: 0;
    margin-top: 20px;
    text-transform: none;
}

.testi-slider .flex-control-nav {
    position: absolute;
    margin-top: -50px;
}

.testi-slider .flex-direction-nav {
    display: none;
}

/* Control Nav */

.testi-slider .flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
}

.testi-slider .flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.testi-slider .flex-control-paging li a {
    background: none;
    width: 8px;
    height: 8px;
    display: block;
    border: solid 1px #fff;
    cursor: poInter;
    text-indent: -9999px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}

.testi-slider .flex-control-paging li a:hover {
    border: solid 1px #fff;
    background: #fff;
}

.testi-slider .flex-control-paging li a.flex-active {
    border: solid 1px #fff;
    cursor: default;
    background: #fff;
}

.size10 {
    font-size: 10px;
}

.size20 {
    font-size: 20px;
}

.size30 {
    font-size: 30px;
}

.size40 {
    font-size: 40px;
}

.size50 {
    font-size: 50px;
}

.size60 {
    font-size: 60px;
}

.size70 {
    font-size: 70px;
}

.typed-strings p {
    position: absolute;
}

.deco-big {
    display: block;
    font-size: 120px;
    font-family: "Miama";
    margin-top: 48px;
    margin-left: -20px;
}

.deco-md {
    display: block;
    font-size: 120px;
    font-family: "Miama";
    margin-top: 32px;
    margin-left: -20px;
}

.social-icons-sm i {
    color: #fff;
    margin: 0 5px 0 5px;
    border: solid 1px #555;
    width: 32px;
    height: 32px;
    padding: 8px;
    text-align: center;
    border-radius: 30px;
}

.social-icons-sm i:hover {
    color: #ffffff;
}

.arrowup {
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid #fff;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    margin-top: -30px;
}

.arrowdown {
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 30px solid #ffffff;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
}

.arrowright {
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid green;
}

.arrowleft {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid blue;
}

blockquote.very-big {
    border: none;
    font-family: var(--body-font);
    text-align: center;
    border: none;
    font-size: 32px;
    font-weight: 400;
    background: none;
    font-style: normal;
}

blockquote.very-big span {
    font-family: var(--body-font);
}

a,
img {
    outline: 0;
}

.z1 {
    z-index: 1;
}

.z10 {
    z-index: 10;
}

.rtl-content {
    text-align: right;
}


/* overwrite bootstrap */

.nav-pills .nav-link {
    font-weight: 500;
}

.de-preloader {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background: #ffffff;
    z-index: 5000;
}

.dark-scheme .de-preloader{
    background: var(--bg-color-even);
}

.dot-loader {
    width: 80px;
    height: 16.6666666667px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.dot-loader-dot {
    will-change: transform;
    height: 16.6666666667px;
    width: 16.6666666667px;
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    -webkit-animation: grow 0.5s ease-in-out infinite alternate;
    animation: grow 0.5s ease-in-out infinite alternate;
}

.dot-loader-dot.dot1 {
    left: 0;
    transform-origin: 100% 50%;
}

.dot-loader-dot.dot2 {
    left: 50%;
    transform: translateX(-50%) scale(1);
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
}

.dot-loader-dot.dot3 {
    right: 0;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

@-webkit-keyframes grow {
    to {
        transform: translateX(-50%) scale(0);
    }
}

@keyframes grow {
    to {
        transform: translateX(-50%) scale(0);
    }
}


/* -------------------------Line Preloader--------------------- */

.line-preloader {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80px;
    height: 16.6666666667px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

}

.line-preloader .p-line {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0);
}

.line-preloader .p-line:nth-child(1) {
    position: absolute;
    width: 25px;
    height: 25px;
    border-right: 2px solid gold;
    border-bottom: 2px solid gold;
    animation: anim4 600ms linear infinite;
}

.line-preloader .p-line:nth-child(2) {
    border-left: 2px solid gold;
    border-top: 2px solid gold;
    animation: anim4 1200ms linear infinite;
}

.line-preloader .p-line:nth-child(3) {
    position: absolute;
    width: 75px;
    height: 75px;
    border-right: 2px solid gold;
    border-top: 2px solid gold;
    animation: anim4 900ms linear infinite;
}

@keyframes anim4 {
    to {
        transform: rotate(360deg);
    }
}

/* ================================================== */


/* R E S P O N S I V E */


/* ================================================== */

@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1300px;
    }
}


@media(min-width:980px) and (max-width: 1199px) {
    #mainmenu li a:after {
        margin-left: 15px;
    }

    .pf_full_width.gallery_border .item,
    .pf_full_width.gallery_border.pf_2_cols .item,
    .pf_full_width.gallery_border.pf_3_cols .item {
        float: left;
        width: 23.95%;
    }

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        min-width: 95%;
    }
}

/* -------------------------------------------------- 
    design for 768px
    /* ================================================== */

@media (min-width: 768px) and (max-width: 979px) {    
    .slider_text {
        padding-top: 0px;
    }
}

@media only screen and (max-width: 1090px) {
    #quick_search{
        width:200px;
    }
    #quick_search{
        position: absolute;
        top: 30px;
    }
}

@media only screen and (max-width: 992px) {
    #menu {
        display: block;
    }
    header .logo-main{
        display: none;
    }

    header .logo-init{
        display: none;
    }

    header .logo-mobile{
        display: block;
    }

    .de_table .tr .td:nth-child(4),
    .de_table .tr .td:nth-child(5),
    .de_table .tr .td:nth-child(6),
    .de_table .tr .td:nth-child(7) {
        display: none;
    }

    .col-right {
        position: absolute;
        top: -82px;
        right: 60px;
        width: 140px;
    }

    .mb-sm-30 {
        margin-bottom: 30px;
    }

    .sm-mt-0 {
        margin-top: 0;
    }

    .p-sm-30 {
        padding: 40px;
    }

    .pb-sm-0 {
        padding-bottom: 0;
    }

    .mb-sm-0 {
        margin-bottom: 0;
    }

    header .header-col {
        position: absolute;
        top: 0;
    }

    header .header-col.mid {
        position: absolute;
        top: 70px;
        width: 100%;
        padding: 0 0 0 0;
    }

    .text-center-sm {
        text-align: center;
    }

    .sm-pt10 {
        padding-top: 10px;
    }

    #menu-btn {
        display: block;
        float: right;
        z-index: 1000;
        top: 0;
        right: 0;
        background: #333;
    }

    .inner-padding {
        padding: 40px;
    }

    .header-col-left,
    .header-col-right {
        padding-top: 16px;
    }

    .header-col-mid #mainmenu {
        position: absolute;
        top: 70px;
        width: 100%;
        padding: 0 0 0 0;
        left: 0px;
        background-color: #85BCF7;
    }

    header {
        display: none;
    }

    header .info {
        display: none;
    }

    header.header-bg {
        background: rgba(0, 0, 0, 1);
    }

    header,
    header.fixed {
        display: block;
        position: inherit;
        margin: 0;
        padding: 0;
        margin-top: 0;
        height: auto;
        background: #111111;
    }

    #de-sidebar {
        position: absolute;
        width: 100%;
        right: 1px;
    }

    header.side-header,
    .de_light header.side-header {
        position: absolute;
        display: block;
        height: 60px;
        top: 0;
        background: #000;
    }

    header.side-header #mainmenu {
        position: absolute;
        background: #000;
        margin: 0;
        top: 60px;
        left: 0;
        width: 100%;
    }

    header.side-header #logo .logo {
        display: none;
    }

    header.side-header #logo .logo-2 {
        display: inline-block;
        position: absolute;
        height: 30px;
        top: 15px;
        left: 40px;
    }

    header.side-header #menu-btn {
        position: absolute;
        top: 8px;
        right: 30px;
        height: 0;
        z-index: 2000;
        height: 35px;
    }

    header.side-header #mainmenu li {
        display: block;
    }

    header.side-header #mainmenu a,
    .de_light.de-navbar-left header #mainmenu>li>a {
        display: block;
        text-align: center;
        color: #fff;
    }

    .de_light.de-navbar-left header #mainmenu>li {
        border-bottom: solid 1px rgba(255, 255, 255, .1);
    }

    header.side-header #mainmenu a:hover {
        background: none;
    }

    header.side-header #mainmenu a:hover {
        background: none;
    }

    header.side-header .social-icons-2 {
        display: none;
    }

    header.autoshow {
        height: auto;
        top: 0;
    }

    header.autoshow #mainmenu li a {
        background: none;
    }

    header.header-mobile-sticky {
        position: fixed;
    }

    #subheader.s2{
        padding: 40px 0 30px 0;
    }

    .de-navbar-left #subheader {
        padding: 0;
        margin: 0;
    }

    #subheader h1 {
        margin-top: 0;
    }

    #subheader span {
        display: none;
    }

    #subheader .crumb {
        display: none;
    }

    #content section:first-of-type{
        padding-top: 160px;
    }

    #sidebar{
        padding-left: 10px;
    }

    .de-navbar-left #subheader {
        padding-top: 70px;
    }

    .slider_text h1 {
        font-size: 32px;
    }

    .slider_text .description {
        display: none;
    }

    #logo,
    #logo .inner {
        vertical-align: middle;
        height: auto;
    }

    #domain-check .text,
    #domain-check .button {
        width: 100%;
    }

    .container-4 .de-team-list {
        width: 49.9%;
        float: left;
        position: relative;
    }

    #testimonial-full blockquote {
        padding: 20px;
    }

    nav {
        height: 0;
    }

    #mainmenu {
        position: fixed;
        height: 100%;
        z-index: 200;
        width: 200px;
        margin: 0;
        margin-top: 10px;
        padding: 0;
        padding-top: 20px;
        padding-bottom: 100px !important;
        overflow-y: auto;
        top: 100px;
    }

    #mainmenu ul {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    #mainmenu a.menu-item {
        text-align: left;
        padding-left: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #mainmenu li.has-child:after{
        display: none;
    }

    #mainmenu li li a.menu-item,
    #mainmenu li li li a.menu-item {
        padding-left: 0;
    }

    #mainmenu a.menu-item:hover {
        background: #111;
    }

    #mainmenu a.menu-item:hover {
        background: #111;
    }

    #mainmenu li ul {
        display: block;
        position: inherit;
        margin: 0;
        width: 100%;
    }

    #mainmenu li {
        border-bottom: solid 1px #eee;
        margin: 0;
        width: 100%;
        display: block;
    }

    #mainmenu li a.menu-item:after {
        display: none;
    }

    #mainmenu li:last-child {
        margin-bottom: 30px;
    }

    #mainmenu li ul {
        border-top: solid 1px #eee;
        top: auto;
        width: auto;
        height: auto;
        position: inherit;
        visibility: visible;
        opacity: 1;
    }

    #mainmenu>li.menu-item-has-children>a.menu-item {
        position: relative;
        padding-right: 15px;
    }

    #mainmenu>li.menu-item-has-children>a.menu-item:after {
        content: "\f107";
        font-family: "FontAwesome";
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
    }

    #mainmenu li ul a.menu-item {
        width: 100%;
        background: none;
        border: none;
    }

    #mainmenu li ul li {
        border: none;
        padding-left: 40px;
    }

    #mainmenu li ul li a.menu-item {
        display: block;
    }

    #mainmenu li ul li:last-child {
        border-bottom: none;
        margin: 0;
    }

    #mainmenu li ul li:last-child a.menu-item {
        border-bottom: none;
    }

    #mainmenu li a {
        color: #eceff3;
    }

    #mainmenu li li a.menu-item:hover {
        color: #fff;
    }

    #mainmenu ul:not(.mega){
        border: none;
    }

    #mainmenu>li ul.mega .sb-menu{
        border: none;
    }

    #back-to-top{
        display: none;
    }

    header.header-mobile {
        overflow: hidden;
        position: relative;
    }

    header.header-mobile #mainmenu {
        width: 100%;
        padding-right: 30px;
    }

    header.header-mobile #mainmenu ul{
        background: none;
    }

    header.header-mobile #mainmenu a.menu-item {
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    header.header-mobile #mainmenu a.menu-item:hover {
        background: none;
    }

    header.header-mobile #mainmenu li ul {
        display: block;
        position: inherit;
        margin: 0;
        width: 100%;
    }

    header.header-mobile #mainmenu li {
        border-bottom: solid 1px #eee;
        margin: 0;
        width: 100%;
        display: block;
    }

    .dark-scheme header.header-mobile #mainmenu li{
        border-bottom-color: rgba(255, 255, 255, .1);
    }

    header.header-mobile #mainmenu li ul.mega ul {
        height: auto;
    }

    header.header-mobile #mainmenu li ul.mega .menu-content {
        background: #fff;
    }

    header.header-light.header-mobile #mainmenu li,
    header.header-light.header-mobile #mainmenu li ul li a.menu-item {
        border-color: #eee;
    }

    header.header-dark.header-mobile {
        background: #222;
    }

    header.header-dark.header-mobile #mainmenu li {
        border-color: #333;
    }

    header.header-dark.header-mobile #mainmenu li a.menu-item {
        color: #fff;
    }

    header.header-mobile #mainmenu li a.menu-item:after {
        display: none;
    }

    header.header-mobile #mainmenu li:last-child {
        margin-bottom: 30px;
    }

    header.header-mobile #mainmenu li ul {
        border-top: none;
        top: auto;
    }

    header.header-mobile #mainmenu li ul a.menu-item {
        width: 100%;
        background: none;
        border: none;
    }

    header.header-mobile #mainmenu li ul li {
        border: none;
        padding-left: 40px;
    }

    header.header-mobile #mainmenu li ul.mega li {
        border: none;
        padding-left: 0;
    }

    header #mainmenu li ul li:first-child {
        border-top: solid 1px #eee;
    }

    .dark-scheme header #mainmenu li ul li:first-child {
        border-top-color: rgba(255,255,255,.1);
    }

    header.header-mobile #mainmenu li ul li a.menu-item {
        display: block;
        border-bottom: solid 1px #eee;
    }

    header.header-mobile #mainmenu li ul li:last-child {
        border-bottom: none;
        margin: 0;
    }

    header.header-mobile #mainmenu li ul li:last-child a.menu-item {
        border-bottom: none;
    }

    header.header-mobile #mainmenu li a.menu-item {
        color: #888;
    }

    .dark-scheme header.header-mobile #mainmenu li a.menu-item {
        color: #ffffff;
    }

    header.header-mobile #mainmenu li a.menu-item:hover {
        color: #333;
    }

    .dark-scheme header.header-mobile #mainmenu li a.menu-item:hover {
        color: #fff;
    }

    header.header-mobile #mainmenu li li a.menu-item:hover {
        color: #888;
    }

    header.header-mobile #mainmenu li ul {
        height: 0;
        overflow: hidden;
        position: relative;
        left: 0;
    }

    header.header-mobile #mainmenu li ul li ul {
        margin-bottom: 10px;
    }

    header.header-mobile #mainmenu>li>span {
        width: 36px;
        height: 36px;
        background: url(../images/ui/arrow-down.png) center no-repeat;
        position: absolute;
        right: 0;
        margin-top: 10px;
        z-index: 1000;
        cursor: poInter;
    }

    .dark-scheme header.header-mobile #mainmenu>li>span {
        background: url(../images/ui/arrow-down-light.png) center no-repeat;
    }

    header.header-mobile #mainmenu li span.active {
        background: url(../images/ui/arrow-up.png) center no-repeat;
    }

    .dark-scheme header.header-mobile #mainmenu li span.active {
        background: url(../images/ui/arrow-up-light.png) center no-repeat;
    }

    header.header-mobile #mainmenu li>ul>li>span {
        width: 36px;
        height: 36px;
        background: url(../images/ui/arrow-down.png) center no-repeat;
        position: absolute;
        right: 0;
        margin-top: -45px;
        z-index: 1000;
        cursor: poInter;
    }

    header.header-mobile #mainmenu>li>ul>li>span.active {
        background: url(../images/ui/arrow-up.png) center no-repeat;
    }

    header.header-mobile #mainmenu li ul li a.menu-item {
        border-bottom: solid 1px #eee;
    }

    .dark-scheme header.header-mobile #mainmenu li ul li a.menu-item {
        border-bottom-color: rgba(255, 255, 255, .1);
    }    

    header.header-mobile #mainmenu li ul li:last-child>a.menu-item {
        border-bottom: none;
    }

    header.header-mobile .h-phone {
        color: #202020;
    }

    header.header-light.header-mobile #mainmenu>li>span,
    header.header-light.header-mobile #mainmenu li>ul>li>span {
        background: url(../images/ui/arrow-down.png) center no-repeat;
    }

    header.header-light.header-mobile #mainmenu>li>span.active,
    header.header-light.header-mobile #mainmenu li>ul>li>span.active {
        background: url(../images/ui/arrow-up.png) center no-repeat;
    }

    #mainmenu li ul.mega {
        position: fixed;
        left: 0;
        width: 100%;
        margin-top: 30px;
        display: block;
    }

    #mainmenu li ul.mega>li {
        width: 100%;
    }

    #mainmenu li ul.mega>li ul {
        position: static;
        visibility: visible;
        opacity: 1;
        left: 0;
        float: none;
        width: 100%;
    }

    #mainmenu ul.mega>li ul li {
        margin: 0;
        padding: 0;
    }

    #mainmenu li ul.mega .menu-content {
        background: #202020;
        padding: 30px;
    }

    /* header light */
    .header-light #mainmenu ul li a.menu-item,
    .header-light #mainmenu ul li a.menu-item:hover {
        color: #333;
    }

    .de_tab.tab_methods .de_nav {
        text-align: center;
    }

    .de_tab.tab_methods .de_nav li {
        margin: 5px;
        padding: 0;
    }

    .de_tab.tab_methods .de_nav li span {
        margin: 0;
        display: inline-block;
    }

    .de_tab.tab_methods .de_nav li .v-border {
        border: none;
    }

    .hidden-phone {
        display: none;
    }

    .grid_gallery .item {
        width: 100%;
    }

    .table [class*="col-"] {
        float: none;
        display: block;
        vertical-align: top;
    }

    .table-pricing thead tr,
    .table-pricing tbody tr {
        display: block;
        width: 100%;
    }

    .table-pricing thead tr {
        display: none;
    }

    .table-pricing tbody tr th,
    .table-pricing tbody tr td {
        text-align: left;
        display: block;
        width: 100%;

    }

    .table-pricing tbody tr td {
        padding: 10px 30px;
    }

    .table-pricing tbody tr td:nth-child(2) {
        padding-top: 30px;
    }

    .table-pricing tbody tr td:last-child {
        padding-bottom: 40px;
        text-align: center;
    }

    .table-pricing tbody tr th span,
    .table-pricing tbody tr td span {
        min-width: 50%;
    }

    .table-pricing tbody tr th .lbl,
    .table-pricing tbody tr td .lbl {
        display: inline-block;
        min-width: 50%;
    }

    .table-pricing tbody tr th {
        color: #ffffff;
        padding: 20px 30px;
    }

    .sm-hide {
        display: none;
    }

    .btn-rsvp {
        position: absolute;
    }

    .d-btn-close {
        position: relative;
    }

    .schedule-item .sc-info {
        padding-top: 20px;
    }

    .de_tab.tab_style_4 .de_nav li {
        min-width: 100px;
    }

    #mainmenu a.menu-item span {
        display: none;
    }

    .pf_full_width.grid {
        margin-left: 1.5%;
    }

    .de_tab.tab_methods.style-2 .de_nav li.active .arrow {
        margin-top: -40px;
        margin-bottom: 40px;

    }

    .table-custom-1 {
        position: relative;
        height: auto;
    }

    h1.very-big {
        font-size: 9vw;
        line-height: 9vw;
        letter-spacing: 0;
    }

    .timeline>li>.timeline-panel {
        width: 100%;
    }

    .timeline-badge {
        display: none;
    }

    .timeline:before {
        background: none;
    }

    .tp-caption {
        padding-left: 40px;
        padding-right: 40px;
    }

    .author_list {
        -moz-column-count: 3;
        -moz-column-gap: 20px;
        -webkit-column-count: 3;
        -webkit-column-gap: 20px;
        column-count: 3;
        column-gap: 20px;
    }

    .subfooter .de-flex,
    .subfooter .de-flex-col {
        display: block;
    }

    .subfooter .social-icons {
        margin-top: 20px;
    }

    .subfooter span.copy {
        margin-top: 20px;
        display: block;
    }

    .activity-filter li{
        width: 100%;
    }

    .dropdown{
        display: inline-block;
        margin-bottom: 10px;
    }

    .side-bg .image-container{ 
        position:relative; 
        width:100%; 
        height:400px !important; 
        top:0;
        margin-bottom:60px;
    }

    .side-bg-text{
        margin-bottom: 80px;
    }

    .countdown-s2 .countdown-show4 .countdown-section{
        width: 70px !important;
    }

}

/* -------------------------------------------------- 
    custom for 320px & 480px 
    /* -------------------------------------------------- */

@media only screen and (max-width: 767px) {
    .de-table.table-rank thead th:nth-child(5),
    .de-table.table-rank thead th:nth-child(6),
    .de-table.table-rank thead th:nth-child(7),
    .de-table.table-rank tbody td:nth-child(4),
    .de-table.table-rank tbody td:nth-child(5),
    .de-table.table-rank tbody td:nth-child(6) {
        display: none;
    }

    .d_profile {
        display: block;
    }

    .d_profile .de-flex-col {
        display: block;
    }

    .profile_avatar {
        display: block;
    }

    .profile_avatar i {
        margin-top: -40px;
    }

    .profile_name {
        margin-left: 0;
        margin-top: 20px;
        display: block;
        width: 100%;
    }

    .profile_follow {
        margin-top: 40px;
        width: 100%;
    }

    .menu_side_area {
        margin-left: 20px;
        width: 100%;
        position: relative;
        right: 0;
        text-align: right;
        z-index: 0;
        display: block;
    }

    header .container {
        min-width: 100%;
    }

    .pricing-2-col .pricing-box,
    .pricing-3-col .pricing-box,
    .pricing-4-col .pricing-box,
    .pricing-5-col .pricing-box {
        width: 100%;
    }

    .container-4 .de-team-list {
        width: 100%;
        float: left;
        position: relative;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .feature-box-image,
    .pricing-box {
        margin-bottom: 30px;
    }

    #filters li.pull-right {
        float: none;
    }

    .pf_full_width .item {
        float: left;
        width: 49.9%;
    }

    .text-item {
        font-size: 48px;
        line-height: 48px;
    }

    .text-item i {
        font-size: 36px;
    }

    #de-sidebar {
        width: 100%;
        display: block;
        position: relative;
    }

    .gallery_border {
        margin-left: 0px;
    }

    .pf_full_width.gallery_border .item {
        width: 48.9%;
    }

    body.boxed {
        margin: 0px;
    }

    .schedule-item .sc-time {
        width: 100%;
        padding-bottom: 20px;
        float: none;
    }

    .schedule-item .sc-pic {
        width: 80px;
    }

    .schedule-item .sc-pic img {
        width: 60px;
    }

    .schedule-item .sc-name {
        padding-top: 5px;
    }

    .schedule-item .sc-info {
        float: none;
        display: block;
        width: 100%;
        clear: both;
        padding-top: 30px;
    }

    .schedule-item .sc-info h4 {
        margin-bottom: 10px;
    }

    .de_tab.tab_style_4 .de_nav {
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
    }

    .de_tab.tab_style_4 .de_nav li {
        min-width: 33.3%;
        width: 33.3%;
        border: none;
    }

    .de_tab.tab_style_4 .de_nav li:first-child,
    .de_tab.tab_style_4 .de_nav li:last-child {
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
    }

    .pf_full_width.grid {
        margin-left: .2%;
    }

    .pf_full_width.grid .item {
        width: 48.5%;
    }

    .display-table [class*="col-"] {
        display: table;
    }

    h1.big,
    h1_big {
        font-size: 8vw;
        line-height: 10vw;
    }

    .xs-hide {
        display: none;
    }

    .author_list {
        -moz-column-count: 2;
        -moz-column-gap: 20px;
        -webkit-column-count: 2;
        -webkit-column-gap: 20px;
        column-count: 2;
        column-gap: 20px;
    }

}

/* -------------------------------------------------- 
    design for 480px
    /* -------------------------------------------------- */

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .feature-box-image,
    .pricing-box {
        margin-bottom: 30px;
    }

    .timeline-panel {
        margin-bottom: 30px;
    }

    .timeline-pic {
        height: 300px;
    }
}

/* -------------------------------------------------- 
    design for 320px
    /* -------------------------------------------------- */

@media only screen and (max-width: 480px) {

    .pf_full_width .item {
        float: left;
        width: 100%;
    }

    .text-item {
        font-size: 32px;
        line-height: 32px;
    }

    .text-item i {
        font-size: 24px;
    }

    .pf_full_width.gallery_border .item {
        width: 100%;
    }

    h4.s2 span {
        display: block;
        margin: 0;
        padding: 0;
    }

    .h-phone {
        display: none;
    }

    .author_list {
        -moz-column-count: 1;
        -moz-column-gap: 20px;
        -webkit-column-count: 1;
        -webkit-column-gap: 20px;
        column-count: 1;
        column-gap: 20px;
    }

    header a.btn-main i{
    display: inline-block;
    }

    #form_quick_search{
        margin-bottom: 0px;
    }

}

@media only screen and (max-width: 360px) {
    #topbar {
        display: none;
    }
}

/* section control */
@media only screen and (min-device-width: 768) and (max-device-width: 1024) and (orientation: portrait) {
    section {
        -webkit-background-size: auto 100%;
        -moz-background-size: auto 100%;
        -o-background-size: auto 100%;
        background-size: auto 100%;
        background-attachment: scroll;
    }
}

@media only screen and (max-width: 992px) {
    #subheader {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    section {
        -webkit-background-size: auto 100%;
        -moz-background-size: auto 100%;
        -o-background-size: auto 100%;
        background-size: auto 100%;
        background-attachment: scroll;
    }
}


@media (min-width: 1200px) {
    .container-timeline ul {
        display: flex;
        padding-left: 0;
        padding-top: 20px;
    }

    .container-timeline ul li {
        flex: 1 1 0;
        border-left: 0;
        border-top: 1px solid green;
        padding: 50px 4em 0 0;
    }

    .container-timeline ul li::before {
        left: 0;
        top: -21px;
    }
}